import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import * as MenuItem from '../menu-item'
import { UserMenu } from './user-menu'
import { LanguageSwitcher } from './language-and-currency'
import { SearchAutocomplete } from '../search'
import {
  useHeaderContext,
  useLinkComponent,
  useOnLoginClick,
  useOnSignUpClick,
  useTranslations,
} from '../../contexts/header-config-context'
import { MainNavigation } from './main-navigation'
import { useIsAuthLoading, useIsAuthenticated } from '../../contexts/auth'
import { StoreMenu } from './store-menu'
import { HeartRoundedIcon } from '../icons/heart-rounded-icon'
import { Bell03Icon } from '../icons/bell-03-icon'
import { ShoppingCart03Icon } from '../icons/shopping-cart-03-icon'
import { SparkInpaintingIcon } from '../icons/spark-inpainting-icon'
import { studioTemplatesLink } from '../../utils/urls'
import { useIsIpad } from '../../hooks/use-is-ipad'

export const containerClasses = 'm-auto w-full px-4 xl:max-w-7xl 2xl:max-w-[1536px]'

export function DesktopHeader() {
  const { lang } = useHeaderContext()
  const Link = useLinkComponent()
  const isAuthLoading = useIsAuthLoading()
  const isAuthenticated = useIsAuthenticated()
  const menuItemStyle = isAuthenticated ? 'flex-col gap-0.5' : ''
  const t = useTranslations()
  const onLoginClick = useOnLoginClick()
  const onSignUpClick = useOnSignUpClick()
  const isIpad = useIsIpad()

  const loadingUI = (
    <div className="w-[386px]">
      <LanguageSwitcher classNames="cf-hidden" forceMount />
    </div>
  )

  const unauthenticatedUI = (
    <>
      <LanguageSwitcher />
      <NavigationMenu.Item key="login">
        <MenuItem.Button
          onClick={onLoginClick}
          className={tw(menuItemStyle, 'grow whitespace-nowrap')}
        >
          {t('top_nav.login')}
        </MenuItem.Button>
      </NavigationMenu.Item>
      <NavigationMenu.Item key="signUp">
        <MenuItem.Button
          variant="outlined"
          className={tw('ml-1', menuItemStyle, 'grow whitespace-nowrap')}
          onClick={onSignUpClick}
        >
          {t('top_nav.sign_up')}
        </MenuItem.Button>
      </NavigationMenu.Item>
    </>
  )
  const authenticatedUI = (
    <>
      <LanguageSwitcher />
      <NavigationMenu.Item key="favorites">
        <NavigationMenu.Trigger asChild>
          <MenuItem.Link href="/my-favorites/" className={menuItemStyle}>
            <HeartRoundedIcon className="h-5" />
            <span className="grow whitespace-nowrap"> {t('top_nav.favorites')}</span>
          </MenuItem.Link>
        </NavigationMenu.Trigger>
      </NavigationMenu.Item>
      <NavigationMenu.Item key="notifications">
        <NavigationMenu.Trigger asChild>
          <MenuItem.Link href="/feed/notifications/" className={menuItemStyle}>
            <Bell03Icon className="h-5" />
            <span className="grow whitespace-nowrap">{t('top_nav.notifications')}</span>
          </MenuItem.Link>
        </NavigationMenu.Trigger>
      </NavigationMenu.Item>
      <StoreMenu />
      <NavigationMenu.Item key="cart">
        <NavigationMenu.Trigger asChild>
          <MenuItem.Link href="/cart/" className={menuItemStyle}>
            <div className="relative">
              <ShoppingCart03Icon className="h-5" />
              {/* <div className="absolute -right-1 -top-1 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-[#E21743] text-[8px] text-[#FFF]">
        {cartItems}
      </div> */}
            </div>
            <span className="grow whitespace-nowrap">{t('top_nav.cart')}</span>
          </MenuItem.Link>
        </NavigationMenu.Trigger>
      </NavigationMenu.Item>
      <UserMenu />
    </>
  )

  return (
    <>
      <div
        id="desktop-header"
        className={tw(
          'border-cf-dreamy-cloud-500 border-b ',
          isIpad ? 'cf-hidden' : 'cf-hidden min-[1175px]:block',
        )}
      >
        <div className={tw(containerClasses, 'flex h-14 items-center gap-6 px-6')}>
          <Link href="/" className="text-cf-dark-aubergine text-20 xl:text-24 font-bold">
            Creative Fabrica
          </Link>
          <SearchAutocomplete />
          <NavigationMenu.Root lang={lang} aria-label="Top navigation">
            <NavigationMenu.List className="text-cf-dreamy-cloud-900 flex items-center">
              <li className="border-cf-dreamy-cloud-400 h-9 border-l" />
              {isAuthLoading ? loadingUI : isAuthenticated ? authenticatedUI : unauthenticatedUI}
              <NavigationMenu.Item key="create">
                <MenuItem.Link
                  className="ml-4 py-1.5"
                  variant="colored"
                  href={studioTemplatesLink}
                  target="_blank"
                >
                  <SparkInpaintingIcon className="text-cf-white drop-shadow-cf-elevation-light-1 h-5" />
                  <span className="drop-shadow-cf-elevation-light-1 text-14">
                    {t('top_nav.create')}
                  </span>
                </MenuItem.Link>
              </NavigationMenu.Item>
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </div>
      </div>
      <MainNavigation />
    </>
  )
}
