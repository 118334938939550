import { useContext } from 'react';

import { Icon } from 'components/elements/atoms/icon/Icon';
import { FormControlContext } from 'components/elements/atoms/formControl/FormControl';

import { CheckboxProps } from './Checkbox.types';
import * as S from './Checkbox.styles';

export const Checkbox = (props: CheckboxProps) => {
  const { searchV2, label, className, ...restProps } = props;
  const { error, disabled } = useContext(FormControlContext) || {};

  return (
    <S.Label
      label={label}
      disabled={disabled}
      className={className}
      control={
        <S.Checkbox
          color={error ? 'error' : undefined}
          disabled={disabled}
          size="small"
          icon={<div className="icon" />}
          checkedIcon={
            <Icon className="icon" icon="check" style={searchV2 ? { boxSizing: 'border-box' } : undefined} />
          }
          {...restProps}
        />
      }
    />
  );
};
