import { Box, styled } from '@mui/material';

export const BoxRoot = styled(Box)(() => ({
  position: 'fixed',
  padding: 0,
  margin: '14px 20px',
  transitionDuration: '250ms',
  transitionTimingFunction: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
  transitionProperty: 'opacity, transform, visibility',
  zIndex: 2,
}));

export const ButtonStyled = styled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  border: 0,
  backgroundColor: '#30aabc',
  padding: '13px 22px',
  borderRadius: '999rem',
  color: '#163438',
  fill: '#163438',
  letterSpacing: 0.6,
  fontSize: '15px',
  fontWeight: 700,
  fontFamily:
    'system-ui, -apple-system, "system-ui", "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", Arial, sans-serif',
  '&:focus': {
    boxShadow: 'inset 0 0 0 3px rgb(255 255 255 / 40%)',
  },
  [theme.breakpoints.down('md')]: {
    padding: '13px',
  },
}));

export const BoxIcon = styled(Box)(({ theme }) => ({
  paddingRight: '8px',
  width: '28px',
  height: '20px',
  [theme.breakpoints.down('md')]: {
    paddingRight: 0,
    width: '20px',
  },
}));
