import { Language } from 'codegen/types'

import { defaultLanguage, languageMap } from './constants'

export function validateLocale(locale: unknown): locale is Language {
  return languageMap.has(locale as Language)
}

export function localeToLanguage(locale: string) {
  return validateLocale(locale) ? locale : defaultLanguage
}

export function addOrReplaceLanguageInUrl(url: string, lang?: Language): string {
  const urlObj = new URL(url)
  const segments = urlObj.pathname.split('/').filter(Boolean)

  // If we do not have a preferred lang, leave it unchanged
  if (lang) {
    if (validateLocale(segments[0])) {
      segments[0] = lang
    } else {
      segments.unshift(lang)
    }
  }

  urlObj.pathname = segments.join('/')

  if (!urlObj.pathname.startsWith('/')) {
    urlObj.pathname = `/${urlObj.pathname}/`
  }

  if (!urlObj.pathname.endsWith('/')) {
    urlObj.pathname = `${urlObj.pathname}/`
  }

  if (urlObj.pathname.startsWith('/en/')) {
    urlObj.pathname = urlObj.pathname.replace('/en/', '/')
  }

  const result = urlObj.toString()

  return result
}

export function getLanguageSegment(url: string) {
  try {
    const [locale] = new URL(url).pathname.split('/').filter(Boolean)

    if (validateLocale(locale)) {
      return locale
    }
  } catch (error) {}

  return defaultLanguage
}
