import * as React from 'react'
import { Hit } from 'instantsearch.js/es/types'
import { tw } from 'utils/classnames'
import { useOnClickOutside } from 'hooks/use-on-click-outside'

import { MIN_REFINEMENT_LENGTH } from './algolia.configs'
import { SearchInput } from './search-input'
import { AlgoliaContentHit, SearchProduct } from './search.types'
import { useRouterUtil } from '../../contexts/header-config-context'
import { RecentSearches } from './recent-searches'

interface AutocompleteProps {
  autofocus?: boolean
  renderResult?: (hit: Hit<SearchProduct | AlgoliaContentHit>) => React.ReactNode
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => void
  hits: Hit<SearchProduct | AlgoliaContentHit>[]
  currentRefinement: string
  refine: (value: string) => void
  className?: string
  onChange: (newValue: string) => void
}

// Ideally this would be a fully accessible autocomplete component, but currently Radix UI doesn't have a good fit.
// However a new primitive for this use case is being developed: https://github.com/radix-ui/primitives/issues/1342
export function Autocomplete({
  autofocus,
  className,
  currentRefinement,
  hits,
  renderResult,
  onChange,
  onSubmit,
}: AutocompleteProps) {
  const formRef = React.useRef<HTMLFormElement>(null)
  const inputRef = React.useRef<HTMLInputElement>(null)
  const [focus, setFocus] = React.useState(false)
  const [showResults, setShowResults] = React.useState(false)
  const pathname = useRouterUtil().pathname
  const isResultsVisible =
    currentRefinement.length >= MIN_REFINEMENT_LENGTH &&
    renderResult &&
    hits.length > 0 &&
    showResults

  useOnClickOutside(formRef, () => {
    setShowResults(false)
    setFocus(false)
  })

  // force close autocomplete when route changes
  React.useEffect(() => {
    setShowResults(false)
  }, [pathname])

  React.useEffect(() => {
    // close autocomplete when focus is lost, but with a delay to allow for clicks on the autocomplete results
    const timeout =
      !focus &&
      setTimeout(() => {
        setShowResults(false)
      }, 150)
    return () => {
      timeout && clearTimeout(timeout)
    }
  }, [focus])

  const onKeyPress = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Escape') {
      setShowResults(false)
      const activeElement = document.activeElement as HTMLElement
      if (activeElement) {
        activeElement.blur()
      }
    }
  }, [])

  return (
    <div className={tw('relative flex grow', className)} data-testid="header-search">
      <form
        ref={formRef}
        onSubmit={e => {
          onSubmit(e)
          setFocus(false)
          inputRef.current?.blur()
        }}
        className="z-cf-menu w-full"
      >
        <SearchInput
          ref={inputRef}
          autofocus={autofocus}
          value={currentRefinement}
          onChange={onChange}
          onKeyPress={onKeyPress}
          onFocus={() => {
            setFocus(true)
            setShowResults(true)
          }}
        />
        {isResultsVisible ? (
          <div
            key="results"
            id="search-autocomplete-results"
            data-testid="header-search-autocomplete"
            className={tw(
              'z-cf-menu absolute top-[50px]',
              'w-full px-2 py-3',
              'bg-cf-white shadow-cf-elevation-light-5 rounded-2',
            )}
          >
            {hits.map(renderResult)}
          </div>
        ) : null}
        {!isResultsVisible && focus ? (
          <RecentSearches
            onFillQueryClick={query => {
              onChange(query)
              setTimeout(() => {
                inputRef.current?.focus()
              })
            }}
            onItemClick={query => {
              onChange(query)
              setTimeout(() => {
                formRef.current?.requestSubmit()
              })
            }}
          />
        ) : null}
      </form>
    </div>
  )
}
