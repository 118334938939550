import * as Portal from 'primitives/portal'

import { useMainMenuContainerRef } from '../../../contexts/main-navigation-context'

export function Container({ asChild, children }: { asChild?: boolean; children: React.ReactNode }) {
  const containerRef = useMainMenuContainerRef()
  return (
    <Portal.Root container={containerRef} asChild={asChild}>
      {children}
    </Portal.Root>
  )
}
