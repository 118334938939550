// Primitive icons don't allow usage of gradients, so we need to create a custom icon.
export function GradientColoredSparkIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <linearGradient
          id="bg1"
          x1="10.5007"
          y1="1.66675"
          x2="10.5007"
          y2="18.3334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8559F9" />
          <stop offset="1" stopColor="#FF228C" />
        </linearGradient>
        <linearGradient
          id="bg2"
          x1="13.8333"
          y1="0.833252"
          x2="13.8333"
          y2="12.4999"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8559F9" />
          <stop offset="1" stopColor="#FF228C" />
        </linearGradient>
        <linearGradient
          id="bg3"
          x1="7.99935"
          y1="9.16675"
          x2="7.99935"
          y2="15.8334"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8559F9" />
          <stop offset="1" stopColor="#FF228C" />
        </linearGradient>
      </defs>
      <path
        d="M9.66732 1.66675H6.16732C4.76719 1.66675 4.06712 1.66675 3.53234 1.93923C3.06194 2.17892 2.67948 2.56137 2.4398 3.03177C2.16732 3.56655 2.16732 4.26662 2.16732 5.66675V14.3334C2.16732 15.7335 2.16732 16.4336 2.4398 16.9684C2.67948 17.4388 3.06194 17.8212 3.53234 18.0609C4.06712 18.3334 4.76719 18.3334 6.16732 18.3334H14.834C16.2341 18.3334 16.9342 18.3334 17.469 18.0609C17.9394 17.8212 18.3218 17.4388 18.5615 16.9684C18.834 16.4336 18.834 15.7335 18.834 14.3334V11.2194"
        stroke="url(#bg1)"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8333 12.4999C14.2138 12.4999 14.5255 12.2201 14.5784 11.8242C15.1014 8.29781 15.5823 7.80686 18.9745 7.42149C19.3655 7.37398 19.6667 7.04668 19.6667 6.66659C19.6667 6.28122 19.3708 5.96447 18.9798 5.9064C15.6087 5.43657 15.1913 5.02481 14.5784 1.50369C14.5097 1.11304 14.2085 0.833252 13.8333 0.833252C13.4476 0.833252 13.1412 1.11304 13.0777 1.50897C12.5652 5.03008 12.0844 5.52104 8.69746 5.9064C8.29589 5.9592 8 6.27594 8 6.66659C8 7.04668 8.28533 7.36342 8.6869 7.42149C12.0633 7.90188 12.4754 8.30837 13.0777 11.8295C13.157 12.2254 13.4635 12.4999 13.8333 12.4999Z"
        fill="url(#bg2)"
      />
      <path
        d="M7.99935 15.8334C8.21674 15.8334 8.39488 15.6735 8.42507 15.4473C8.72399 13.4322 8.99874 13.1517 10.9372 12.9315C11.1606 12.9043 11.3327 12.7173 11.3327 12.5001C11.3327 12.2799 11.1636 12.0989 10.9402 12.0657C9.01384 11.7972 8.77532 11.5619 8.42507 9.54986C8.38582 9.32663 8.21372 9.16675 7.99935 9.16675C7.77894 9.16675 7.60382 9.32663 7.56759 9.55287C7.27471 11.5649 6.99995 11.8455 5.06457 12.0657C4.8351 12.0959 4.66602 12.2769 4.66602 12.5001C4.66602 12.7173 4.82906 12.8983 5.05853 12.9315C6.98788 13.206 7.22338 13.4382 7.56759 15.4503C7.61288 15.6766 7.788 15.8334 7.99935 15.8334Z"
        fill="url(#bg3)"
      />
    </svg>
  )
}
