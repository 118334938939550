import * as React from 'react'

import { useDomEvent } from './use-dom-event'

type Handler = (e: React.MouseEvent) => void

export function useOnClickOutside<T extends HTMLElement = HTMLElement>(
  ref: React.RefObject<T>,
  handler: Handler,
  mouseEvent: 'mousedown' | 'mouseup' = 'mousedown',
): void {
  useDomEvent(
    useDomEvent.window,
    mouseEvent,
    React.useCallback(
      (event: Event) => {
        const el = ref?.current

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.target as Node)) {
          return
        }

        handler(event as unknown as React.MouseEvent)
      },
      [handler, ref],
    ),
  )
}
