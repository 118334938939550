export function HeartRoundedIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M13.9253 2.5C16.8605 2.5 18.8327 5.29375 18.8327 7.9C18.8327 13.1781 10.6475 17.5 10.4993 17.5C10.3512 17.5 2.16602 13.1781 2.16602 7.9C2.16602 5.29375 4.13824 2.5 7.07342 2.5C8.75861 2.5 9.86046 3.35312 10.4993 4.10312C11.1382 3.35312 12.2401 2.5 13.9253 2.5Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
