import { Hit } from 'instantsearch.js/es/types'
// import Link from 'next/link'

import { Image } from '../image'
import { DEFAULT_ALGOLIA_LANGUAGE, mapLocaleToContentLanguage } from './algolia.configs'
import { useLinkComponent, useLocale } from '../../contexts/header-config-context'
import { AlgoliaContentHit } from './search.types'

interface AutocompleteHitProps {
  href: string
  hit: Hit<AlgoliaContentHit>
}

export function TextParsedAutocompleteHit(props: AutocompleteHitProps) {
  const { href, hit } = props
  const locale = useLocale()
  const algoliaLang = mapLocaleToContentLanguage.get(locale) || DEFAULT_ALGOLIA_LANGUAGE
  const Link = useLinkComponent()

  return (
    <Link href={href} color="inherit">
      <div className="hover:bg-cf-lavender bg-cf-white flex w-full items-center rounded-full px-4 py-2">
        <Image
          src={`${process.env.NEXT_PUBLIC_CONTENT_IMAGES_ROOT_URL}/${hit.thumbnail.url}`}
          width={hit.thumbnail.width}
          height={hit.thumbnail.height}
          alt={hit.title ? hit.title[algoliaLang] : ''}
          imageClassName="h-full w-full object-cover overflow-hidden"
        />

        <span
          className="ml-4 text-xs"
          dangerouslySetInnerHTML={{ __html: hit._highlightResult?.title[algoliaLang].value || '' }}
        ></span>
      </div>
    </Link>
  )
}
