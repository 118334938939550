import invariant from 'tiny-invariant'
import { defaultLanguage } from 'cf-i18n/constants'

import {
  useHasActiveSubscription,
  useHasAllAccess,
  useIsAuthLoading,
  useIsAuthenticated,
} from '../contexts/auth'
import { useLocale, useTranslations } from '../contexts/header-config-context'
import { NavItemType } from './use-menu-items'
import { askCFBaseUrl, baseUrl, studioBaseUrl } from '../utils/urls'

export type NavItem = {
  id?: NavItemType
  name: string
  link?: string
  wrapItems?: boolean
  wrapTitle?: boolean
  title?: string
  trending?:
    | {
        title?: string
        image?: string
        imageLink?: string
        imageText?: string
        layout?: never
      }
    | {
        title?: string
        items: {
          icon?: React.ReactNode
          iconLink: string
          iconText: string
        }[]
        layout: 'horizontal'
      }
  isFootOption?: boolean
  subMenuItems?: NavItem[]
} & (
  | {
      primaryName?: string
      secondaryName: string
      secondarySubMenuItems: NavItem[]
    }
  | {
      primaryName?: never
      secondaryName?: never
      secondarySubMenuItems?: never
    }
)

const CF_URL = process.env.NEXT_PUBLIC_CF_URL
invariant(CF_URL, 'NEXT_PUBLIC_CF_URL is required in cf-menu/hooks/use-nav-items.tsx')

export const useNavItems = () => {
  const t = useTranslations()
  const lang = useLocale()
  const isAuthLoading = useIsAuthLoading()
  const isAuthenticated = useIsAuthenticated()
  const hasAllAccessSubscription = useHasAllAccess()
  const hasActiveSubscription = useHasActiveSubscription()
  const langSegment = lang === defaultLanguage ? '' : `/${lang}`

  const fontsMenuItem: NavItem = {
    id: 'fonts',
    name: t('navigation.fonts.fonts'),
    link: '/fonts/',
    subMenuItems: [
      {
        id: 'fonts_sub',
        name: t('navigation.fonts.fonts'),
        link: '/fonts/',
        wrapItems: true,
        trending: {
          image:
            'https://www.creativefabrica.com/wp-content/uploads/2023/12/13/0474-Images-nav-menu-01_Font-section.png',
          imageLink: '/fonts/',
        },
        subMenuItems: [
          {
            name: t('navigation.fonts.script'),
            link: '/fonts/script-fonts/',
          },
          {
            name: t('navigation.fonts.display'),
            link: '/fonts/display-fonts/',
          },
          {
            name: t('navigation.fonts.sans_serif'),
            link: '/fonts/sans-serif-fonts/',
          },
          {
            name: t('navigation.fonts.serif'),
            link: '/fonts/serif-fonts/',
          },
          {
            name: t('navigation.fonts.blackletter'),
            link: '/fonts/blackletter-fonts/',
          },
          {
            name: t('navigation.fonts.slab_serif'),
            link: '/fonts/slab-serif-fonts/',
          },
          {
            name: t('navigation.fonts.decorative'),
            link: '/fonts/decorative-fonts/',
          },
          {
            name: t('navigation.fonts.dingbats'),
            link: '/fonts/dingbats-fonts/',
          },
          {
            name: t('navigation.fonts.color'),
            link: '/fonts/color-fonts/',
          },
          {
            name: t('navigation.fonts.discounted'),
            link: '/promotions/fonts/',
          },
          {
            name: t('navigation.fonts.view_all'),
            link: '/fonts/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'popular_styles',
        name: t('navigation.fonts.popular_styles'),
        wrapItems: true,
        subMenuItems: [
          {
            name: t('navigation.fonts.fancy'),
            link: '/search/?category=&query=Fancy&type=Fonts',
          },
          {
            name: t('navigation.fonts.stars'),
            link: '/search/?query=Stars&type=Fonts',
          },
          {
            name: t('navigation.fonts.cool'),
            link: '/search/?query=Cool&type=Fonts',
          },
          {
            name: t('navigation.fonts.handwriting'),
            link: '/search/?query=Handwriting&category=Script%2520%2526%2520Handwritten&type=Fonts',
          },
          {
            name: t('navigation.fonts.monogram'),
            link: '/search/?query=Monogram&type=Fonts',
          },
          {
            name: t('navigation.fonts.tattoo'),
            link: '/search/?query=Tattoo&type=Fonts',
          },
          {
            name: t('navigation.fonts.old_english'),
            link: '/search/?query=Old%20English&type=Fonts',
          },
          {
            name: t('navigation.fonts.cursive'),
            link: '/search/?query=Cursive&type=Fonts',
          },
          {
            name: t('navigation.fonts.lettering'),
            link: '/search/?query=Lettering&type=Fonts',
          },
          {
            name: t('navigation.fonts.heart'),
            link: '/search/?query=Heart&type=Fonts',
          },
          {
            name: t('navigation.fonts.graffiti'),
            link: '/search/?query=Graffiti&type=Fonts',
          },
          {
            name: t('navigation.fonts.cute'),
            link: '/search/?query=Cute&type=Fonts',
          },
          {
            name: t('navigation.fonts.typewriter'),
            link: '/search/?query=Typewriter&type=Fonts',
          },
          {
            name: t('navigation.fonts.calligraphy'),
            link: '/search/?query=Calligraphy&type=Fonts',
          },
          {
            name: t('navigation.fonts.aesthetic'),
            link: '/search/?query=Aesthetic&type=Fonts',
          },
          {
            name: t('navigation.fonts.gothic'),
            link: '/search/?query=Gothic&category=Blackletter%2CDecorative&type=Fonts',
          },
          {
            name: t('navigation.fonts.bubble'),
            link: '/search/?query=Bubble&type=Fonts',
          },
          {
            name: t('navigation.fonts.space'),
            link: '/search/?query=Space&type=Fonts',
          },
          {
            name: t('navigation.fonts.vintage'),
            link: '/search/?query=Vintage&type=Fonts',
          },
          {
            name: t('navigation.fonts.modern'),
            link: '/search/?query=Modern&type=Fonts',
          },
          {
            name: t('navigation.fonts.y2k'),
            link: '/search/?query=Y2K&type=Fonts',
          },
          {
            name: t('navigation.fonts.scary'),
            link: '/search/?query=Scary&type=Fonts',
          },
          {
            name: t('navigation.fonts.art_deco'),
            link: '/search/?query=Art%20Deco&type=Fonts',
          },
          {
            name: t('navigation.fonts.elegant'),
            link: '/search/?query=Elegant&type=Fonts',
          },
          {
            name: t('navigation.fonts.signature'),
            link: '/search/?query=Signature&type=Fonts',
          },
          {
            name: t('navigation.fonts.pretty'),
            link: '/search/?query=Pretty&type=Fonts',
          },
          {
            name: t('navigation.fonts.whimsical'),
            link: '/search/?query=Whimsical&type=Fonts',
          },
          {
            name: t('navigation.fonts.quirky'),
            link: '/search/?query=Quirky&type=Fonts',
          },
          {
            name: t('navigation.fonts.outline'),
            link: '/search/?query=Outline&type=Fonts',
          },
          {
            name: t('navigation.fonts.retro'),
            link: '/search/?query=Retro&type=Fonts',
          },
          {
            name: t('navigation.fonts.variable'),
            link: '/search/?query=Variable&type=Fonts',
          },
          {
            name: t('navigation.fonts.monospace'),
            link: '/search/?query=Monospace&type=Fonts',
          },
          {
            name: t('navigation.fonts.clean'),
            link: '/search/?query=Clean&type=Fonts',
          },
          {
            name: t('navigation.fonts.brush'),
            link: '/search/?query=Brush&type=Fonts',
          },
          {
            name: t('navigation.fonts.groovy'),
            link: '/search/?query=Groovy&type=Fonts',
          },
        ],
      },
      {
        id: 'holidays_events',
        name: t('navigation.fonts.holidays_events'),
        wrapItems: true,
        subMenuItems: [
          {
            name: t('navigation.fonts.birthday'),
            link: '/search/?category=&query=Birthday&type=Fonts',
          },
          {
            name: t('navigation.fonts.graduation'),
            link: '/search/?query=Graduation&type=Fonts',
          },
          {
            name: t('navigation.fonts.wedding'),
            link: '/tag/wedding-fonts/',
          },
          {
            name: t('navigation.fonts.canada_day'),
            link: '/search/?query=Canada&type=Fonts',
          },
          {
            name: t('navigation.fonts.chinese_new_year'),
            link: '/search/?query=Chinese&type=Fonts',
          },
          {
            name: t('navigation.fonts.christmas'),
            link: '/search/?query=Christmas&type=Fonts',
          },
          {
            name: t('navigation.fonts.cinco_de_mayo'),
            link: '/search/?query=Cinco%20de%20Mayo&type=Fonts',
          },
          {
            name: t('navigation.fonts.easter'),
            link: '/search/?query=Easter&type=Fonts',
          },
          {
            name: t('navigation.fonts.fathers_day'),
            link: '/search/?query=Father%27s%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.mothers_day'),
            link: '/search/?query=Mother%27s%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.halloween'),
            link: '/search/?query=Halloween&type=Fonts',
          },
          {
            name: t('navigation.fonts.independence_day'),
            link: '/search/?query=Independence%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.mardi_gras'),
            link: '/search/?query=Mardi%20Gras&type=Fonts',
          },
          {
            name: t('navigation.fonts.memorial_day'),
            link: '/search/?query=Memorial%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.st_patricks_day'),
            link: '/search/?query=St.%20Patrick%27s%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.thanksgiving'),
            link: '/search/?query=Thanksgiving&type=Fonts',
          },
          {
            name: t('navigation.fonts.valentines_day'),
            link: '/search/?query=Valentine%27s%20Day&type=Fonts',
          },
          {
            name: t('navigation.fonts.veterans_day'),
            link: '/search/?query=USA&type=Fonts',
          },
          {
            name: t('navigation.fonts.autumn'),
            link: '/search/?query=Autumn&type=Fonts',
          },
          {
            name: t('navigation.fonts.spring'),
            link: '/search/?query=Spring&type=Fonts',
          },
          {
            name: t('navigation.fonts.summer'),
            link: '/search/?query=Summer&type=Fonts',
          },
          {
            name: t('navigation.fonts.winter'),
            link: '/search/?query=Winter&type=Fonts',
          },
        ],
      },
      {
        id: 'text_tools',
        name: t('navigation.fonts.text_tools'),
        wrapItems: true,
        subMenuItems: [
          {
            name: t('navigation.webfont_generator'),
            link: '/webfont-generator/',
          },
          {
            name: t('navigation.fonts.online_manager'),
            link: '/fontcloud/',
          },
          {
            name: t('navigation.fonts.fancy_generator'),
            link: '/tools/font-generator/',
          },
          {
            name: t('navigation.fonts.wordcloud_generator'),
            link: '/shapecloud/',
          },
          {
            name: t('navigation.fonts.cursive_generator'),
            link: '/tools/cursive-font-generator/',
          },
          {
            name: t('navigation.fonts.small_text_generator'),
            link: '/tools/small-text-generator/',
          },
          {
            name: t('navigation.fonts.glitch_text_generator'),
            link: '/tools/glitch-text-generator/',
          },
          {
            name: t('navigation.fonts.cool_generator'),
            link: '/tools/cool-fonts-generator/',
          },
          {
            name: t('navigation.fonts.zalgo_text_generator'),
            link: '/tools/zalgo-text-generator/',
          },
          {
            name: t('navigation.fonts.instagram_font_generator'),
            link: '/tools/instagram-fonts-generator/',
          },
          {
            name: t('navigation.fonts.bold_text_generator'),
            link: '/tools/bold-text-generator/',
          },
          {
            name: t('navigation.fonts.wingdings_translator'),
            link: '/tools/wingdings-translator/',
          },
          {
            name: t('navigation.fonts.cursed_text_generator'),
            link: '/tools/cursed-text-generator/',
          },
          {
            name: t('navigation.fonts.italics_generator'),
            link: '/tools/italics-generator/',
          },
          {
            name: t('navigation.fonts.ascii_art_generator'),
            link: '/tools/ascii-art-generator/',
          },
          {
            name: t('navigation.fonts.caligraphy_text_generator'),
            link: '/tools/calligraphy-font-generator/',
          },
          {
            name: t('navigation.fonts.aesthetic_generator'),
            link: '/tools/aesthetic-fonts-generator/',
          },
          {
            name: t('navigation.fonts.cross_out_text_generator'),
            link: '/tools/strike-through-text-generator/',
          },
          {
            name: t('navigation.fonts.creepy_text_generator'),
            link: '/tools/creepy-text-generator/',
          },
          {
            name: t('navigation.fonts.gothic_generator'),
            link: '/tools/gothic-font-generator/',
          },
          {
            name: t('navigation.fonts.facebook_generator'),
            link: '/tools/facebook-fonts-generator/',
          },
          {
            name: t('navigation.fonts.bubble_generator'),
            link: '/tools/bubble-font-generator/',
          },
          {
            name: t('navigation.fonts.twitter_generator'),
            link: '/tools/twitter-fonts-generator/',
          },
          {
            name: t('navigation.fonts.discord_generator'),
            link: '/tools/discord-fonts-generator/',
          },
        ],
      },
    ],
  }

  const imagesMenuItem: NavItem = {
    id: 'images',
    name: t('navigation.images.images'),
    link: '/graphics/',
    subMenuItems: [
      {
        id: 'graphics',
        name: t('navigation.images.graphics.graphics'),
        link: '/graphics/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.graphics.3d_svg'),
            link: '/subscriptions/graphics/3d-svg-graphics/',
          },
          {
            name: t('navigation.images.graphics.add_ons'),
            link: '/subscriptions/graphics/add-ons/',
          },
          {
            name: t('navigation.images.graphics.actions_and_presets'),
            link: '/subscriptions/graphics/add-ons/actions-presets/',
          },
          {
            name: t('navigation.images.graphics.ai_generated'),
            link: '/subscriptions/graphics/ai-generated/',
          },
          {
            name: t('navigation.images.graphics.backgrounds'),
            link: '/subscriptions/graphics/backgrounds/',
          },
          {
            name: t('navigation.images.graphics.cms_templates'),
            link: '/subscriptions/graphics/cms-templates/',
          },
          {
            name: t('navigation.images.graphics.coloring_books_adults'),
            link: '/subscriptions/graphics/coloring-pages-books/adults/',
          },
          {
            name: t('navigation.images.graphics.coloring_books_kids'),
            link: '/subscriptions/graphics/coloring-pages-books/kids-coloring-pages-books/',
          },
          {
            name: t('navigation.images.graphics.home_crafts'),
            link: '/subscriptions/graphics/crafts-graphics/',
          },
          {
            name: t('navigation.images.graphics.logos'),
            link: '/subscriptions/graphics/graphic-templates/logos/',
          },
          {
            name: t('navigation.images.graphics.scene_generators'),
            link: '/subscriptions/graphics/graphic-templates/scene-generators/',
          },
          {
            name: t('navigation.images.graphics.ux_and_ui_kits'),
            link: '/subscriptions/graphics/graphic-templates/ux-and-ui-kits/',
          },
          {
            name: t('navigation.images.graphics.icons'),
            link: '/subscriptions/graphics/icons/',
          },
          {
            name: t('navigation.images.graphics.illustrations'),
            link: '/subscriptions/graphics/illustrations/',
          },
          {
            name: t('navigation.images.graphics.kdp_interiors'),
            link: '/subscriptions/graphics/kdp-interiors/',
          },
          {
            name: t('navigation.images.graphics.motion_graphics'),
            link: '/subscriptions/graphics/motion-graphics/',
          },
          {
            name: t('navigation.images.graphics.objects'),
            link: '/subscriptions/graphics/objects/',
          },
          {
            name: t('navigation.images.graphics.patterns'),
            link: '/subscriptions/graphics/patterns/',
          },
          {
            name: t('navigation.images.graphics.presentation_templates'),
            link: '/subscriptions/graphics/presentation-templates/',
          },
          {
            name: t('navigation.images.graphics.product_mockups'),
            link: '/subscriptions/graphics/product-mockups/',
          },
          {
            name: t('navigation.images.graphics.t_shirt_designs'),
            link: '/subscriptions/graphics/t-shirt-designs/',
          },
          {
            name: t('navigation.images.graphics.teaching_materials'),
            link: '/subscriptions/graphics/teaching-materials/',
          },
          {
            name: t('navigation.images.graphics.textures'),
            link: '/subscriptions/graphics/textures/',
          },
          {
            name: t('navigation.images.graphics.web_elements'),
            link: '/subscriptions/graphics/web-elements/',
          },
          {
            name: t('navigation.images.graphics.web_templates'),
            link: '/subscriptions/graphics/web-templates/',
          },
          {
            name: t('navigation.images.graphics.discounted_graphics'),
            link: '/promotions/graphics/',
          },
          {
            name: t('navigation.images.graphics.browse_all'),
            link: '/graphics/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'png',
        name: t('navigation.images.png.png'),
        link: '/png/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.png.4th_july'),
            link: '/png/fourth-of-july-png-eDNI70Xav/',
          },
          {
            name: t('navigation.images.png.american_flag'),
            link: '/png/american-flag-png-I5w9JaG7D/',
          },
          {
            name: t('navigation.images.png.angel_wing'),
            link: '/png/angel-wing-png-qQkQHaFlg/',
          },
          {
            name: t('navigation.images.png.arrow'),
            link: '/png/arrow-png-iCkcQioqH/',
          },
          {
            name: t('navigation.images.png.banner'),
            link: '/png/banner-png-zksH6jQVX/',
          },
          {
            name: t('navigation.images.png.beach'),
            link: '/png/beach-png-9AeFeAxBE/',
          },
          {
            name: t('navigation.images.png.birthday_cake'),
            link: '/png/birthday-cake-png-mqGuOQJS9/',
          },
          {
            name: t('navigation.images.png.butterfly'),
            link: '/png/butterfly-png-q4AHzldKb/',
          },
          {
            name: t('navigation.images.png.cat'),
            link: '/png/cat-png-9w1xcoQsS/',
          },
          {
            name: t('navigation.images.png.cowboy_hat'),
            link: '/png/cowboy-hat-png-NnABzpr9P/',
          },
          {
            name: t('navigation.images.png.dog'),
            link: '/png/dog-png-uyCRd54R2/',
          },
          {
            name: t('navigation.images.png.dove'),
            link: '/png/dove-png-ajngqZuus/',
          },
          {
            name: t('navigation.images.png.dragon'),
            link: '/png/dragon-png-rbQaTYxXf/',
          },
          {
            name: t('navigation.images.png.fire'),
            link: '/png/fire-png-qMUEQeECR/',
          },
          {
            name: t('navigation.images.png.flower_border'),
            link: '/png/flower-border-png-rYN5zIT4K/',
          },
          {
            name: t('navigation.images.png.flower'),
            link: '/png/flower-png-domBxMGtV/',
          },
          {
            name: t('navigation.images.png.frame'),
            link: '/png/frame-png-NO13Dco65/',
          },
          {
            name: t('navigation.images.png.halloween'),
            link: '/png/halloween-png-NxyGE6cJB/',
          },
          {
            name: t('navigation.images.png.happy_birthday'),
            link: '/png/happy-birthday-png-6qqhWoJX0/',
          },
          {
            name: t('navigation.images.png.heart'),
            link: '/png/heart-png-GEZENtlfn/',
          },
          {
            name: t('navigation.images.png.music_note'),
            link: '/png/music-note-png-0FAWEGfru/',
          },
          {
            name: t('navigation.images.png.palm_tree'),
            link: '/png/palm-tree-png-Wztgb3k28/',
          },
          {
            name: t('navigation.images.png.red_arrow'),
            link: '/png/red-arrow-png-R0AklSIE6/',
          },
          {
            name: t('navigation.images.png.santa_hat'),
            link: '/png/santa-hat-png-RONTtQkT0/',
          },
          {
            name: t('navigation.images.png.skeleton'),
            link: '/png/skeleton-png-6ql2PqyNt/',
          },
          {
            name: t('navigation.images.png.skull'),
            link: '/png/skull-png-0oVlmLK5R/',
          },
          {
            name: t('navigation.images.png.snow'),
            link: '/png/snow-png-d6484chA8/',
          },
          {
            name: t('navigation.images.png.sparkle'),
            link: '/png/sparkle-png-KIB1N0FNw/',
          },
          {
            name: t('navigation.images.png.speech_bubble'),
            link: '/png/speech-bubble-png-0W9QsXQrc/',
          },
          {
            name: t('navigation.images.png.question_mark'),
            link: '/png/question-mark-png-u7qJs0dnY/',
          },
          {
            name: t('navigation.images.png.star'),
            link: '/png/star-png-kbIF66xsa/',
          },
          {
            name: t('navigation.images.png.sunglasses'),
            link: '/png/sunglass-png-UjTgVEGYS/',
          },
          {
            name: t('navigation.images.png.swimming_pool'),
            link: '/png/swimming-pool-png-JrfgczCem/',
          },
          {
            name: t('navigation.images.png.tree'),
            link: '/png/tree-png-Q4VcBX4oy/',
          },
          {
            name: t('navigation.images.png.white_star'),
            link: '/png/white-star-png-zk2THlsYW/',
          },
          {
            name: t('navigation.images.png.browse_all'),
            link: '/png/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'mockups',
        name: t('navigation.images.mockups.mockups'),
        link: '/mockups/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.mockups.apparel'),
            link: '/mockups/apparel-mockups-DBtF2g0eJ/',
          },
          {
            name: t('navigation.images.mockups.beanie'),
            link: '/mockups/beanie-mockups-9MSIdWkZs/',
          },
          {
            name: t('navigation.images.mockups.blank_t_shirt'),
            link: '/mockups/blank-t-shirt-mockups-yglVUvVcV/',
          },
          {
            name: t('navigation.images.mockups.business_card'),
            link: '/mockups/business-card-mockups-nC4012r3b/',
          },
          {
            name: t('navigation.images.mockups.canvas_bag'),
            link: '/mockups/canvas-bag-mockups-QoD02UYBj/',
          },
          {
            name: t('navigation.images.mockups.cap'),
            link: '/mockups/cap-mockups-9jwJTOOGD/',
          },
          {
            name: t('navigation.images.mockups.clothing'),
            link: '/mockups/clothing-mockups-sS5SACbON/',
          },
          {
            name: t('navigation.images.mockups.computer'),
            link: '/mockups/computer-mockups-ZFRh5wnnH/',
          },
          {
            name: t('navigation.images.mockups.flyer'),
            link: '/mockups/flyer-mockups-k6R61Do5A/',
          },
          {
            name: t('navigation.images.mockups.frame'),
            link: '/mockups/frame-mockups-MwPlZWeCF/',
          },
          {
            name: t('navigation.images.mockups.hat'),
            link: '/mockups/hat-mockups-CeaN6GGy7/',
          },
          {
            name: t('navigation.images.mockups.hooded_sweatshirts'),
            link: '/mockups/hooded-sweatshirt-mockups-Hoe52CJl1/',
          },
          {
            name: t('navigation.images.mockups.hoodie'),
            link: '/mockups/hoodie-mockups-Uzm57Z5Et/',
          },
          {
            name: t('navigation.images.mockups.jumper'),
            link: '/mockups/jumper-mockups-j0qGYdNcR/',
          },
          {
            name: t('navigation.images.mockups.laptop'),
            link: '/mockups/laptop-mockups-o6qDnB6tG/',
          },
          {
            name: t('navigation.images.mockups.long_sleeve_shirt'),
            link: '/mockups/long-sleeve-shirt-mockups-ZK4cVXbJW/',
          },
          {
            name: t('navigation.images.mockups.mug'),
            link: '/mockups/mug-mockups-3jaa7NH0A/',
          },
          {
            name: t('navigation.images.mockups.phone'),
            link: '/mockups/phone-mockups-8y6oyUuO0/',
          },
          {
            name: t('navigation.images.mockups.picture_frame'),
            link: '/mockups/picture-frame-mockups-OZzf6h0QQ/',
          },
          {
            name: t('navigation.images.mockups.pillow'),
            link: '/mockups/pillow-mockups-ZDa2MESZ1/',
          },
          {
            name: t('navigation.images.mockups.polo_shirt'),
            link: '/mockups/polo-shirt-mockups-jgMefWU5a/',
          },
          {
            name: t('navigation.images.mockups.postcard'),
            link: '/mockups/postcard-mockups-cC7eBNWrW/',
          },
          {
            name: t('navigation.images.mockups.poster'),
            link: '/mockups/poster-mockups-mpqQvdqza/',
          },
          {
            name: t('navigation.images.mockups.shirt'),
            link: '/mockups/shirt-mockups-82i7MISDT/',
          },
          {
            name: t('navigation.images.mockups.shorts'),
            link: '/mockups/shorts-mockups-Q3WqhhJWY/',
          },
          {
            name: t('navigation.images.mockups.sign'),
            link: '/mockups/sign-mockups-pdnqogoZ7/',
          },
          {
            name: t('navigation.images.mockups.sticker'),
            link: '/mockups/sticker-mockups-JiSumn6va/',
          },
          {
            name: t('navigation.images.mockups.sweatshirt'),
            link: '/mockups/sweatshirt-mockups-RzXPUUe1w/',
          },
          {
            name: t('navigation.images.mockups.t_shirt'),
            link: '/mockups/t-shirt-mockups-2FASf8WyH/',
          },
          {
            name: t('navigation.images.mockups.totebag'),
            link: '/mockups/totebag-mockups-e1TinsPTw/',
          },
          {
            name: t('navigation.images.mockups.browse_all'),
            link: '/mockups/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'kawaii',
        name: t('navigation.images.kawaii.kawaii'),
        link: '/kawaii/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.kawaii.animal'),
            link: '/kawaii/animal-YY18xS2WN/',
          },
          {
            name: t('navigation.images.kawaii.anime_girl'),
            link: '/kawaii/anime-girl-paNVihoZR/',
          },
          {
            name: t('navigation.images.kawaii.art'),
            link: '/kawaii/art-pnaS8R13C/',
          },
          {
            name: t('navigation.images.kawaii.axolotl'),
            link: '/kawaii/axolotl-YBMEyiVuB/',
          },
          {
            name: t('navigation.images.kawaii.bear'),
            link: '/kawaii/bear-wSdgIMJ90/',
          },
          {
            name: t('navigation.images.kawaii.bunny'),
            link: '/kawaii/bunny-Ng0AiU1Ti/',
          },
          {
            name: t('navigation.images.kawaii.cat'),
            link: '/kawaii/cat-QGqzwBEDq/',
          },
          {
            name: t('navigation.images.kawaii.chibi'),
            link: '/kawaii/chibi-3fL6iawZX/',
          },
          {
            name: t('navigation.images.kawaii.cow'),
            link: '/kawaii/cow-2HSXBhMR7/',
          },
          {
            name: t('navigation.images.kawaii.dinosaur'),
            link: '/kawaii/dinosaur-eLaonATEY/',
          },
          {
            name: t('navigation.images.kawaii.dog'),
            link: '/kawaii/dog-8Y5RPKgxu/',
          },
          {
            name: t('navigation.images.kawaii.doodle'),
            link: '/kawaii/doodle-2j1gRO0yo/',
          },
          {
            name: t('navigation.images.kawaii.dragon'),
            link: '/kawaii/dragon-Qi9koX4wj/',
          },
          {
            name: t('navigation.images.kawaii.duck'),
            link: '/kawaii/duck-BqOP5VW9E/',
          },
          {
            name: t('navigation.images.kawaii.easter'),
            link: '/kawaii/easter-W5hSoEmai/',
          },
          {
            name: t('navigation.images.kawaii.eyes'),
            link: '/kawaii/eyes-Mksaieqka/',
          },
          {
            name: t('navigation.images.kawaii.flower'),
            link: '/kawaii/flower-srGbbFueN/',
          },
          {
            name: t('navigation.images.kawaii.fox'),
            link: '/kawaii/fox-Zq1xk9mfS/',
          },
          {
            name: t('navigation.images.kawaii.food'),
            link: '/kawaii/food-cfp4WvRdC/',
          },
          {
            name: t('navigation.images.kawaii.frog'),
            link: '/kawaii/frog-OAMhoEIsC/',
          },
          {
            name: t('navigation.images.kawaii.ghost'),
            link: '/kawaii/ghost-5g3Hcoytl/',
          },
          {
            name: t('navigation.images.kawaii.girl'),
            link: '/kawaii/girl-iG6sSfJPb/',
          },
          {
            name: t('navigation.images.kawaii.halloween'),
            link: '/kawaii/halloween-VQafSBG3x/',
          },
          {
            name: t('navigation.images.kawaii.hamster'),
            link: '/kawaii/hamster-vhwkZGtvE/',
          },
          {
            name: t('navigation.images.kawaii.heart'),
            link: '/kawaii/heart-HviZPKihT/',
          },
          {
            name: t('navigation.images.kawaii.kitten'),
            link: '/kawaii/kitten-Kk5RgUL0J/',
          },
          {
            name: t('navigation.images.kawaii.mushroom'),
            link: '/kawaii/mushroom-p8HBWf49p/',
          },
          {
            name: t('navigation.images.kawaii.panda'),
            link: '/kawaii/panda-C1y3uCWLq/',
          },
          {
            name: t('navigation.images.kawaii.penguin'),
            link: '/kawaii/penguin-u1LJgvaZk/',
          },
          {
            name: t('navigation.images.kawaii.pigs'),
            link: '/kawaii/pigs-H5kGNGqwG/',
          },
          {
            name: t('navigation.images.kawaii.pixel_art'),
            link: '/kawaii/pixel-art-qllW2WZyr/',
          },
          {
            name: t('navigation.images.kawaii.rabbit'),
            link: '/kawaii/rabbit-ZDnEdPk9C/',
          },
          {
            name: t('navigation.images.kawaii.red_panda'),
            link: '/kawaii/red-panda-t0fdZobCU/',
          },
          {
            name: t('navigation.images.kawaii.strawberry'),
            link: '/kawaii/strawberry-a7eIeam7p/',
          },
          {
            name: t('navigation.images.kawaii.unicorn'),
            link: '/kawaii/unicorn-hvIogUMuU/',
          },
          {
            name: t('navigation.images.kawaii.browse_all'),
            link: '/kawaii/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'cartoons',
        name: t('navigation.images.cartoons.cartoons'),
        link: '/cartoons/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.cartoons.airplane'),
            link: '/cartoons/airplane-sTUiN9lz3/',
          },
          {
            name: t('navigation.images.cartoons.aliens'),
            link: '/cartoons/alien-zNgGtLpdj/',
          },
          {
            name: t('navigation.images.cartoons.apple'),
            link: '/cartoons/apple-tWMIFoiNg/',
          },
          {
            name: t('navigation.images.cartoons.bat'),
            link: '/cartoons/bat-QTgRmXdCA/',
          },
          {
            name: t('navigation.images.cartoons.bear'),
            link: '/cartoons/bear-ZXe8eutoj/',
          },
          {
            name: t('navigation.images.cartoons.bee'),
            link: '/cartoons/bee-XZcO8COUy/',
          },
          {
            name: t('navigation.images.cartoons.bird'),
            link: '/cartoons/bird-FSir5kJ7J/',
          },
          {
            name: t('navigation.images.cartoons.black_cat'),
            link: '/cartoons/black-cat-SkVeM9dkE/',
          },
          {
            name: t('navigation.images.cartoons.brain'),
            link: '/cartoons/brain-ZL4JPqW4r/',
          },
          {
            name: t('navigation.images.cartoons.cat'),
            link: '/cartoons/cat-FPZO9Rv0Z/',
          },
          {
            name: t('navigation.images.cartoons.cow'),
            link: '/cartoons/cow-EzS0MnT0b/',
          },
          {
            name: t('navigation.images.cartoons.cowboy_hat'),
            link: '/cartoons/cowboy-hat-G2RM6dqCD/',
          },
          {
            name: t('navigation.images.cartoons.dog'),
            link: '/cartoons/dog-UrOcmRE9a/',
          },
          {
            name: t('navigation.images.cartoons.duck'),
            link: '/cartoons/duck-VZ1tgCaKk/',
          },
          {
            name: t('navigation.images.cartoons.flower'),
            link: '/cartoons/flower-PecCAgqJP/',
          },
          {
            name: t('navigation.images.cartoons.frog'),
            link: '/cartoons/frog-r4hyPCITu/',
          },
          {
            name: t('navigation.images.cartoons.giraffe'),
            link: '/cartoons/giraffe-zGH5bUe3k/',
          },
          {
            name: t('navigation.images.cartoons.hippo'),
            link: '/cartoons/hippo-LxVmZpaSM/',
          },
          {
            name: t('navigation.images.cartoons.horse'),
            link: '/cartoons/horse-XVdF7fdPX/',
          },
          {
            name: t('navigation.images.cartoons.mermaid'),
            link: '/cartoons/mermaid-UbyyXPN6c/',
          },
          {
            name: t('navigation.images.cartoons.monkey'),
            link: '/cartoons/monkey-3JzzcFH14/',
          },
          {
            name: t('navigation.images.cartoons.monster'),
            link: '/cartoons/monster-qOz1L4YbO/',
          },
          {
            name: t('navigation.images.cartoons.moose'),
            link: '/cartoons/moose-oloJJ7JSw/',
          },
          {
            name: t('navigation.images.cartoons.palm_tree'),
            link: '/cartoons/palm-tree-zx341kSOo/',
          },
          {
            name: t('navigation.images.cartoons.panda'),
            link: '/cartoons/panda-e7L364vs2/',
          },
          {
            name: t('navigation.images.cartoons.pumpkin'),
            link: '/cartoons/pumpkin-SxCC5tbsS/',
          },
          {
            name: t('navigation.images.cartoons.rooster'),
            link: '/cartoons/rooster-5PLc5lh7n/',
          },
          {
            name: t('navigation.images.cartoons.santa'),
            link: '/cartoons/santa-4l1da63c9/',
          },
          {
            name: t('navigation.images.cartoons.shark'),
            link: '/cartoons/shark-QQ6DHR60q/',
          },
          {
            name: t('navigation.images.cartoons.skull'),
            link: '/cartoons/skull-lmcibzuq0/',
          },
          {
            name: t('navigation.images.cartoons.t_rex'),
            link: '/cartoons/t-rex-8yeD3OHNq/',
          },
          {
            name: t('navigation.images.cartoons.tiger'),
            link: '/cartoons/tiger-7gXbg3UvT/',
          },
          {
            name: t('navigation.images.cartoons.turkey'),
            link: '/cartoons/turkey-TFLNxc1AR/',
          },
          {
            name: t('navigation.images.cartoons.turtle'),
            link: '/cartoons/turtle-dKjNKEx8j/',
          },
          {
            name: t('navigation.images.cartoons.unicorn'),
            link: '/cartoons/unicorn-9nbdgEk6D/',
          },
          {
            name: t('navigation.images.cartoons.browse_all'),
            link: '/cartoons/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'backgrounds',
        name: t('navigation.images.backgrounds.backgrounds'),
        link: '/backgrounds/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.backgrounds.american_flag'),
            link: '/backgrounds/american-flag-backgrounds-XOPmQWZv1/',
          },
          {
            name: t('navigation.images.backgrounds.anime'),
            link: '/backgrounds/anime-backgrounds-9AJ2XDkrQ/',
          },
          {
            name: t('navigation.images.backgrounds.black_and_gold'),
            link: '/backgrounds/black-and-gold-backgrounds-lRwHnaDKq/',
          },
          {
            name: t('navigation.images.backgrounds.blue'),
            link: '/backgrounds/blue-backgrounds-6mF1losAM/',
          },
          {
            name: t('navigation.images.backgrounds.cherry_blossom'),
            link: '/backgrounds/cherry-blossom-backgrounds-tVVwSlAJu/',
          },
          {
            name: t('navigation.images.backgrounds.christmas'),
            link: '/backgrounds/christmas-backgrounds-bOyUt3V1E/',
          },
          {
            name: t('navigation.images.backgrounds.classroom'),
            link: '/backgrounds/classroom-backgrounds-xJh1mAc1e/',
          },
          {
            name: t('navigation.images.backgrounds.colorful'),
            link: '/backgrounds/colorful-backgrounds-Rwcqa8NXM/',
          },
          {
            name: t('navigation.images.backgrounds.dinosaur'),
            link: '/backgrounds/dinosaur-backgrounds-vWJmLqPrx/',
          },
          {
            name: t('navigation.images.backgrounds.fall'),
            link: '/backgrounds/fall-backgrounds-mx2v0Ku2t/',
          },
          {
            name: t('navigation.images.backgrounds.flower'),
            link: '/backgrounds/flower-backgrounds-9IHVi7kxc/',
          },
          {
            name: t('navigation.images.backgrounds.galaxy'),
            link: '/backgrounds/galaxy-backgrounds-Pmw8pl9bj/',
          },
          {
            name: t('navigation.images.backgrounds.glitter'),
            link: '/backgrounds/glitter-backgrounds-FaWhAjDFb/',
          },
          {
            name: t('navigation.images.backgrounds.gold_glitter'),
            link: '/backgrounds/gold-glitter-backgrounds-u3488efzA/',
          },
          {
            name: t('navigation.images.backgrounds.gradient'),
            link: '/backgrounds/gradient-backgrounds-eoMy09PMk/',
          },
          {
            name: t('navigation.images.backgrounds.grey'),
            link: '/backgrounds/grey-backgrounds-9yYAxRywV/',
          },
          {
            name: t('navigation.images.backgrounds.grunge'),
            link: '/backgrounds/grunge-backgrounds-SOMtaLes1/',
          },
          {
            name: t('navigation.images.backgrounds.halloween'),
            link: '/backgrounds/halloween-backgrounds-nDfvKrq4p/',
          },
          {
            name: t('navigation.images.backgrounds.happy_birthday'),
            link: '/backgrounds/happy-birthday-backgrounds-lOd8EBhMV/',
          },
          {
            name: t('navigation.images.backgrounds.heart'),
            link: '/backgrounds/heart-backgrounds-JmZAkMuTw/',
          },
          {
            name: t('navigation.images.backgrounds.marble'),
            link: '/backgrounds/marble-backgrounds-vfdxJlBa7/',
          },
          {
            name: t('navigation.images.backgrounds.paster'),
            link: '/backgrounds/pastel-backgrounds-rviQHeFbs/',
          },
          {
            name: t('navigation.images.backgrounds.pink'),
            link: '/backgrounds/pink-backgrounds-olNOIz4ka/',
          },
          {
            name: t('navigation.images.backgrounds.psychedelic'),
            link: '/backgrounds/psychedelic-backgrounds-cTWcGzqvw/',
          },
          {
            name: t('navigation.images.backgrounds.rainbow'),
            link: '/backgrounds/rainbow-backgrounds-mEJkOXz3j/',
          },
          {
            name: t('navigation.images.backgrounds.red'),
            link: '/backgrounds/red-backgrounds-2Irkhmdmz/',
          },
          {
            name: t('navigation.images.backgrounds.sky'),
            link: '/backgrounds/sky-backgrounds-Fl6SJNxle/',
          },
          {
            name: t('navigation.images.backgrounds.snow'),
            link: '/backgrounds/snow-backgrounds-scOlBqRCF/',
          },
          {
            name: t('navigation.images.backgrounds.space'),
            link: '/backgrounds/space-backgrounds-As7wdpK5D/',
          },
          {
            name: t('navigation.images.backgrounds.spring'),
            link: '/backgrounds/spring-backgrounds-Ak7fnqjP5/',
          },
          {
            name: t('navigation.images.backgrounds.summer'),
            link: '/backgrounds/summer-backgrounds-zH06z1LRj/',
          },
          {
            name: t('navigation.images.backgrounds.thanksgiving'),
            link: '/backgrounds/thanksgiving-backgrounds-UWr27ilqD/',
          },
          {
            name: t('navigation.images.backgrounds.unicon'),
            link: '/backgrounds/unicorn-backgrounds-WxCTeTT3q/',
          },
          {
            name: t('navigation.images.backgrounds.water'),
            link: '/backgrounds/water-backgrounds-ceZnkQwve/',
          },
          {
            name: t('navigation.images.backgrounds.watercolor'),
            link: '/backgrounds/watercolor-backgrounds-E5A51z7UK/',
          },
          {
            name: t('navigation.images.backgrounds.white'),
            link: '/backgrounds/white-backgrounds-G6Iifg921/',
          },
          {
            name: t('navigation.images.backgrounds.winter'),
            link: '/backgrounds/winter-backgrounds-84cVOy2Tn/',
          },
          {
            name: t('navigation.images.backgrounds.wolf'),
            link: '/backgrounds/wolf-backgrounds-BWVvHZh3w/',
          },
          {
            name: t('navigation.images.backgrounds.wood'),
            link: '/backgrounds/wood-backgrounds-nTonplkpG/',
          },
          {
            name: t('navigation.images.backgrounds.yellow'),
            link: '/backgrounds/yellow-backgrounds-qavhCZE3q/',
          },
          {
            name: t('navigation.images.backgrounds.browse_all'),
            link: '/backgrounds/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'patterns',
        name: t('navigation.images.patterns.patterns'),
        link: '/patterns/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.patterns.90s'),
            link: '/patterns/90s-patterns-9BCk8Lhzm/',
          },
          {
            name: t('navigation.images.patterns.abstract'),
            link: '/patterns/abstract-patterns-1MSEsOWGi/',
          },
          {
            name: t('navigation.images.patterns.animal'),
            link: '/patterns/animal-patterns-ejPSSS1zW/',
          },
          {
            name: t('navigation.images.patterns.art_deco'),
            link: '/patterns/art-deco-patterns-nX02MucWM/',
          },
          {
            name: t('navigation.images.patterns.black_and_white'),
            link: '/patterns/black-and-white-patterns-FltQtLz8w/',
          },
          {
            name: t('navigation.images.patterns.butterfly_wing'),
            link: '/patterns/butterfly-wing-patterns-QLhVBCBcF/',
          },
          {
            name: t('navigation.images.patterns.camouflage'),
            link: '/patterns/camouflage-patterns-im9TtyKP9/',
          },
          {
            name: t('navigation.images.patterns.celtic'),
            link: '/patterns/celtic-patterns-fpmR3fslD/',
          },
          {
            name: t('navigation.images.patterns.chinese'),
            link: '/patterns/chinese-patterns-yzc7zMCx2/',
          },
          {
            name: t('navigation.images.patterns.christmas'),
            link: '/patterns/christmas-patterns-2qLFtye0z/',
          },
          {
            name: t('navigation.images.patterns.circle'),
            link: '/patterns/circle-patterns-MS3rfBK0e/',
          },
          {
            name: t('navigation.images.patterns.cow'),
            link: '/patterns/cow-patterns-xSH01l7DI/',
          },
          {
            name: t('navigation.images.patterns.curl'),
            link: '/patterns/curl-patterns-Oqa98SzfJ/',
          },
          {
            name: t('navigation.images.patterns.cute'),
            link: '/patterns/cute-patterns-OqqhnLOHn/',
          },
          {
            name: t('navigation.images.patterns.damascus'),
            link: '/patterns/damascus-patterns-sYs9Kw00b/',
          },
          {
            name: t('navigation.images.patterns.damask'),
            link: '/patterns/damask-patterns-XWNo5BGyt/',
          },
          {
            name: t('navigation.images.patterns.decorative'),
            link: '/patterns/decorative-patterns-ok5yLpMvs/',
          },
          {
            name: t('navigation.images.patterns.doodle'),
            link: '/patterns/doodle-patterns-OGHTDsfA4/',
          },
          {
            name: t('navigation.images.patterns.dot'),
            link: '/patterns/dot-patterns-aswGByi7H/',
          },
          {
            name: t('navigation.images.patterns.floral'),
            link: '/patterns/floral-patterns-rkaIqfiBo/',
          },
          {
            name: t('navigation.images.patterns.halloween'),
            link: '/patterns/halloween-patterns-CyRH9xQaA/',
          },
          {
            name: t('navigation.images.patterns.heart'),
            link: '/patterns/heart-patterns-wxj5EhouU/',
          },
          {
            name: t('navigation.images.patterns.ikat'),
            link: '/patterns/ikat-patterns-VouG6T3lP/',
          },
          {
            name: t('navigation.images.patterns.japanese'),
            link: '/patterns/japanese-patterns-WPjTtzwCt/',
          },
          {
            name: t('navigation.images.patterns.kimono'),
            link: '/patterns/kimono-patterns-yVFtYn4fv/',
          },
          {
            name: t('navigation.images.patterns.lace'),
            link: '/patterns/lace-patterns-fs5IoeE1C/',
          },
          {
            name: t('navigation.images.patterns.leaf'),
            link: '/patterns/leaf-patterns-2m0zRH1ly/',
          },
          {
            name: t('navigation.images.patterns.line'),
            link: '/patterns/line-patterns-4xkrc399i/',
          },
          {
            name: t('navigation.images.patterns.mosaic'),
            link: '/patterns/mosaic-patterns-4MTNhT8Bn/',
          },
          {
            name: t('navigation.images.patterns.naturalistic'),
            link: '/patterns/naturalistic-patterns-XbAaxYgJG/',
          },
          {
            name: t('navigation.images.patterns.paisley'),
            link: '/patterns/paisley-patterns-2BYW7wlaG/',
          },
          {
            name: t('navigation.images.patterns.pirate'),
            link: '/patterns/pirate-patterns-vBxFoBrLr/',
          },
          {
            name: t('navigation.images.patterns.rainbow'),
            link: '/patterns/rainbow-patterns-y9ljI7A5U/',
          },
          {
            name: t('navigation.images.patterns.retro'),
            link: '/patterns/retro-patterns-b2sdltRua/',
          },
          {
            name: t('navigation.images.patterns.rose'),
            link: '/patterns/rose-patterns-4N96RrBWs/',
          },
          {
            name: t('navigation.images.patterns.sheet'),
            link: '/patterns/sheet-patterns-EcsM3tn0i/',
          },
          {
            name: t('navigation.images.patterns.star'),
            link: '/patterns/star-patterns-syTgjltj2/',
          },
          {
            name: t('navigation.images.patterns.tile'),
            link: '/patterns/tile-patterns-gBBzv5YAB/',
          },
          {
            name: t('navigation.images.patterns.tribal'),
            link: '/patterns/tribal-patterns-UpDWhmPtY/',
          },
          {
            name: t('navigation.images.patterns.browse_all'),
            link: '/patterns/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'frames',
        name: t('navigation.images.frames.frames'),
        link: '/frames/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.frames.antique'),
            link: '/frames/antique-frame-images-Qk8m5h74U/',
          },
          {
            name: t('navigation.images.frames.artistic'),
            link: '/frames/artistic-frame-images-Xcn7sqak6/',
          },
          {
            name: t('navigation.images.frames.balloon'),
            link: '/frames/balloon-frame-images-n6QeEg8PX/',
          },
          {
            name: t('navigation.images.frames.baroque'),
            link: '/frames/baroque-frame-images-xvMn90VrQ/',
          },
          {
            name: t('navigation.images.frames.birthday'),
            link: '/frames/birthday-frame-images-3xwiZNiN3/',
          },
          {
            name: t('navigation.images.frames.black_and_white'),
            link: '/frames/black-and-white-frame-images-Dtb1NNKYT/',
          },
          {
            name: t('navigation.images.frames.black'),
            link: '/frames/black-frame-images-J8M5N3hzl/',
          },
          {
            name: t('navigation.images.frames.blue'),
            link: '/frames/blue-frame-images-fRLLdlDc2/',
          },
          {
            name: t('navigation.images.frames.butterfly'),
            link: '/frames/butterfly-frame-images-YwjCta4qg/',
          },
          {
            name: t('navigation.images.frames.cartoon'),
            link: '/frames/cartoon-frame-images-DI7DV9OPp/',
          },
          {
            name: t('navigation.images.frames.christmas'),
            link: '/frames/christmas-frame-images-SOM0rdHwo/',
          },
          {
            name: t('navigation.images.frames.circle'),
            link: '/frames/circle-frame-images-Znj9Fi5C3/',
          },
          {
            name: t('navigation.images.frames.dog'),
            link: '/frames/dog-frame-images-cArnXF8Xf/',
          },
          {
            name: t('navigation.images.frames.floral'),
            link: '/frames/floral-frame-images-5f2nuSUbS/',
          },
          {
            name: t('navigation.images.frames.flower'),
            link: '/frames/flower-frame-images-zto8crBOu/',
          },
          {
            name: t('navigation.images.frames.gold'),
            link: '/frames/gold-frame-images-xwLbF1hHo/',
          },
          {
            name: t('navigation.images.frames.gothic'),
            link: '/frames/gothic-frame-images-FGCe800WR/',
          },
          {
            name: t('navigation.images.frames.graduation'),
            link: '/frames/graduation-frame-images-T9CVjlc7h/',
          },
          {
            name: t('navigation.images.frames.browse_all'),
            link: '/frames/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'quotes',
        name: t('navigation.images.quotes.quotes'),
        link: '/quotes/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.quotes.angel_wings'),
            link: '/quotes/angel-wings-GYGfybgLN/',
          },
          {
            name: t('navigation.images.quotes.balloons'),
            link: '/quotes/balloons-QYrDcE5Ut/',
          },
          {
            name: t('navigation.images.quotes.christmas'),
            link: '/quotes/christmas-ro90cvvGE/',
          },
          {
            name: t('navigation.images.quotes.dinosaur'),
            link: '/quotes/dinosaur-m2DpdvDEn/',
          },
          {
            name: t('navigation.images.quotes.easter'),
            link: '/quotes/easter-VwdAlMZuh/',
          },
          {
            name: t('navigation.images.quotes.halloween'),
            link: '/quotes/halloween-gPl1D11F3/',
          },
          {
            name: t('navigation.images.quotes.hearts'),
            link: '/quotes/hearts-UBBHw2IpT/',
          },
          {
            name: t('navigation.images.quotes.lovers'),
            link: '/quotes/lovers-GIaMJqbdk/',
          },
          {
            name: t('navigation.images.quotes.mama'),
            link: '/quotes/mama-oE43i9xPw/',
          },
          {
            name: t('navigation.images.quotes.pumpkins'),
            link: '/quotes/pumpkins-0lfXv08by/',
          },
          {
            name: t('navigation.images.quotes.snowflakes'),
            link: '/quotes/snowflakes-9g5ZbJZbX/',
          },
          {
            name: t('navigation.images.quotes.teachers'),
            link: '/quotes/teachers-F17pAspuz/',
          },
          {
            name: t('navigation.images.quotes.tote_bag'),
            link: '/quotes/tote-bag-ZmWb6n0oR/',
          },
          {
            name: t('navigation.images.quotes.valentine'),
            link: '/quotes/valentine-GDkChNO8g/',
          },
          {
            name: t('navigation.images.quotes.browse_all'),
            link: '/quotes/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'borders_and_corners',
        name: t('navigation.images.borders_and_corners.borders_and_corners'),
        link: '/borders-and-corners/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.borders_and_corners.african'),
            link: '/borders-and-corners/african-L7pOcgi4P/',
          },
          {
            name: t('navigation.images.borders_and_corners.art_nouveau'),
            link: '/borders-and-corners/art-nouveau-No6dU4P1s/',
          },
          {
            name: t('navigation.images.borders_and_corners.asian'),
            link: '/borders-and-corners/asian-UrVKPxWG6/',
          },
          {
            name: t('navigation.images.borders_and_corners.aztec'),
            link: '/borders-and-corners/aztec-qcJy3bUMZ/',
          },
          {
            name: t('navigation.images.borders_and_corners.ball'),
            link: '/borders-and-corners/ball-gQMply2oL/',
          },
          {
            name: t('navigation.images.borders_and_corners.baseball'),
            link: '/borders-and-corners/baseball-jjPabLTfe/',
          },
          {
            name: t('navigation.images.borders_and_corners.black_and_white_leaf'),
            link: '/borders-and-corners/black-and-white-leaf-s97eAPnTe/',
          },
          {
            name: t('navigation.images.borders_and_corners.blue'),
            link: '/borders-and-corners/blue-h7XDZbPiR/',
          },
          {
            name: t('navigation.images.borders_and_corners.boho_floral'),
            link: '/borders-and-corners/boho-floral-ZlQH8UNAz/',
          },
          {
            name: t('navigation.images.borders_and_corners.breakfast'),
            link: '/borders-and-corners/breakfast-oRz7wxeNE/',
          },
          {
            name: t('navigation.images.borders_and_corners.celtic'),
            link: '/borders-and-corners/celtic-CjivifXOe/',
          },
          {
            name: t('navigation.images.borders_and_corners.chinese'),
            link: '/borders-and-corners/chinese-xYFDOSuvl/',
          },
          {
            name: t('navigation.images.borders_and_corners.egyptian'),
            link: '/borders-and-corners/egyptian-kT0OJvnle/',
          },
          {
            name: t('navigation.images.borders_and_corners.elephant'),
            link: '/borders-and-corners/elephant-BxeRp1Pik/',
          },
          {
            name: t('navigation.images.borders_and_corners.fall_leaves'),
            link: '/borders-and-corners/fall-leaves-PCe6Ax2v7/',
          },
          {
            name: t('navigation.images.borders_and_corners.food'),
            link: '/borders-and-corners/food-BOd8nJ85O/',
          },
          {
            name: t('navigation.images.borders_and_corners.grass'),
            link: '/borders-and-corners/grass-WGba4WfTH/',
          },
          {
            name: t('navigation.images.borders_and_corners.green_leaves'),
            link: '/borders-and-corners/green-leaves-pyZ53UTec/',
          },
          {
            name: t('navigation.images.borders_and_corners.indian'),
            link: '/borders-and-corners/indian-M40sMui2R/',
          },
          {
            name: t('navigation.images.borders_and_corners.lilac'),
            link: '/borders-and-corners/lilac-xZRoKUrlY/',
          },
          {
            name: t('navigation.images.borders_and_corners.mexican_flowers'),
            link: '/borders-and-corners/mexican-flowers-WxXqFFuVs/',
          },
          {
            name: t('navigation.images.borders_and_corners.pampas_grass'),
            link: '/borders-and-corners/pampas-grass-vtgN3QjYG/',
          },
          {
            name: t('navigation.images.borders_and_corners.picnic'),
            link: '/borders-and-corners/picnic-F2vZO3CZe/',
          },
          {
            name: t('navigation.images.borders_and_corners.plant'),
            link: '/borders-and-corners/plant-4v0edcZqy/',
          },
          {
            name: t('navigation.images.borders_and_corners.poinsettia'),
            link: '/borders-and-corners/poinsettia-d3V4m9l9k/',
          },
          {
            name: t('navigation.images.borders_and_corners.sea_life'),
            link: '/borders-and-corners/sea-life-ubbnlBu4w/',
          },
          {
            name: t('navigation.images.borders_and_corners.snowflake'),
            link: '/borders-and-corners/snowflake-NqXZPJt6w/',
          },
          {
            name: t('navigation.images.borders_and_corners.succulent'),
            link: '/borders-and-corners/succulent-RdjcBX554/',
          },
          {
            name: t('navigation.images.borders_and_corners.sunflower'),
            link: '/borders-and-corners/sunflower-N7Scz9WXf/',
          },
          {
            name: t('navigation.images.borders_and_corners.tree_branch'),
            link: '/borders-and-corners/tree-branch-UEg8ScPAu/',
          },
          {
            name: t('navigation.images.borders_and_corners.tribal'),
            link: '/borders-and-corners/tribal-pbzTtRQMR/',
          },
          {
            name: t('navigation.images.borders_and_corners.tropical_leaves'),
            link: '/borders-and-corners/tropical-leaves-CehKv0Km5/',
          },
          {
            name: t('navigation.images.borders_and_corners.watercolor_leaves'),
            link: '/borders-and-corners/watercolor-leaves-33ui737sr/',
          },
          {
            name: t('navigation.images.borders_and_corners.browse_all'),
            link: '/borders-and-corners/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'sketches',
        name: t('navigation.images.sketches.sketches'),
        link: '/sketches/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.sketches.alien'),
            link: '/sketches/alien-bizMOwN3b/',
          },
          {
            name: t('navigation.images.sketches.anime'),
            link: '/sketches/anime-MkO0i98lX/',
          },
          {
            name: t('navigation.images.sketches.bear'),
            link: '/sketches/bear-HMEmPq3gg/',
          },
          {
            name: t('navigation.images.sketches.bird'),
            link: '/sketches/bird-qQQmWXC8r/',
          },
          {
            name: t('navigation.images.sketches.bunny'),
            link: '/sketches/bunny-RNl7AzISN/',
          },
          {
            name: t('navigation.images.sketches.butterfly'),
            link: '/sketches/butterfly-iNgwAFLoK/',
          },
          {
            name: t('navigation.images.sketches.cat'),
            link: '/sketches/cat-epL4cNyqR/',
          },
          {
            name: t('navigation.images.sketches.crown'),
            link: '/sketches/crown-o4h1e00Pv/',
          },
          {
            name: t('navigation.images.sketches.cute'),
            link: '/sketches/cute-8EWM9tCRl/',
          },
          {
            name: t('navigation.images.sketches.deer'),
            link: '/sketches/deer-FbQxOQTwj/',
          },
          {
            name: t('navigation.images.sketches.dinosaur'),
            link: '/sketches/dinosaur-65VCcWUf5/',
          },
          {
            name: t('navigation.images.sketches.dog'),
            link: '/sketches/dog-tVJxtRQJV/',
          },
          {
            name: t('navigation.images.sketches.dolphin'),
            link: '/sketches/dolphin-uqf5Z0nSN/',
          },
          {
            name: t('navigation.images.sketches.donkey'),
            link: '/sketches/donkey-60Uc1VL2C/',
          },
          {
            name: t('navigation.images.sketches.dragon'),
            link: '/sketches/dragon-33Zmyya9k/',
          },
          {
            name: t('navigation.images.sketches.dress'),
            link: '/sketches/dress-06S1TxlJL/',
          },
          {
            name: t('navigation.images.sketches.eagle'),
            link: '/sketches/eagle-HZhgdlPp2/',
          },
          {
            name: t('navigation.images.sketches.elephant'),
            link: '/sketches/elephant-gwQibvTsJ/',
          },
          {
            name: t('navigation.images.sketches.fashion'),
            link: '/sketches/fashion-AZsDGhAtm/',
          },
          {
            name: t('navigation.images.sketches.flower'),
            link: '/sketches/flower-7NMqAL8n2/',
          },
          {
            name: t('navigation.images.sketches.fox'),
            link: '/sketches/fox-HzruH1kVk/',
          },
          {
            name: t('navigation.images.sketches.frog'),
            link: '/sketches/frog-oveS2vZwW/',
          },
          {
            name: t('navigation.images.sketches.horse'),
            link: '/sketches/horse-Dqj3ETB6w/',
          },
          {
            name: t('navigation.images.sketches.house'),
            link: '/sketches/house-ifnFFlDV3/',
          },
          {
            name: t('navigation.images.sketches.lips'),
            link: '/sketches/lips-Xn1eae8Ip/',
          },
          {
            name: t('navigation.images.sketches.lotus'),
            link: '/sketches/lotus-6PGc4RiEm/',
          },
          {
            name: t('navigation.images.sketches.monster'),
            link: '/sketches/monster-qLFzcmgPj/',
          },
          {
            name: t('navigation.images.sketches.mushroom'),
            link: '/sketches/mushroom-f2rarGm4Q/',
          },
          {
            name: t('navigation.images.sketches.pumpkin'),
            link: '/sketches/pumpkin-ok9IIF7TF/',
          },
          {
            name: t('navigation.images.sketches.rabbit'),
            link: '/sketches/rabbit-egsjoqySF/',
          },
          {
            name: t('navigation.images.sketches.rose'),
            link: '/sketches/rose-c1xEfehA0/',
          },
          {
            name: t('navigation.images.sketches.seahorse'),
            link: '/sketches/seahorse-pYRdppjiA/',
          },
          {
            name: t('navigation.images.sketches.shoe'),
            link: '/sketches/shoe-iyyWB88At/',
          },
          {
            name: t('navigation.images.sketches.skeleton'),
            link: '/sketches/skeleton-UvyaEkfQV/',
          },
          {
            name: t('navigation.images.sketches.skull'),
            link: '/sketches/skull-KoG2HFE67/',
          },
          {
            name: t('navigation.images.sketches.still_life'),
            link: '/sketches/still-life-vintYc0s2/',
          },
          {
            name: t('navigation.images.sketches.sunflower'),
            link: '/sketches/sunflower-BiJzxYJpS/',
          },
          {
            name: t('navigation.images.sketches.sword'),
            link: '/sketches/sword-dsTW5eQfz/',
          },
          {
            name: t('navigation.images.sketches.teddy_bear'),
            link: '/sketches/teddy-bear-1A720TUuO/',
          },
          {
            name: t('navigation.images.sketches.tiger'),
            link: '/sketches/tiger-7gCBpt5LO/',
          },
          {
            name: t('navigation.images.sketches.tree'),
            link: '/sketches/tree-6wa0QW3S6/',
          },
          {
            name: t('navigation.images.sketches.vase'),
            link: '/sketches/vase-7tkTKXdCP/',
          },
          {
            name: t('navigation.images.sketches.whale'),
            link: '/sketches/whale-0VnlAe6bV/',
          },
          {
            name: t('navigation.images.sketches.wolf'),
            link: '/sketches/wolf-AKSksw8SL/',
          },
          {
            name: t('navigation.images.sketches.browse_all'),
            link: '/sketches/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'coloring_pages',
        name: t('navigation.images.coloring_pages.coloring_pages'),
        link: '/coloring-pages/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.coloring_pages.animal'),
            link: '/coloring-pages/animal-EaWBnJge8/',
          },
          {
            name: t('navigation.images.coloring_pages.cats'),
            link: '/coloring-pages/cats-tgafQk4aP/',
          },
          {
            name: t('navigation.images.coloring_pages.christmas'),
            link: '/coloring-pages/christmas-J6JMRZUsA/',
          },
          {
            name: t('navigation.images.coloring_pages.dog'),
            link: '/coloring-pages/dog-mtcpnQ5te/',
          },
          {
            name: t('navigation.images.coloring_pages.dolphin'),
            link: '/coloring-pages/dolphin-fbKhX8K8z/',
          },
          {
            name: t('navigation.images.coloring_pages.easter'),
            link: '/coloring-pages/easter-lnGBRvELU/',
          },
          {
            name: t('navigation.images.coloring_pages.fairy'),
            link: '/coloring-pages/fairy-fOfIytBck/',
          },
          {
            name: t('navigation.images.coloring_pages.fall'),
            link: '/coloring-pages/fall-dbswUUEzI/',
          },
          {
            name: t('navigation.images.coloring_pages.halloween'),
            link: '/coloring-pages/halloween-2pKR7Ec0W/',
          },
          {
            name: t('navigation.images.coloring_pages.heart'),
            link: '/coloring-pages/heart-DVl6vs9zF/',
          },
          {
            name: t('navigation.images.coloring_pages.st_patricks_day'),
            link: '/coloring-pages/st-patricks-day-zYQTh3iPg/',
          },
          {
            name: t('navigation.images.coloring_pages.summer'),
            link: '/coloring-pages/summer-mBThJgsKV/',
          },
          {
            name: t('navigation.images.coloring_pages.super_hero'),
            link: '/coloring-pages/super-hero-FxU7MICse/',
          },
          {
            name: t('navigation.images.coloring_pages.turkey'),
            link: '/coloring-pages/turkey-EVQeHTMZc/',
          },
          {
            name: t('navigation.images.coloring_pages.puppies'),
            link: '/coloring-pages/puppies-fyJXAPs7k/',
          },
          {
            name: t('navigation.images.coloring_pages.unicorn'),
            link: '/coloring-pages/unicorn-Rf4mBoh89/',
          },
          {
            name: t('navigation.images.coloring_pages.winter'),
            link: '/coloring-pages/winter-w1IalPPiM/',
          },
          {
            name: t('navigation.images.coloring_pages.browse_all'),
            link: '/coloring-pages/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'community',
        name: t('navigation.images.community.community'),
        link: '/community/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.images.community.graphics'),
            link: '/community/graphics-community/',
          },
          {
            name: t('navigation.images.community.patterns'),
            link: '/community/patterns-community/',
          },
          {
            name: t('navigation.images.community.pngs'),
            link: '/community/community-transparent-pngs/',
          },
          {
            name: t('navigation.images.community.sketches'),
            link: '/community/sketches/',
          },
          {
            name: t('navigation.images.community.coloring_pages'),
            link: '/community/community-coloring-pages/',
          },
          {
            name: t('navigation.images.community.search'),
            link: '/community/search/',
          },
          {
            name: t('navigation.images.community.browse_all'),
            link: '/community/',
            isFootOption: true,
          },
        ],
      },
    ],
  }

  const crafts3DMenuItem: NavItem = {
    id: '3d',
    name: t('navigation.3d.3d'),
    link: '/3d-svg/',
    subMenuItems: [
      {
        id: '3d_crafts_paper_cut',
        name: t('navigation.3d.paper_cut.name'),
        link: '/3d-svg/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.3d.paper_cut.holiday'),
            link: '/subscriptions/3d-svg/holidays-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.flowers'),
            link: '/subscriptions/3d-svg/flowers-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.kids_crafts'),
            link: '/subscriptions/3d-svg/kids-crafts-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.paper_sculptures'),
            link: '/subscriptions/3d-svg/paper-sculptures-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.seasons'),
            link: '/subscriptions/3d-svg/seasons-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.baby'),
            link: '/subscriptions/3d-svg/baby-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.religious'),
            link: '/subscriptions/3d-svg/religious-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.home_decor'),
            link: '/subscriptions/3d-svg/home-decor-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.life_events_celebrations'),
            link: '/subscriptions/3d-svg/life-events-and-celebrations-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.countries_travel'),
            link: '/subscriptions/3d-svg/countries-and-travel-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.remembrance'),
            link: '/subscriptions/3d-svg/remembrance-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.animals'),
            link: '/subscriptions/3d-svg/animals-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.lifestyle_hobbies'),
            link: '/subscriptions/3d-svg/lifestyle-and-hobbies-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.kids'),
            link: '/subscriptions/3d-svg/kids-crafts-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.wedding'),
            link: '/subscriptions/3d-svg/wedding-3d-svg/',
          },
          {
            name: t('navigation.3d.paper_cut.browse_all'),
            link: '/3d-svg/',
            isFootOption: true,
          },
        ],
      },
      {
        id: '3d_crafts_laser_cut',
        name: t('navigation.3d.laser_cut.name'),
        link: '/subscriptions/graphics/laser-cut-files/',
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.3d.laser_cut.boxes'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-boxes/',
          },
          {
            name: t('navigation.3d.laser_cut.cake_toppers'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-cake-toppers/',
          },
          {
            name: t('navigation.3d.laser_cut.cards'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-cards/',
          },
          {
            name: t('navigation.3d.laser_cut.earrings'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-earrings/',
          },
          {
            name: t('navigation.3d.laser_cut.keychains'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-keychains/',
          },
          {
            name: t('navigation.3d.laser_cut.birthday'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-birthday/',
          },
          {
            name: t('navigation.3d.laser_cut.ornaments'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-ornaments/',
          },
          {
            name: t('navigation.3d.laser_cut.shadow_boxes'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-shadow-boxes/',
          },
          {
            name: t('navigation.3d.laser_cut.signs'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-signs/',
          },
          {
            name: t('navigation.3d.laser_cut.stands'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-stands/',
          },
          {
            name: t('navigation.3d.laser_cut.wall_decor'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-wall-decor/',
          },
          {
            name: t('navigation.3d.laser_cut.valentines_day'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-valentines-day/',
          },
          {
            name: t('navigation.3d.laser_cut.easter'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-easter/',
          },
          {
            name: t('navigation.3d.laser_cut.mothers_day'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-mothers-day/',
          },
          {
            name: t('navigation.3d.laser_cut.fathers_day'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-fathers-day/',
          },
          {
            name: t('navigation.3d.laser_cut.halloween'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-halloween/',
          },
          {
            name: t('navigation.3d.laser_cut.christmas'),
            link: '/subscriptions/graphics/laser-cut-files/laser-cut-christmas/',
          },
          {
            name: t('navigation.3d.laser_cut.browse_all'),
            link: '/subscriptions/graphics/laser-cut-files/',
            isFootOption: true,
          },
        ],
      },
    ],
  }

  const studioMenuItem: NavItem = {
    id: 'studio',
    name: t('navigation.studio.name'),
    link: '/studio/',
    subMenuItems: [
      {
        id: 'create',
        name: t('navigation.studio.create.design'),
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.studio.create.design'),
            link: `${studioBaseUrl}/studio/create?navigation-bar=templates`,
          },
          {
            name: t('navigation.studio.create.browse_templates'),
            link: '/studio/templates/',
          },
          {
            name: t('navigation.studio.create.generate_png_with_ai'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai&tab=crystalline`,
          },
          {
            name: t('navigation.studio.create.browse_graphics'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=graphics`,
          },
          {
            name: t('navigation.studio.create.browse_photos'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=photos`,
          },
          {
            name: t('navigation.studio.create.browse_backgrounds'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=background`,
          },
          {
            name: t('navigation.studio.create.browse_patterns'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=patterns`,
          },
          {
            name: t('navigation.studio.create.draw'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=draw`,
          },
          {
            name: t('navigation.studio.create.more_tools'),
            link: `${studioBaseUrl}/`,
          },
          {
            name: t('navigation.studio.explore'),
            link: `${baseUrl}${langSegment}/studio/`,
            isFootOption: true,
          },
        ],
      },
      {
        id: 'templates',
        name: t('navigation.studio.templates.name'),
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.studio.templates.discover'),
            link: `${baseUrl}${langSegment}/studio/templates/`,
          },
          {
            name: t('navigation.studio.templates.easter'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=easter`,
          },
          {
            name: t('navigation.studio.templates.mothers_day'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=mothers+day`,
          },
          {
            name: t('navigation.studio.templates.wedding_invites'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=wedding`,
          },
          {
            name: t('navigation.studio.templates.birthday_cards'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=birthday`,
          },
          {
            name: t('navigation.studio.templates.bridal_shower'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=bridal+shower`,
          },
          {
            name: t('navigation.studio.templates.save_the_date'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=save+the+date`,
          },
          {
            name: t('navigation.studio.templates.in_loving_memory'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=loving+memory`,
          },
          {
            name: t('navigation.studio.templates.ticket'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=ticket`,
          },
          {
            name: t('navigation.studio.templates.graduation'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=Graduation`,
          },
          {
            name: t('navigation.studio.templates.halloween_party'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=halloween`,
          },
          {
            name: t('navigation.studio.templates.business_templates'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=business`,
          },
          {
            name: t('navigation.studio.templates.church'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=Church`,
          },
          {
            name: t('navigation.studio.templates.sweet_sixteen'),
            link: `${baseUrl}${langSegment}/studio/templates/?query=Sweet+Sixteen`,
          },
          {
            name: t('navigation.studio.templates.explore'),
            link: `${baseUrl}${langSegment}/studio/templates/`,
            isFootOption: true,
          },
        ],
      },
      {
        id: 'ai_tools',
        name: t('navigation.studio.ai_tools.name'),
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.studio.ai_tools.create_pngs_with_ai'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai`,
          },
          {
            name: t('navigation.studio.ai_tools.create_ai_images'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai&tab=art`,
          },
          {
            name: t('navigation.studio.ai_tools.create_image_variations'),
            link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai&tab=imagemix`,
          },
          {
            name: t('navigation.studio.ai_tools.remove_background'),
            link: `${studioBaseUrl}/background-remover`,
          },
          // {
          //   name: t('navigation.studio.ai_tools.image_inpainting'),
          //   link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai&tab=inpainting`,
          // },
          // {
          //   name: t('navigation.studio.ai_tools.image_outpainting'),
          //   link: `${studioBaseUrl}/studio/create/?navigation-bar=spark-ai&tab=outpainting`,
          // },
          {
            name: t('navigation.studio.explore'),
            link: `${baseUrl}${langSegment}/studio/`,
            isFootOption: true,
          },
        ],
      },
      {
        id: 'other_tools',
        name: t('navigation.studio.other_tools.name'),
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.studio.other_tools.vectorize_designs'),
            link: `${studioBaseUrl}/vectorizer`,
          },
          {
            name: t('navigation.studio.other_tools.send_large_files'),
            link: `${studioBaseUrl}/transfer`,
          },
          {
            name: t('navigation.studio.other_tools.remove_background'),
            link: `${studioBaseUrl}/background-remover`,
          },
          {
            name: t('navigation.studio.explore'),
            link: `${baseUrl}${langSegment}/studio/`,
            isFootOption: true,
          },
        ],
      },
      {
        id: 'features',
        name: t('navigation.studio.features.name'),
        wrapItems: true,
        trending: {},
        subMenuItems: [
          {
            name: t('navigation.studio.features.background_remover'),
            link: `${baseUrl}${langSegment}/studio/features/background-remover/`,
          },
          {
            name: t('navigation.studio.features.png_to_svg'),
            link: `${baseUrl}${langSegment}/studio/features/png-to-svg-converter/`,
          },
          {
            name: t('navigation.studio.features.mirror_image'),
            link: `${baseUrl}${langSegment}/studio/features/mirror-image/`,
          },
          {
            name: t('navigation.studio.features.rotate_image'),
            link: `${baseUrl}${langSegment}/studio/features/rotate-image/`,
          },
          {
            name: t('navigation.studio.features.crop_image'),
            link: `${baseUrl}${langSegment}/studio/features/crop-image/`,
          },
          {
            name: t('navigation.studio.features.add_text_on_image'),
            link: `${baseUrl}${langSegment}/studio/features/add-text-on-image/`,
          },
          {
            name: t('navigation.studio.features.change_photo_background'),
            link: `${baseUrl}${langSegment}/studio/features/change-photo-background-color/`,
          },
          {
            name: t('navigation.studio.features.circle_crop'),
            link: `${baseUrl}${langSegment}/studio/features/circle-crop/`,
          },
          {
            name: t('navigation.studio.features.overlay_images'),
            link: `${baseUrl}${langSegment}/studio/features/overlay-images/`,
          },
          {
            name: t('navigation.studio.features.ai_inpainting_tool'),
            link: `${baseUrl}${langSegment}/studio/features/ai-inpainting-tool/`,
          },
          {
            name: t('navigation.studio.features.resize_icon'),
            link: `${baseUrl}${langSegment}/studio/features/resize-icon/`,
          },
          {
            name: t('navigation.studio.features.watermark_to_photos'),
            link: `${baseUrl}${langSegment}/studio/features/watermark-to-photos/`,
          },
          {
            name: t('navigation.studio.features.view_all'),
            link: `${baseUrl}${langSegment}/studio/features/`,
            isFootOption: true,
          },
        ],
      },
      {
        id: 'start_designing',
        name: t('navigation.studio.start_designing'),
        link: `${studioBaseUrl}/studio/create?navigation-bar=templates`,
        isFootOption: true,
      },
    ],
  }

  const learnMenuItem: NavItem = {
    id: 'learn',
    name: t('navigation.learn.name'),
    subMenuItems: [
      {
        id: 'blog',
        name: t('navigation.learn.blog.guide.name'),
        primaryName: t('navigation.learn.blog.guide.ultimate_guides'),
        secondaryName: t('navigation.learn.blog.articles.name'),
        trending: {
          image:
            'https://www.creativefabrica.com/wp-content/uploads/2023/12/13/0474-Images-nav-menu-10_Artistry-section.png',
          imageLink: '/the-artistry/',
          imageText: t('navigation.learn.blog.image_title'),
        },
        subMenuItems: [
          {
            name: t('navigation.learn.blog.guide.font'),
            link: '/the-ultimate-font-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.cricut'),
            link: '/the-ultimate-cricut-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.silhouette'),
            link: '/the-ultimate-silhouette-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.canvas_workspace'),
            link: '/the-ultimate-brother-canvas-workspace-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.pod'),
            link: '/the-ultimate-pod-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.graphic_design'),
            link: '/the-ultimate-graphic-design-guide-2/',
          },
          {
            name: t('navigation.learn.blog.guide.machine_embroidery'),
            link: '/the-ultimate-machine-embroidery-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.ai_art'),
            link: '/the-ultimate-ai-guide/',
          },
          {
            name: t('navigation.learn.blog.guide.etsy'),
            link: '/the-ultimate-etsy-guide/',
          },
          {
            name: t('navigation.learn.blog.view_all'),
            link: '/the-artistry/',
            isFootOption: true,
          },
        ],
        secondarySubMenuItems: [
          {
            name: t('navigation.learn.blog.articles.art1'),
            wrapTitle: true,
            link: '/the-artistry/fonts-and-digital-designs/learn-how-to-pair-fonts-with-25-perfect-font-combinations/',
          },
          {
            name: t('navigation.learn.blog.articles.art2'),
            wrapTitle: true,
            link: '/the-artistry/fonts-and-digital-designs/exclusive-offer-5-svg-cut-file-templates-for-free/',
          },
          {
            name: t('navigation.learn.blog.articles.art3'),
            wrapTitle: true,
            link: '/the-artistry/fonts-and-digital-designs/exclusive-offer-10-graphic-sets-for-free/',
          },
          {
            name: t('navigation.learn.blog.articles.art4'),
            wrapTitle: true,
            link: '/the-artistry/cricut-design-space/tutorial-using-digital-papers-in-design-space/',
          },
          {
            name: t('navigation.learn.blog.articles.art5'),
            wrapTitle: true,
            link: '/the-artistry/sublimation/how-to-sublimate-on-cotton-shirts/',
          },
          {
            name: t('navigation.learn.blog.articles.art6'),
            wrapTitle: true,
            link: '/the-artistry/kdp/the-top-5-profitable-amazon-kdp-niches-for-low-content/',
          },
          {
            name: t('navigation.learn.blog.articles.art7'),
            wrapTitle: true,
            link: '/the-artistry/christmas/creating-custom-split-monogram-name-ornaments/',
          },
          {
            name: t('navigation.learn.blog.articles.art8'),
            wrapTitle: true,
            link: '/the-artistry/cricut-design-space/the-best-fonts-for-vinyl-cutting/',
          },
          {
            name: t('navigation.learn.blog.articles.art9'),
            wrapTitle: true,
            link: '/the-artistry/sublimation/how-to-bleach-shirts-design-with-sublimation/',
          },
          {
            name: t('navigation.learn.blog.articles.art10'),
            wrapTitle: true,
            link: '/the-artistry/kdp/how-to-make-money-publishing-low-content-books-on-amazon-kdp/',
          },
        ],
      },
      {
        id: 'video-classes',
        name: t('navigation.learn.video.name'),
        wrapItems: true,
        trending: {
          image:
            'https://www.creativefabrica.com/wp-content/uploads/2023/12/13/0474-Images-nav-menu-07_Teaching-materials-section.png',
          imageLink: '/classes/',
          imageText: t('navigation.learn.video.explore_all'),
        },
        subMenuItems: [
          {
            name: t('navigation.learn.video.cricut'),
            link: '/subscriptions/classes/cricut/',
          },
          {
            name: t('navigation.learn.video.graphic_design'),
            link: '/subscriptions/classes/graphic-design/',
          },
          {
            name: t('navigation.learn.video.illustrating'),
            link: '/subscriptions/classes/illustrating/',
          },
          {
            name: t('navigation.learn.video.printing_sublimation'),
            link: '/subscriptions/classes/printing-sublimation/',
          },
          {
            name: t('navigation.learn.video.organizing_planning'),
            link: '/subscriptions/classes/organizing-planning/',
          },
          {
            name: t('navigation.learn.video.etsy'),
            link: '/subscriptions/classes/etsy/',
          },
          {
            name: t('navigation.learn.video.paper_crafting'),
            link: '/subscriptions/classes/paper-crafting/',
          },
          {
            name: t('navigation.learn.video.business'),
            link: '/subscriptions/classes/craft-business/',
          },
          {
            name: t('navigation.learn.video.embroidery'),
            link: '/subscriptions/classes/embroidery-classes/',
          },
          {
            name: t('navigation.learn.video.seasonal'),
            link: '/subscriptions/classes/seasonal/',
          },
          {
            name: t('navigation.learn.video.resin'),
            link: '/subscriptions/classes/resin/',
          },
          {
            name: t('navigation.learn.video.sewing'),
            link: '/subscriptions/classes/sewing/',
          },
          {
            name: t('navigation.learn.video.crochet'),
            link: '/subscriptions/classes/crochet-classes/',
          },
          {
            name: t('navigation.learn.video.macrame'),
            link: '/subscriptions/classes/macrame/',
          },
          {
            name: t('navigation.learn.video.painting'),
            link: '/subscriptions/classes/painting/',
          },
          {
            name: t('navigation.learn.video.clays'),
            link: '/subscriptions/classes/clays/',
          },
          {
            name: t('navigation.learn.video.candles_soap'),
            link: '/subscriptions/classes/candles-and-soap/',
          },
          {
            name: t('navigation.learn.video.scrapbooking'),
            link: '/subscriptions/classes/scrapbooking/',
          },
          {
            name: t('navigation.learn.video.doll_making'),
            link: '/subscriptions/classes/doll-making/',
          },
          {
            name: t('navigation.learn.video.jewelry_making'),
            link: '/subscriptions/classes/jewelry-making/',
          },
          {
            name: t('navigation.learn.video.silhouette'),
            link: '/subscriptions/classes/silhouette/',
          },
          {
            name: t('navigation.learn.video.home_decor'),
            link: '/subscriptions/classes/home-decor/',
          },
          {
            name: t('navigation.learn.video.die_cutting'),
            link: '/subscriptions/classes/die-cutting/',
          },
          {
            name: t('navigation.learn.video.woodworking'),
            link: '/subscriptions/classes/woodworking/',
          },
          {
            name: t('navigation.learn.video.view_all'),
            link: '/classes/',
            isFootOption: true,
          },
        ],
      },
      {
        id: 'craft-ideas',
        name: t('navigation.learn.craft_ideas.name'),
        wrapItems: true,
        subMenuItems: [
          {
            name: t('navigation.learn.craft_ideas.wedding'),
            link: '/ideas/wedding/',
          },
          {
            name: t('navigation.learn.craft_ideas.easter'),
            link: '/ideas/easter/',
          },
          {
            name: t('navigation.learn.craft_ideas.halloween'),
            link: '/ideas/halloween/',
          },
          {
            name: t('navigation.learn.craft_ideas.4th_of_july'),
            link: '/ideas/4th-of-july/',
          },
          {
            name: t('navigation.learn.craft_ideas.mothers_day'),
            link: '/ideas/mothers-day/',
          },
          {
            name: t('navigation.learn.craft_ideas.christmas'),
            link: '/ideas/christmas/',
          },
        ],
      },
      {
        id: 'ask-cf',
        name: t('navigation.learn.ask_cf.name'),
        title: t('navigation.learn.ask_cf.title'),
        link: `${askCFBaseUrl}${langSegment}/topics/`,
        trending: {
          image: 'https://lfs.creativefabrica.com/ask-cf/images/Ask-CF-header-menu.png',
        },
        wrapItems: true,
        subMenuItems: [
          {
            name: t('navigation.learn.ask_cf.fonts'),
            link: `${askCFBaseUrl}${langSegment}/topics/fonts/`,
          },
          {
            name: t('navigation.learn.ask_cf.etsy_store'),
            link: `${askCFBaseUrl}${langSegment}/topics/etsy-store/`,
          },
          {
            name: t('navigation.learn.ask_cf.sublimation'),
            link: `${askCFBaseUrl}${langSegment}/topics/sublimation/`,
          },
          {
            name: t('navigation.learn.ask_cf.cricut'),
            link: `${askCFBaseUrl}${langSegment}/topics/cricut/`,
          },
          {
            name: t('navigation.learn.ask_cf.3d_svgs'),
            link: `${askCFBaseUrl}${langSegment}/topics/3d-svgs/`,
          },
          {
            name: t('navigation.learn.ask_cf.crochet'),
            link: `${askCFBaseUrl}${langSegment}/topics/crochet/`,
          },
          {
            name: t('navigation.learn.ask_cf.spark_ai'),
            link: `${askCFBaseUrl}${langSegment}/topics/spark-ai/`,
          },
          {
            name: t('navigation.learn.ask_cf.embroidery'),
            link: `${askCFBaseUrl}${langSegment}/topics/embroidery/`,
          },
          {
            name: t('navigation.learn.ask_cf.laser_cutting'),
            link: `${askCFBaseUrl}${langSegment}/topics/laser-cutting/`,
          },
          {
            name: t('navigation.learn.ask_cf.applique'),
            link: `${askCFBaseUrl}${langSegment}/topics/applique/`,
          },
          {
            name: t('navigation.learn.ask_cf.baking_and_cake_decorating'),
            link: `${askCFBaseUrl}${langSegment}/topics/baking-and-cake-decorating/`,
          },
          {
            name: t('navigation.learn.ask_cf.card_making'),
            link: `${askCFBaseUrl}${langSegment}/topics/card-making/`,
          },
          {
            name: t('navigation.learn.ask_cf.drawing'),
            link: `${askCFBaseUrl}${langSegment}/topics/drawing/`,
          },
          {
            name: t('navigation.learn.ask_cf.etching'),
            link: `${askCFBaseUrl}${langSegment}/topics/etching/`,
          },
          {
            name: t('navigation.learn.ask_cf.graphic_design'),
            link: `${askCFBaseUrl}${langSegment}/topics/graphic-design/`,
          },
          {
            name: t('navigation.learn.ask_cf.knitting'),
            link: `${askCFBaseUrl}${langSegment}/topics/knitting/`,
          },
          {
            name: t('navigation.learn.ask_cf.pngs'),
            link: `${askCFBaseUrl}${langSegment}/topics/pngs/`,
          },
          {
            name: t('navigation.learn.ask_cf.apparel_design'),
            link: `${askCFBaseUrl}${langSegment}/topics/apparel-design/`,
          },
          {
            name: t('navigation.learn.ask_cf.clay'),
            link: `${askCFBaseUrl}${langSegment}/topics/clay/`,
          },
          {
            name: t('navigation.learn.ask_cf.decoupage'),
            link: `${askCFBaseUrl}${langSegment}/topics/decoupage/`,
          },
          {
            name: t('navigation.learn.ask_cf.doll_making'),
            link: `${askCFBaseUrl}${langSegment}/topics/doll-making/`,
          },
          {
            name: t('navigation.learn.ask_cf.embossing'),
            link: `${askCFBaseUrl}${langSegment}/topics/embossing/`,
          },
          {
            name: t('navigation.learn.ask_cf.floral_designs'),
            link: `${askCFBaseUrl}${langSegment}/topics/floral-design/`,
          },
          {
            name: t('navigation.learn.ask_cf.hand_embroidery'),
            link: `${askCFBaseUrl}${langSegment}/topics/hand-embroidery/`,
          },
          {
            name: t('navigation.learn.ask_cf.kids_crafts'),
            link: `${askCFBaseUrl}${langSegment}/topics/kids-crafts/`,
          },
          {
            name: t('navigation.learn.ask_cf.leather_working'),
            link: `${askCFBaseUrl}${langSegment}/topics/leatherworking/`,
          },
          {
            name: t('navigation.learn.ask_cf.organizing_and_planning'),
            link: `${askCFBaseUrl}${langSegment}/topics/organizing-and-planning/`,
          },
          {
            name: t('navigation.learn.ask_cf.origami'),
            link: `${askCFBaseUrl}${langSegment}/topics/origami/`,
          },
          {
            name: t('navigation.learn.ask_cf.paper_crafting'),
            link: `${askCFBaseUrl}${langSegment}/topics/paper-crafting/`,
          },
          {
            name: t('navigation.learn.ask_cf.printing'),
            link: `${askCFBaseUrl}${langSegment}/topics/printing/`,
          },
          {
            name: t('navigation.learn.ask_cf.rhinestones'),
            link: `${askCFBaseUrl}${langSegment}/topics/rhinestones/`,
          },
          {
            name: t('navigation.learn.ask_cf.sewing'),
            link: `${askCFBaseUrl}${langSegment}/topics/sewing/`,
          },
          {
            name: t('navigation.learn.ask_cf.silhouette'),
            link: `${askCFBaseUrl}${langSegment}/topics/silhouette/`,
          },
          {
            name: t('navigation.learn.ask_cf.studio'),
            link: `${askCFBaseUrl}${langSegment}/topics/studio/`,
          },
          {
            name: t('navigation.learn.ask_cf.vinyl'),
            link: `${askCFBaseUrl}${langSegment}/topics/vinyl/`,
          },
          {
            name: t('navigation.learn.ask_cf.waterslide_decal'),
            link: `${askCFBaseUrl}${langSegment}/topics/waterslide-decal/`,
          },
          {
            name: t('navigation.learn.ask_cf.view_all'),
            link: `${askCFBaseUrl}${langSegment}/`,
            isFootOption: true,
          },
        ],
      },
    ],
  }

  const navItems: NavItem[] = [
    fontsMenuItem,
    imagesMenuItem,
    crafts3DMenuItem,
    {
      id: 'crafts',
      name: t('navigation.crafts.crafts'),
      link: '/crafts/',
      subMenuItems: [
        {
          name: t('navigation.crafts.craft_designs'),
          link: '/crafts/',
        },
        {
          name: t('navigation.crafts.deals'),
          link: '/the-craft-club/deals/',
        },
        {
          name: t('navigation.crafts.halloween'),
          link: '/subscriptions/crafts/halloween/',
        },
        {
          name: t('navigation.crafts.thanksgiving'),
          link: '/subscriptions/crafts/thanksgiving/',
        },
        {
          name: t('navigation.crafts.kids'),
          link: '/subscriptions/crafts/kids/',
        },
        {
          name: t('navigation.crafts.fall'),
          link: '/subscriptions/crafts/fall/',
        },
        {
          name: t('navigation.crafts.easter'),
          link: '/subscriptions/crafts/easter/',
        },
        {
          name: t('navigation.crafts.spring'),
          link: '/subscriptions/crafts/spring/',
        },
        {
          name: t('navigation.graphics.browse_all'),
          link: '/crafts/',
        },
      ],
    },
    {
      id: 'needlework',
      name: t('navigation.needlework.name'),
      link: '/subscriptions/graphics/needle-arts/',
      subMenuItems: [
        {
          id: 'embroidery',
          name: t('navigation.needlework.embroidery_designs.name'),
          link: '/embroidery/',
          subMenuItems: [
            {
              id: 'animals',
              name: t('navigation.needlework.embroidery_designs.animals.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.animals.name'),
                  link: '/subscriptions/embroidery/animals-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.quotes'),
                  link: '/subscriptions/embroidery/animals-embroidery/animal-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.baby_animals'),
                  link: '/subscriptions/embroidery/animals-embroidery/baby-animals/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.birds'),
                  link: '/subscriptions/embroidery/animals-embroidery/birds/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.bugs_insects'),
                  link: '/subscriptions/embroidery/animals-embroidery/bugs-insects/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.cats'),
                  link: '/subscriptions/embroidery/animals-embroidery/cats-animals-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.dinosaurs'),
                  link: '/subscriptions/embroidery/animals-embroidery/dinosaurs-animals-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.dogs'),
                  link: '/subscriptions/embroidery/animals-embroidery/dogs-animals-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.farm_animals'),
                  link: '/subscriptions/embroidery/animals-embroidery/farm-animals/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.fish_shells'),
                  link: '/subscriptions/embroidery/animals-embroidery/fish-shells/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.horses'),
                  link: '/subscriptions/embroidery/animals-embroidery/horses/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.marine_mammals'),
                  link: '/subscriptions/embroidery/animals-embroidery/marine-mammals/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.reptiles'),
                  link: '/subscriptions/embroidery/animals-embroidery/reptiles/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.wild_animals'),
                  link: '/subscriptions/embroidery/animals-embroidery/wild-animals/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.animals.woodland_animals'),
                  link: '/subscriptions/embroidery/animals-embroidery/woodland-animals/',
                },
              ],
            },
            {
              id: 'around_the_world',
              name: t('navigation.needlework.embroidery_designs.around_the_world.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.name'),
                  link: '/subscriptions/embroidery/around-the-world/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.africa'),
                  link: '/subscriptions/embroidery/around-the-world/africa/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.asia'),
                  link: '/subscriptions/embroidery/around-the-world/asia/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.australia'),
                  link: '/subscriptions/embroidery/around-the-world/australia-around-the-world/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.europe'),
                  link: '/subscriptions/embroidery/around-the-world/europe/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.around_the_world.mexico'),
                  link: '/subscriptions/embroidery/around-the-world/mexico-around-the-world/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.around_the_world.north_america',
                  ),
                  link: '/subscriptions/embroidery/around-the-world/north-america/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.around_the_world.south_america',
                  ),
                  link: '/subscriptions/embroidery/around-the-world/south-america/',
                },
              ],
            },
            {
              id: 'awareness_inspiration',
              name: t('navigation.needlework.embroidery_designs.awareness_inspiration.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.awareness_inspiration.name'),
                  link: '/subscriptions/embroidery/awareness-inspiration/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.awareness_inspiration.awareness',
                  ),
                  link: '/subscriptions/embroidery/awareness-inspiration/awareness-awareness-inspiration/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.awareness_inspiration.inspirational',
                  ),
                  link: '/subscriptions/embroidery/awareness-inspiration/inspirational/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.awareness_inspiration.remembrance',
                  ),
                  link: '/subscriptions/embroidery/awareness-inspiration/remembrance-awareness-inspiration/',
                },
              ],
            },
            {
              id: 'babies_kids',
              name: t('navigation.needlework.embroidery_designs.babies_kids.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.name'),
                  link: '/subscriptions/embroidery/babies-kids/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.quotes'),
                  link: '/subscriptions/embroidery/babies-kids/babies-kids-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.back_to_school'),
                  link: '/subscriptions/embroidery/babies-kids/back-to-school/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.bed_bath'),
                  link: '/subscriptions/embroidery/babies-kids/bed-bath/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.boys_girls'),
                  link: '/subscriptions/embroidery/babies-kids/boys-girls/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.circus_clowns'),
                  link: '/subscriptions/embroidery/babies-kids/circus-clowns/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.cowboy_cowgirl'),
                  link: '/subscriptions/embroidery/babies-kids/cowboy-cowgirl/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.fairy_tales'),
                  link: '/subscriptions/embroidery/babies-kids/fairy-tales-babies-kids//',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.nursery'),
                  link: '/subscriptions/embroidery/babies-kids/nursery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.pirates'),
                  link: '/subscriptions/embroidery/babies-kids/pirates-babies-kids/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.robots_space'),
                  link: '/subscriptions/embroidery/babies-kids/robots-space/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.tedy_bears'),
                  link: '/subscriptions/embroidery/babies-kids/teddy-bears//',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.teenagers'),
                  link: '/subscriptions/embroidery/babies-kids/teenagers/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.babies_kids.toys_games'),
                  link: '/subscriptions/embroidery/babies-kids/toys-games/',
                },
              ],
            },
            {
              id: 'family_friends',
              name: t('navigation.needlework.embroidery_designs.family_friends.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.name'),
                  link: '/subscriptions/embroidery/family-friends/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.adoption'),
                  link: '/subscriptions/embroidery/family-friends/adoption-family-friends/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.quotes'),
                  link: '/subscriptions/embroidery/family-friends/family-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.father'),
                  link: '/subscriptions/embroidery/family-friends/father/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.friends'),
                  link: '/subscriptions/embroidery/family-friends/friends/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.friends_quotes'),
                  link: '/subscriptions/embroidery/family-friends/friends-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.grandchildren'),
                  link: '/subscriptions/embroidery/family-friends/grandchildren/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.grandparents'),
                  link: '/subscriptions/embroidery/family-friends/grandparents/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.mother'),
                  link: '/subscriptions/embroidery/family-friends/mother/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.family_friends.relatives'),
                  link: '/subscriptions/embroidery/family-friends/relatives/',
                },
              ],
            },
            {
              id: 'fashion_beauty',
              name: t('navigation.needlework.embroidery_designs.fashion_beauty.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.fashion_beauty.name'),
                  link: '/subscriptions/embroidery/fashion-beauty/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.fashion_beauty.accessories'),
                  link: '/subscriptions/embroidery/fashion-beauty/accessories/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.fashion_beauty.beauty'),
                  link: '/subscriptions/embroidery/fashion-beauty/beauty/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.fashion_beauty.boho'),
                  link: '/subscriptions/embroidery/fashion-beauty/boho-fashion-beauty/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.fashion_beauty.clothing'),
                  link: '/subscriptions/embroidery/fashion-beauty/boho-fashion-beauty/',
                },
              ],
            },
            {
              id: 'floral_garden',
              name: t('navigation.needlework.embroidery_designs.floral_garden.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.floral_garden.name'),
                  link: '/subscriptions/embroidery/floral-garden/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.floral_garden.bouquets_bunches',
                  ),
                  link: '/subscriptions/embroidery/floral-garden/bouquets-bunches/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.floral_garden.floral_wraths'),
                  link: '/subscriptions/embroidery/floral-garden/floral-wreaths/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.floral_garden.forest_trees'),
                  link: '/subscriptions/embroidery/floral-garden/forest-trees/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.floral_garden.outdoor_quotes'),
                  link: '/subscriptions/embroidery/floral-garden/outdoor-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.floral_garden.outline_flowers'),
                  link: '/subscriptions/embroidery/floral-garden/outline-flowers/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.floral_garden.single_flowers_plants',
                  ),
                  link: '/subscriptions/embroidery/floral-garden/single-flowers-and-plants/',
                },
              ],
            },
            {
              id: 'food_drink',
              name: t('navigation.needlework.embroidery_designs.hobbies_sports.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.name'),
                  link: '/subscriptions/embroidery/hobbies-sports/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.hobbies_sports.camping_fishing',
                  ),
                  link: '/subscriptions/embroidery/hobbies-sports/camping-fishing/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.dance_drama'),
                  link: '/subscriptions/embroidery/hobbies-sports/dance-drama/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.games_leisure'),
                  link: '/subscriptions/embroidery/hobbies-sports/games-leisure/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.music'),
                  link: '/subscriptions/embroidery/hobbies-sports/music-hobbies-sports/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.sewing_crafts'),
                  link: '/subscriptions/embroidery/hobbies-sports/sewing-crafts/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.sports'),
                  link: '/subscriptions/embroidery/hobbies-sports/sports-hobbies-sports/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.hobbies_sports.welness'),
                  link: '/subscriptions/embroidery/hobbies-sports/wellness-hobbies-sports/',
                },
              ],
            },
            {
              id: 'holidays_celebrations',
              name: t('navigation.needlework.embroidery_designs.holidays_celebrations.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.holidays_celebrations.name'),
                  link: '/subscriptions/embroidery/holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.anniversary',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/anniversary-holidays-celebrations/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.holidays_celebrations.baptism'),
                  link: '/subscriptions/embroidery/holidays-celebrations/baptism/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.birthdays',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/birthdays/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.christmas',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/christmas-holidays-celebrations/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.holidays_celebrations.easter'),
                  link: '/subscriptions/embroidery/holidays-celebrations/easter-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.father_day',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/fathers-day-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.graduation',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/graduation/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.halloween',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/halloween-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.indepenence_day',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/independence-day-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.mother_day',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/mothers-day-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.st_patrick',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/st-patricks-day/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.thanksgiving',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/thanksgiving-holidays-celebrations/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.holidays_celebrations.valentine',
                  ),
                  link: '/subscriptions/embroidery/holidays-celebrations/valentines-day-holidays-celebrations/',
                },
              ],
            },
            {
              id: 'house_home',
              name: t('navigation.needlework.embroidery_designs.house_home.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.name'),
                  link: '/subscriptions/embroidery/house-home/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.bathroom'),
                  link: '/subscriptions/embroidery/house-home/bathroom-house-home/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.bedroom'),
                  link: '/subscriptions/embroidery/house-home/bedroom-house-home/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.cleaning'),
                  link: '/subscriptions/embroidery/house-home/cleaning/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.desserts_sweets'),
                  link: '/subscriptions/embroidery/house-home/dessert-sweets/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.farm_country'),
                  link: '/subscriptions/embroidery/house-home/farm-country-house-home/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.food_dining'),
                  link: '/subscriptions/embroidery/house-home/food-dining/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.quotes'),
                  link: '/subscriptions/embroidery/house-home/house-home-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.kitchen_cooking'),
                  link: '/subscriptions/embroidery/house-home/house-home-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.tea_coffee'),
                  link: '/subscriptions/embroidery/house-home/tea-coffee/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.house_home.wine_drinks'),
                  link: '/subscriptions/embroidery/house-home/wine-drinks/',
                },
              ],
            },
            {
              id: 'inspirational_quotes',
              name: t('navigation.needlework.embroidery_designs.shapes.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.shapes.name'),
                  link: '/subscriptions/embroidery/shapes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.shapes.intricate_cuts'),
                  link: '/subscriptions/embroidery/shapes/intricate-cuts-shapes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.shapes.mandala'),
                  link: '/subscriptions/embroidery/shapes/mandala/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.shapes.paisley'),
                  link: '/subscriptions/embroidery/shapes/paisley-shapes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.shapes.zentangle'),
                  link: '/subscriptions/embroidery/shapes/zentangle-shapes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.more_categories.backgrounds'),
                  link: '/subscriptions/embroidery/backgrounds-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.more_categories.borders'),
                  link: '/subscriptions/embroidery/borders/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.more_categories.fonts'),
                  link: '/subscriptions/embroidery/fonts-embroidery/',
                },
              ],
            },
            {
              id: 'nature',
              name: t('navigation.needlework.embroidery_designs.travel_season.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.name'),
                  link: '/subscriptions/embroidery/travel-season/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.autumn'),
                  link: '/subscriptions/embroidery/travel-season/autumn/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.beach_nautical'),
                  link: '/subscriptions/embroidery/travel-season/beach-nautical/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.spring'),
                  link: '/subscriptions/embroidery/travel-season/spring-travel-season/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.summer'),
                  link: '/subscriptions/embroidery/travel-season/summer-travel-season/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.transportation'),
                  link: '/subscriptions/embroidery/travel-season/transportation-travel-season/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.travel_quotes'),
                  link: '/subscriptions/embroidery/travel-season/travel-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.vacation'),
                  link: '/subscriptions/embroidery/travel-season/vacation/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.travel_season.winter'),
                  link: '/subscriptions/embroidery/travel-season/winter-travel-season/',
                },
              ],
            },
            {
              id: 'wedding',
              name: t('navigation.needlework.embroidery_designs.wedding.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.name'),
                  link: '/subscriptions/embroidery/wedding-embroidery/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.bachelor'),
                  link: '/subscriptions/embroidery/wedding-embroidery/bachelor/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.bachelorette'),
                  link: '/subscriptions/embroidery/wedding-embroidery/bachelorette/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.husband'),
                  link: '/subscriptions/embroidery/wedding-embroidery/husband/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.desings'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wedding-designs/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.family'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wedding-family/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.flowers'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wedding-flowers/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.monogram'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wedding-monogram/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.quotes'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wedding-quotes/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.wedding.wife'),
                  link: '/subscriptions/embroidery/wedding-embroidery/wife/',
                },
              ],
            },
            {
              id: 'work_religion_school',
              name: t('navigation.needlework.embroidery_designs.work_religion_school.name'),
              subMenuItems: [
                {
                  name: t('navigation.needlework.embroidery_designs.work_religion_school.name'),
                  link: '/subscriptions/embroidery/work-religion-school/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.work_religion_school.cities_villages',
                  ),
                  link: '/subscriptions/embroidery/work-religion-school/cities-villages/',
                },
                {
                  name: t('navigation.needlework.embroidery_designs.work_religion_school.military'),
                  link: '/subscriptions/embroidery/work-religion-school/military-work-religion-school/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.work_religion_school.religion_faith',
                  ),
                  link: '/subscriptions/embroidery/work-religion-school/religion-faith/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.work_religion_school.school_education',
                  ),
                  link: '/subscriptions/embroidery/work-religion-school/school-education/',
                },
                {
                  name: t(
                    'navigation.needlework.embroidery_designs.work_religion_school.work_occuption',
                  ),
                  link: '/subscriptions/embroidery/work-religion-school/work-occupation/',
                },
              ],
            },
            {
              name: t('navigation.needlework.embroidery_designs.more_categories.view_all'),
              link: '/embroidery/',
              isFootOption: true,
            },
          ],
        },
        {
          id: 'knitting_patterns',
          name: t('navigation.needlework.knitting_patterns.name'),
          link: '/subscriptions/graphics/needle-arts/knitting/',
          wrapItems: true,
          trending: {
            title: t('navigation.trending'),
            image:
              'https://www.creativefabrica.com/wp-content/uploads/2024/03/15/knitting-patterns.jpg',
            imageLink: '/subscriptions/graphics/needle-arts/knitting/',
          },
          subMenuItems: [
            {
              name: t('navigation.needlework.knitting_patterns.accessories'),
              link: '/subscriptions/graphics/needle-arts/knitting/knit-accessories/',
            },
            {
              name: t('navigation.needlework.knitting_patterns.cloting'),
              link: '/subscriptions/graphics/needle-arts/knitting/knit-clothing/',
            },
            {
              name: t('navigation.needlework.knitting_patterns.home_decor'),
              link: '/subscriptions/graphics/needle-arts/knitting/knit-home-decor/',
            },
            {
              name: t('navigation.needlework.knitting_patterns.toy_dolls'),
              link: '/subscriptions/graphics/needle-arts/knitting/knit-toys-and-dolls/',
            },
            {
              name: t('navigation.needlework.knitting_patterns.pets'),
              link: '/subscriptions/graphics/needle-arts/knitting/knits-for-pets/',
            },
            {
              name: t('navigation.needlework.knitting_patterns.view_all'),
              isFootOption: true,
              link: '/subscriptions/graphics/needle-arts/knitting/',
            },
          ],
        },
        {
          id: 'more_patterns',
          name: t('navigation.needlework.more_patterns.name'),
          link: '/subscriptions/graphics/needle-arts/',
          wrapItems: true,
          trending: {
            title: t('navigation.trending'),
            image:
              'https://www.creativefabrica.com/wp-content/uploads/2024/03/14/needlework-patterns.jpg',
            imageLink: '/subscriptions/graphics/needle-arts/',
          },
          subMenuItems: [
            {
              name: t('navigation.needlework.more_patterns.beading'),
              link: '/subscriptions/graphics/needle-arts/beading-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.crochet'),
              link: '/subscriptions/graphics/needle-arts/crochet/',
            },
            {
              name: t('navigation.needlework.more_patterns.cross_stitch'),
              link: '/subscriptions/graphics/needle-arts/cross-stitch-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.hand_embroidery'),
              link: '/subscriptions/graphics/needle-arts/hand-embroidery-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.knitting'),
              link: '/subscriptions/graphics/needle-arts/knitting/',
            },
            {
              name: t('navigation.needlework.more_patterns.macrame'),
              link: '/subscriptions/graphics/needle-arts/macrame-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.quilt'),
              link: '/subscriptions/graphics/needle-arts/quilt-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.sewing'),
              link: '/subscriptions/graphics/needle-arts/sewing-patterns/',
            },
            {
              name: t('navigation.needlework.more_patterns.view_all'),
              isFootOption: true,
              link: '/subscriptions/graphics/needle-arts/',
            },
          ],
        },
        {
          id: 'browse_all_needle_arts',
          isFootOption: true,
          name: t('navigation.graphics.browse_all'),
          link: '/subscriptions/graphics/needle-arts/',
        },
      ],
    },
    {
      id: 'photos',
      name: t('navigation.photos'),
      link: '/stockphotos/',
    },
    {
      id: 'tools',
      name: t('navigation.tools.tools'),
      link: '/tools/',
      subMenuItems: [
        {
          name: t('navigation.tools.spark'),
          link: '/spark/',
        },
        {
          name: t('navigation.fontcloud'),
          link: '/fontcloud/',
        },
        {
          name: t('navigation.shapecloud'),
          link: '/shapecloud/',
        },
        {
          name: t('navigation.webfont_generator'),
          link: '/webfont-generator/',
        },
        {
          name: t('navigation.background_remover'),
          link: '/studio/features/background-remover/',
        },
        {
          name: t('navigation.svg_converter'),
          link: '/studio/features/vectorize-image/',
        },
        {
          name: t('navigation.the_artistry'),
          link: '/the-artistry/',
        },
        {
          name: t('navigation.inspiration'),
          link: '/masterfeed/',
        },
        {
          name: t('navigation.tools.font_generator'),
          link: '/tools/font-generator/',
        },
        {
          name: t('navigation.teachers_club'),
          link: '/teachers/',
        },
        {
          name: t('navigation.graphics.browse_all'),
          link: '/tools/',
        },
      ],
    },
    {
      id: 'pod',
      name: t('navigation.pod'),
      link: '/pod/',
    },
    {
      id: 'bundles',
      name: t('navigation.bundles'),
      link: '/bundles/',
    },
    learnMenuItem,
    studioMenuItem,
  ]

  if (!isAuthLoading && !hasActiveSubscription) {
    navItems.push({
      id: 'subscription',
      name: t('navigation.subscription'),
      link: '/subscription/',
    })
  }

  if (!isAuthLoading && isAuthenticated && hasActiveSubscription && !hasAllAccessSubscription) {
    navItems.push({
      id: 'upgrade',
      name: t('navigation.upgrade'),
      link: '/subscription/',
    })
  }

  return navItems
}
