import { useContext } from 'react';

import { SnackbarContext } from 'components/contexts/snackbarProvider/SnackbarProvider';

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);

  if (!context) {
    throw new Error('useSnackbar should be used inside a component');
  }

  return context;
};
