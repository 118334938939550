import * as React from 'react';
import Link from 'next/link';
import { Header as CfHeader } from 'cf-web/packages/cf-menu/components/header';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { HeaderContextProvider } from 'cf-web/packages/cf-menu/contexts/header-config-context';
import { MainNavigationContextProvider } from 'cf-web/packages/cf-menu/contexts/main-navigation-context';

import { FutureImage } from 'components/elements/atoms/image/FutureImage';
import { Translations, useFormatMessage } from 'utilities/i18n';
import { useCurrencyAndLanguageProps, useHandleLogoutClick } from 'hooks/useHeaderActions/useHeaderActions';
import { AuthModal } from 'components/elements/organisms/authModal/AuthModal';
import { useAuthModalState } from 'hooks/useAuthModalState/useAuthModalState';

interface CustomLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  href: string;
}

const CustomLink = React.forwardRef(
  ({ children, className, href, ...rest }: CustomLinkProps, ref: React.ForwardedRef<HTMLAnchorElement>) => {
    return (
      <Link {...rest} ref={ref} href={href} passHref>
        <a className={className} {...rest}>
          {children}
        </a>
      </Link>
    );
  },
);

export const Header = () => {
  const router = useRouter();
  const translate = useFormatMessage();
  const { handleLoginClick, handleSignUpClick, getAuthModalProps } = useAuthModalState();
  const handleLogoutClick = useHandleLogoutClick();
  const t = React.useCallback(
    (key: string) => {
      return translate(`cf_menu.${key}` as Translations);
    },
    [translate],
  );

  const currencyAndLanguageProps = useCurrencyAndLanguageProps();

  return (
    <>
      <HeaderContextProvider
        {...currencyAndLanguageProps}
        i18n={t}
        onLoginClick={handleLoginClick}
        onSignUpClick={handleSignUpClick}
        onLogoutClick={handleLogoutClick}
        utils={{
          Image: FutureImage,
          Link: CustomLink,
          router: {
            push: router.push,
            pathname: router.asPath,
          },
          dynamic,
        }}
      >
        <MainNavigationContextProvider>
          <CfHeader />
        </MainNavigationContextProvider>
      </HeaderContextProvider>
      <AuthModal {...getAuthModalProps()} />
    </>
  );
};
