import { useCallback, useEffect, useMemo, useState } from 'react';
import { throttle } from 'throttle-debounce';

import { useDomEvent } from 'hooks/useDomEvent/useDomEvent';
import { Icon } from 'components/elements/atoms/icon/Icon';

import { ScrollToTopButtonProps } from './ScrollToTopButton.types';
import * as S from './ScrollToTopButton.styles';

const VISIBLE_SCROLL_POSITION = 350;
const HIDE_FLAMES_SCROLL_POSITION = 800;
const THROTTLE_DELAY = 50;

export const ScrollToTopButton = (props: ScrollToTopButtonProps) => {
  const { hideOnMobile } = props;
  const [isVisible, setVisible] = useState(false);
  const [withFlames, setWithFlames] = useState(false);

  const updateVisibility = useCallback(() => {
    setVisible(window.scrollY > VISIBLE_SCROLL_POSITION);

    if (window.scrollY < HIDE_FLAMES_SCROLL_POSITION) {
      setWithFlames(false);
    }
  }, []);

  const handleScroll = useMemo(() => throttle(THROTTLE_DELAY, updateVisibility), [updateVisibility]);

  const handleClick = () => {
    setWithFlames(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    updateVisibility();
  }, [handleScroll, updateVisibility]);

  useDomEvent(() => window, 'scroll', handleScroll, { passive: true });

  return (
    <S.ScrollToTopButton
      isVisible={isVisible}
      withFlames={withFlames}
      hideOnMobile={hideOnMobile}
      onClick={handleClick}
    >
      <Icon icon="rocket" color="grayPurple.main" />
      <S.Flame />
      <S.Flame />
      <S.Flame />
    </S.ScrollToTopButton>
  );
};
