import { defaultLanguage } from 'cf-i18n/constants'

export enum AppRoute {
  PROD = '/prod/',
  SEARCH = '/search/',
  REACT_SEARCH = '/react/search/',
}

export const getPathnameWithLocalePrefix = (pathname: string, locale: string) => {
  return locale === defaultLanguage ? pathname : `/${locale}${pathname}`
}
