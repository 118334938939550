import * as NavigationMenu from 'primitives/navigation-menu'

import { NavigationItem } from './navigation-item'
import * as MenuItem from '../menu-item'
import { useTranslations } from '../../contexts/header-config-context'
import { useStoreSlug } from '../../contexts/auth'
import { StorefrontIcon } from '../icons/storefront-icon'
import { NavItem } from '../../hooks/use-nav-items'

export function StoreMenu() {
  const storeSlug = useStoreSlug()
  const hasStore = !!storeSlug
  const t = useTranslations()

  if (!hasStore) {
    return null
  }

  const menuItem: NavItem = {
    name: 'store',
    link: `/designer/${storeSlug}/`,
    subMenuItems: [
      {
        name: t('top_nav.store'),
        link: `/designer/${storeSlug}/`,
      },
      {
        name: t('store.store_dashboard'),
        link: '/dashboard/',
      },
      {
        name: t('store.boost_store'),
        link: '/dashboard/manage/boost/',
      },
      {
        name: t('store.search_analytics'),
        link: '/dashboard/manage/search-analytics/',
      },
      {
        name: t('store.store_settings'),
        link: '/dashboard/personal-data/',
      },
    ],
  }

  return (
    <NavigationItem
      key="store"
      trigger={
        <NavigationMenu.Trigger className="focus:bg-cf-lavender">
          <MenuItem.Link
            href={`/designer/${storeSlug}/`}
            className={'focus-within:bg-cf-lavender flex-col gap-0.5'}
          >
            <StorefrontIcon className="h-5" />
            <span className="grow whitespace-nowrap">{t('top_nav.store')}</span>
          </MenuItem.Link>
        </NavigationMenu.Trigger>
      }
      className="pb-4"
      item={menuItem}
    />
  )
}
