import * as Avatar from 'primitives/avatar'

import { useAuthContext } from '../contexts/auth'

export function UserAvatar() {
  const authData = useAuthContext()
  const userData = authData?.userData
  const name = `${userData?.firstName ?? ''} ${userData?.lastName ?? ''} avatar image`
  const initials = `${userData?.firstName?.[0] ?? ''}${userData?.lastName?.[0] ?? ''}`

  return (
    <Avatar.Root
      size="small"
      rounded="full"
      className="inline-flex h-5 w-5 min-w-5 shrink-0 select-none"
    >
      <Avatar.Image src={userData?.avatarUrl ?? ''} alt={name} />
      <Avatar.Fallback className="leading-1 bg-cf-dreamy-cloud-400 text-cf-dreamy-cloud-900 text-xxs flex h-5 w-5 items-center justify-center rounded-full font-bold uppercase">
        {initials}
      </Avatar.Fallback>
    </Avatar.Root>
  )
}
