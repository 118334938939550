export function SparkInpaintingIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11 2H6.8C5.11984 2 4.27976 2 3.63803 2.32698C3.07354 2.6146 2.6146 3.07354 2.32698 3.63803C2 4.27976 2 5.11984 2 6.8V17.2C2 18.8802 2 19.7202 2.32698 20.362C2.6146 20.9265 3.07354 21.3854 3.63803 21.673C4.27976 22 5.11984 22 6.8 22H17.2C18.8802 22 19.7202 22 20.362 21.673C20.9265 21.3854 21.3854 20.9265 21.673 20.362C22 19.7202 22 18.8802 22 17.2V13.4632"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 15C16.4565 15 16.8306 14.6643 16.894 14.1891C17.5217 9.95747 18.0987 9.36833 22.1694 8.90588C22.6386 8.84887 23 8.45611 23 8C23 7.53756 22.6449 7.15747 22.1757 7.08778C18.1304 6.52398 17.6295 6.02986 16.894 1.80452C16.8116 1.33575 16.4502 1 16 1C15.5371 1 15.1694 1.33575 15.0933 1.81086C14.4783 6.0362 13.9013 6.62534 9.83696 7.08778C9.35507 7.15113 9 7.53122 9 8C9 8.45611 9.34239 8.8362 9.82428 8.90588C13.8759 9.48235 14.3705 9.97014 15.0933 14.1955C15.1884 14.6706 15.5562 15 16 15Z"
        fill="currentColor"
      />
      <path
        d="M9 19C9.26087 19 9.47464 18.8081 9.51087 18.5367C9.86957 16.1186 10.1993 15.7819 12.5254 15.5176C12.7935 15.4851 13 15.2606 13 15C13 14.7357 12.7971 14.5186 12.529 14.4787C10.2174 14.1566 9.93116 13.8742 9.51087 11.4597C9.46377 11.1919 9.25725 11 9 11C8.73551 11 8.52536 11.1919 8.48188 11.4633C8.13043 13.8778 7.80072 14.2145 5.47826 14.4787C5.2029 14.5149 5 14.7321 5 15C5 15.2606 5.19565 15.4778 5.47101 15.5176C7.78623 15.8471 8.06884 16.1258 8.48188 18.5403C8.53623 18.8118 8.74638 19 9 19Z"
        fill="currentColor"
      />
    </svg>
  )
}
