import { styled, Typography } from '@mui/material';

import { Link } from 'components/elements/atoms/link/Link';
import { Snackbar } from 'components/elements/atoms/snackbar/Snackbar';

export const SnackbarStyled = styled(Snackbar)(({ theme }) => ({
  borderRadius: 4,
  textAlign: 'center',
  maxWidth: theme.spacing(31.25),
  boxShadow: theme.shadows[1],
  '& .MuiPaper-root.MuiAlert-filled': {
    background: theme.palette.common.white,
    color: theme.palette.common.black,
    padding: theme.spacing(2),
    display: 'block',
  },
  '& .MuiAlert-action button.MuiButtonBase-root': {
    position: 'absolute',
    top: theme.spacing(-1.5),
    right: theme.spacing(-1.5),
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    borderRadius: 16,
    '&:hover': {
      opacity: 1,
    },
  },
}));

export const Title = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.secondary.main,
}));

export const Text = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium,
  margin: theme.spacing(1.25, 0, 3),
  lineHeight: 1.3,
}));

export const LinkStyled = styled(Link)(({ theme }) => ({
  padding: theme.spacing(1.25, 2),
  borderRadius: 4,
}));

export const LinkText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightSemiBold,
  color: theme.palette.common.white,
  display: 'inline-block',
  textTransform: 'uppercase',
}));
