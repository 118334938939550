import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import { Image } from '../../image'
import { Container } from './nested-menu-container'
import { useActivePrimaryItem } from '../../../contexts/main-navigation-context'
import { ArrowNarrowUpRight } from '../../icons/arrow-narrow-up-right'
import { useLinkComponent } from '../../../contexts/header-config-context'

export type TrendingCardProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>

export function TrendingSection(props: TrendingCardProps) {
  const Link = useLinkComponent()
  const [primary] = useActivePrimaryItem()
  const viewAllItem = primary?.subMenuItems?.find(item => item.isFootOption)

  if (!primary?.trending) {
    return null
  }

  const { trending } = primary

  if (trending.layout === 'horizontal' && Array.isArray(trending.items)) {
    return (
      <Container asChild key="trending-section">
        <div {...props}>
          <div className="w-full items-start justify-start">
            {trending.title ? (
              <h3 className="font-bold">{trending.title}</h3>
            ) : (
              <div className="mb-9"></div>
            )}
            {trending.items.map((item, index) => (
              <NavigationMenu.Link
                key={index}
                href={item.iconLink}
                className="flex w-full items-center justify-start p-4"
                asChild
              >
                <Link>
                  <div className="mr-3 h-8 w-8">{item.icon}</div>
                  <span>{item.iconText}</span>
                </Link>
              </NavigationMenu.Link>
            ))}
          </div>
        </div>
      </Container>
    )
  }

  if (!('items' in trending)) {
    const { image, imageLink, imageText, title } = trending
    const footerItem = viewAllItem?.name
    const footerLink = viewAllItem?.link

    return (
      <Container asChild key="trending-section">
        <div {...props}>
          <div className="flex flex-col items-end justify-stretch">
            <span className="w-full font-bold">{title}</span>
            <div className="bg-cf-white rounded-2 overflow-hidden">
              {imageLink ? (
                <Link href={imageLink}>
                  {image ? (
                    <Image
                      src={image}
                      alt="trending"
                      className="h-fit w-[200px] min-[1200px]:w-[230px]"
                      height={144}
                      width={230}
                    />
                  ) : null}
                  {imageText ? (
                    <p className="text-14 w-[200px] p-4 font-medium leading-5 min-[1200px]:w-[230px]">
                      {imageText}
                    </p>
                  ) : null}
                </Link>
              ) : null}
            </div>
          </div>
          {footerLink ? (
            <NavigationMenu.Link
              className={tw(
                'text-14 border-cf-dreamy-cloud-600 mt-12 inline-block w-[200px] border-t px-4 py-2 font-semibold',
                'flex items-center text-center',
              )}
              href={footerLink}
              asChild
            >
              <Link>
                <span>{footerItem} </span>
                <span className="pl-4">
                  <ArrowNarrowUpRight className="inline-block" />
                </span>
              </Link>
            </NavigationMenu.Link>
          ) : null}
        </div>
      </Container>
    )
  }

  return null
}
