import { useSnackbar } from 'hooks/useSnackbar/useSnackbar';
import { RequestErrorHandler, UseRequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler.types';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { meFetcher } from 'api/requests/me/me';
import { AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useFormatMessage } from 'utilities/i18n';

export const useRequestErrorHandler: UseRequestErrorHandler = ({ hasSnackbar }) => {
  const showSnackbar = useSnackbar();
  const { isAuthorized } = useAuthState();
  const dispatch = useAuthDispatch();
  const t = useFormatMessage();

  const errorHandler: RequestErrorHandler = async error => {
    const isAuthenticationError = error?.status && [401, 403].includes(error.status);

    if (isAuthenticationError && isAuthorized) {
      const { user } = await meFetcher();

      if (!user) {
        dispatch({ type: AuthActionType.LOGOUT });

        showSnackbar({
          children: t('common.sign_out_error'),
        });

        return;
      }
    }

    if (hasSnackbar) {
      const { message } = error;

      showSnackbar({
        children: message || t('common.unknown_error'),
        severity: 'error',
      });
    }
  };

  return errorHandler;
};
