import React, { useEffect } from 'react';

import { motion, useAnimation } from 'lib/framer-motion';
import { Icon } from 'components/elements/atoms/icon/Icon';

import { AccordionProps, AccordionTriggerProps } from './Accordion.types';

export const Accordion = ({ children, expanded, delay = 0 }: AccordionProps) => {
  const controls = useAnimation();

  const variants = {
    expanded: { opacity: 1, height: 'auto', transition: { duration: 0.3, delay: delay } },
    collapsed: { opacity: 0, height: 0, transition: { duration: 0.3, delay: 0 } },
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (expanded) {
        controls.start('expanded');
      } else {
        controls.start('collapsed');
      }
    }, 100);

    return () => clearTimeout(timer);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expanded]);

  return (
    <motion.div
      initial={expanded ? 'expanded' : 'collapsed'}
      className="z-0 overflow-hidden"
      animate={controls}
      variants={variants}
    >
      {children}
    </motion.div>
  );
};

const Trigger = ({ expanded, onClick, header }: AccordionTriggerProps) => {
  return (
    <div className="flex items-center justify-between hover:cursor-pointer" onClick={onClick}>
      {typeof header === 'string' ? <span className="text-sm font-bold">{header}</span> : header}
      {expanded ? <Icon icon="chevron-down" /> : <Icon icon="chevron-up" />}
    </div>
  );
};

Accordion.Trigger = Trigger;
