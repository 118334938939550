import invariant from 'tiny-invariant'

const NEXT_PUBLIC_STUDIO_CF_URL = process.env.NEXT_PUBLIC_STUDIO_CF_URL
const NEXT_PUBLIC_CF_URL = process.env.NEXT_PUBLIC_CF_URL
const NEXT_PUBLIC_ASK_CF_URL = process.env.NEXT_PUBLIC_ASK_CF_URL

invariant(NEXT_PUBLIC_STUDIO_CF_URL, 'NEXT_PUBLIC_STUDIO_CF_URL is not defined')
invariant(NEXT_PUBLIC_CF_URL, 'NEXT_PUBLIC_CF_URL is not defined')
invariant(NEXT_PUBLIC_ASK_CF_URL, 'NEXT_PUBLIC_ASK_CF_URL is not defined')

export const studioBaseUrl = NEXT_PUBLIC_STUDIO_CF_URL
export const baseUrl = NEXT_PUBLIC_CF_URL
export const studioLink = `${NEXT_PUBLIC_CF_URL}/studio`
export const studioTemplatesLink = `${NEXT_PUBLIC_STUDIO_CF_URL}/studio/create?navigation-bar=templates`
export const askCFBaseUrl = NEXT_PUBLIC_ASK_CF_URL
