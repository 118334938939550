import { createContext } from 'react';
import { Box } from '@mui/material';

import { FormControlProps, FormControlState } from 'components/elements/atoms/formControl/FormControl.types';

export const FormControlContext = createContext<FormControlState | null>(null);

export const FormControl = (props: FormControlProps) => {
  const {
    children,
    required = false,
    error,
    disabled = false,
    hideErrorText = false,
    fullWidth = true,
    component,
  } = props;

  const value = {
    required,
    error,
    disabled,
    hideErrorText,
    fullWidth,
  };

  const renderChildren = () => {
    if (component) {
      return <Box component={component}>{children}</Box>;
    }

    return children;
  };

  return <FormControlContext.Provider value={value}>{renderChildren()}</FormControlContext.Provider>;
};
