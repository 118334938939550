import { useRouter } from 'next/router';
import { useCallback, useContext, useState } from 'react';
import { useCookies } from 'react-cookie';
import qs from 'qs';
import { DEFAULT_LOCALE } from 'translations/locales';
import { CurrencyCode, Currency as CurrencyObj, Language } from 'cf-web/packages/codegen/types';
import { currencyCodeToCurrency } from 'cf-web/packages/cf-menu/utils/currencies';
import { languageMap } from 'cf-i18n/constants';
import { useUpdateUserCurrencyMutation, useUpdateUserLanguageMutation } from 'codegen/generated/user-preferences';

import { Currency, DEFAULT_CURRENCY } from 'api/requests/site/site.types';
import { AppRoute } from 'components/app/App.types';
import { AuthActionType } from 'components/contexts/authProvider/authReducer/authReducer.types';
import { translatableFilters } from 'components/pages/stockphotos/pages/stockPhotosSearchPage/StockPhotosSearchPage.utils';
import { useAuthDispatch } from 'hooks/useAuthDispatch/useAuthDispatch';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { useCurrentCurrency } from 'hooks/useCurrency/useCurrency';
import {
  googleTrackLangAndCurrencyChange,
  googleTrackLangAndCurrencyClick,
} from 'components/app/analytics/googleAnalytics/GoogleAnalytics.utils';
import { LogoutContext } from 'components/contexts/requestStateProviders/logoutProvider/LogoutProvider';

export function useCurrencyAndLanguageProps(): {
  activeCurrency: CurrencyObj;
  lang: Language;
  onLanguageChange: (lang: Language) => Promise<void>;
  onCurrencyChange: (curr: CurrencyCode) => Promise<void>;
  onCurrencyOpen: () => void;
  onLanguageOpen: () => void;
} {
  const router = useRouter();
  const user = useOptionalUser(); // This returns null on localhost
  const dispatch = useAuthDispatch();
  const { pathname, query, locale } = router;
  const [activeLanguage, setLanguage] = useState<Language>((locale || DEFAULT_LOCALE) as Language);
  const defaultCurrency = useCurrentCurrency();
  const updateUserLanguageMutation = useUpdateUserLanguageMutation();
  const updateUserCurrencyMutation = useUpdateUserCurrencyMutation();
  const [, setLangCookie] = useCookies(['lang']);
  const [, setCurrencyCookie] = useCookies(['currency']);

  const [currency, setCurrency] = useState<Currency>(defaultCurrency);
  const activeCurrency = currencyCodeToCurrency(currency);
  const activeCurrencyCode = activeCurrency.code ?? (DEFAULT_CURRENCY.toLowerCase() as CurrencyCode);

  const refereshUser = useCallback(
    async (newLanguage: Language, newCurrency: Currency) => {
      if ((user?.wallet.currency.code !== newCurrency || newLanguage !== locale) && user) {
        if (newLanguage !== locale) {
          await updateUserLanguageMutation.mutateAsync({ language: newLanguage });
        }

        if (user?.wallet.currency.code !== newCurrency) {
          await updateUserCurrencyMutation.mutateAsync({ currency: newCurrency.toLocaleLowerCase() as CurrencyCode });
        }

        dispatch({
          type: AuthActionType.REFRESH_USER,
          user: {
            ...user,
            currency: newCurrency,
            localization: {
              ...user.localization,
              languageCode: newLanguage,
              languageLabel: languageMap.get(newLanguage)!,
            },
          },
        });
      }
    },
    [user, dispatch, locale, updateUserLanguageMutation, updateUserCurrencyMutation],
  );

  const onLanguageChange = useCallback(
    async (lang: Language) => {
      googleTrackLangAndCurrencyChange(activeCurrencyCode, activeCurrencyCode, activeLanguage, lang);

      if (locale !== lang) {
        setLangCookie('lang', lang, { domain: '.creativefabrica.com', path: '/' });
        const isStockphotosSearchPage = router.asPath.indexOf(AppRoute.STOCKPHOTOS_SEARCH) !== -1;
        const isPngSearchPage = router.asPath.indexOf(AppRoute.PNG_SEARCH) !== -1;

        if (isStockphotosSearchPage || isPngSearchPage) {
          const newQuery = { ...query };
          translatableFilters.forEach(filter => {
            delete newQuery[filter];
          });
          const queryString = qs.stringify(newQuery, { arrayFormat: 'comma' });

          const newUrl = `${window.location.protocol}//${window.location.host}${
            lang !== DEFAULT_LOCALE ? `/${lang}` : ''
          }${isStockphotosSearchPage ? AppRoute.STOCKPHOTOS_SEARCH : AppRoute.PNG_SEARCH}${
            queryString ? `?${queryString}` : ''
          }`;
          window.location.assign(newUrl);

          return;
        }
        setLanguage(lang);
        await refereshUser(lang, currency);
        router.push({ pathname, query }, undefined, { locale: lang });
      } else {
        setLanguage(lang);
        await refereshUser(lang, currency);
        router.replace(router.asPath);
      }
    },
    [router, currency, locale, query, pathname, activeLanguage, activeCurrencyCode, refereshUser, setLangCookie],
  );

  const onCurrencyChange = useCallback(
    async (curr: CurrencyCode) => {
      const c = curr.toUpperCase() as Currency;
      googleTrackLangAndCurrencyChange(activeCurrencyCode, c, activeLanguage, activeLanguage);

      setCurrencyCookie('currency', c, { domain: '.creativefabrica.com', path: '/' });

      setCurrency(c);
      await refereshUser(activeLanguage, c);
      window.location.reload();
    },
    [activeCurrencyCode, activeLanguage, refereshUser, setCurrencyCookie],
  );

  const trackCurrencyOrLanguageOpen = useCallback(() => {
    googleTrackLangAndCurrencyClick(currency, activeLanguage);
  }, [currency, activeLanguage]);

  return {
    activeCurrency,
    lang: activeLanguage,
    onLanguageChange,
    onCurrencyChange,
    onCurrencyOpen: trackCurrencyOrLanguageOpen,
    onLanguageOpen: trackCurrencyOrLanguageOpen,
  };
}

export function useHandleLogoutClick() {
  const logoutContext = useContext(LogoutContext);

  if (!logoutContext) {
    throw new Error('LogoutContext is unavailable');
  }

  const { logout } = logoutContext;

  return logout;
}
