import { useCfMenuUserQuery } from 'codegen/generated/cf-menu'
import { isLoggedInQuerySelector } from 'auth/selectors'

export function useAuthContext() {
  const { data, isLoading } = useCfMenuUserQuery()
  const storeSlug = useStoreSlug()

  if (data?.me?.user && isLoggedInQuerySelector(data)) {
    return {
      userData: {
        id: data.me.user.id,
        firstName: data.me.user.firstName,
        lastName: data.me.user.lastName,
        avatarUrl: data.me.user.avatarUrl,
        slug: data.me.user.slug,
      },
      storeSlug,
      isLoading,
    }
  }

  return { userData: null, storeSlug, isLoading }
}

export function useIsAuthenticated() {
  // eslint-disable-next-line local/codegen-query-patterns
  return useCfMenuUserQuery(undefined, {
    select: isLoggedInQuerySelector,
  }).data
}

export function useIsAuthLoading() {
  const { isLoading } = useCfMenuUserQuery()
  return isLoading
}

export function useStoreSlug() {
  // eslint-disable-next-line local/codegen-query-patterns
  return useCfMenuUserQuery(undefined, {
    select: data => (data?.me?.user?.roles?.includes('designer') ? data?.me?.user?.slug ?? '' : ''),
  }).data
}

export function useHasStore() {
  const storeSlug = useStoreSlug()
  return Boolean(storeSlug)
}

export function useUserData() {
  const { userData } = useAuthContext()
  return userData
}

export function useSubscriptions() {
  const { data } = useCfMenuUserQuery()
  return data?.me?.user?.subscriptions
}

export function useHasAllAccess() {
  const subscriptions = useSubscriptions()
  return !!subscriptions?.allAccess
}

export function useHasActiveSubscription() {
  const subscriptions = useSubscriptions()

  return subscriptions && Object.values(subscriptions).some(value => value === true)
}

export function useUserLanguage() {
  // eslint-disable-next-line local/codegen-query-patterns
  return useCfMenuUserQuery(undefined, {
    select: data => data?.me?.user?.language,
  }).data
}
