import * as React from 'react'
import { useCookies } from 'react-cookie'
import { useQuery } from '@tanstack/react-query'
import { useConfigure, useConnector, useInstantSearch } from 'react-instantsearch'
import connectAutocomplete, {
  AutocompleteConnectorParams,
  AutocompleteWidgetDescription,
} from 'instantsearch.js/es/connectors/autocomplete/connectAutocomplete'
import { Hit } from 'instantsearch.js/es/types'
import debounce from 'lodash.debounce'
import {
  SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
} from 'constants/cookies'

import { AlgoliaContentHit, SearchProduct } from './search.types'
import { useLocale, useRouterUtil } from '../../contexts/header-config-context'
import {
  DEFAULT_ALGOLIA_LANGUAGE,
  MIN_REFINEMENT_LENGTH,
  mapLocaleToContentLanguage,
} from './algolia.configs'
import { AppRoute, getPathnameWithLocalePrefix } from '../../utils/route'
import { TextParsedAutocompleteHit } from './autocomplete-hit'
import { Results } from './autocomplete-result'
import { fetchContentData } from './api'
import { Autocomplete } from './autocomplete'
import { InstantSearchProvider } from './search-provider'
import { saveQueryToRecentSearches } from './recent-searches'

export const EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED = [
  '"AI Illustrations"',
  '"AI Graphics"',
  '"AI Transparent PNGs"',
  '"AI Generated"',
  '"AI Patterns"',
  '"AI Coloring Pages"',
  '"AI Sketches"',
]

function CustomSearchAutocomplete() {
  const [cookies] = useCookies([
    SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
    SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME,
  ])
  const isCommunityContentEnabled =
    cookies[SEARCH_PAGE_COMMUNITY_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true'
  const isAIContentEnabled = cookies[SEARCH_PAGE_AI_CONTENT_ENABLED_TOGGLE_COOKIE_NAME] === 'true'

  const excludeFilter =
    EXCLUDED_PRODUCTS_CATEGORIES_WHEN_AI_CONTENT_IS_DISABLED.join(' AND NOT category:')
  useConfigure({
    hitsPerPage: 5,
    filters: `${isCommunityContentEnabled ? '' : 'NOT type:Community'}${
      isAIContentEnabled
        ? ''
        : `${isCommunityContentEnabled ? '' : ' AND '}NOT category:${excludeFilter}`
    }`,
  })
  const { indices, refine, currentRefinement } = useConnector<
    AutocompleteConnectorParams,
    AutocompleteWidgetDescription
  >(connectAutocomplete)
  const { status } = useInstantSearch()
  const hits = indices[0].hits as unknown as Hit<SearchProduct>[]
  const router = useRouterUtil()
  const pathname = router.pathname
  const locale = useLocale()
  const algoliaLang = mapLocaleToContentLanguage.get(locale) || DEFAULT_ALGOLIA_LANGUAGE
  const [autocompleteQuery, setAutocompleteQuery] = React.useState(currentRefinement)

  const { isLoading, data } = useQuery({
    queryKey: ['search', autocompleteQuery],
    queryFn: () => fetchContentData(autocompleteQuery, 0, 1),
    enabled: status === 'loading' && autocompleteQuery.length >= MIN_REFINEMENT_LENGTH,
  })
  const contentData = data?.hits as Hit<AlgoliaContentHit>[]

  const renderResult = (hit: Hit<SearchProduct | AlgoliaContentHit>) => {
    if ('slug' in hit) {
      const prodUrl = `${AppRoute.PROD}${hit.slug ? hit.slug[algoliaLang] : ''}-${hit.objectID}/`

      return <TextParsedAutocompleteHit key={hit.objectID} href={prodUrl} hit={hit} />
    }

    const url =
      hit.url.indexOf('creativefabrica.com') !== -1
        ? hit.url.split('creativefabrica.com')[1]
        : hit.url

    return <Results hit={hit} href={url} key={hit.objectID} />
  }

  const debouncedAlgoliaSearchRef = React.useRef(
    debounce((query: string) => {
      refine(query)
    }, 300),
  )

  React.useEffect(() => {
    debouncedAlgoliaSearchRef.current = debounce((query: string) => {
      refine(query)
    }, 300)
  }, [refine])

  React.useEffect(() => {
    setAutocompleteQuery('')
  }, [pathname])

  const debouncedAlgoliaSearch = React.useCallback((query: string) => {
    debouncedAlgoliaSearchRef.current(query)
  }, [])

  const handleOnChange = React.useCallback(
    (newValue: string) => {
      setAutocompleteQuery(newValue)

      if (newValue.length >= MIN_REFINEMENT_LENGTH) {
        debouncedAlgoliaSearch(newValue)
      }
    },
    [setAutocompleteQuery, debouncedAlgoliaSearch],
  )

  const toRenderHits = React.useMemo(() => {
    if (contentData && contentData.length > 0) {
      if (hits.length === 5) {
        return [...hits.slice(0, 4), contentData[0]]
      } else {
        return [...hits, contentData[0]]
      }
    } else {
      return hits
    }
  }, [contentData, hits])

  const handleSearchSubmit = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault()

      // Only save the query if it has hits
      if (toRenderHits.length > 0) {
        saveQueryToRecentSearches(autocompleteQuery)
      }

      // TODO: Replace with router.push when the search page is moved to the new stack
      // router.push(
      //   `${AppRoute.SEARCH}${autocompleteQuery.length > 0 ? `?query=${autocompleteQuery}` : ''}`,
      // )
      window.location.assign(
        getPathnameWithLocalePrefix(
          `${AppRoute.SEARCH}${autocompleteQuery.length > 0 ? `?query=${autocompleteQuery}` : ''}`,
          locale || 'en',
        ),
      )
    },
    [toRenderHits.length, autocompleteQuery, locale],
  )

  return (
    <Autocomplete
      currentRefinement={autocompleteQuery}
      renderResult={renderResult}
      hits={isLoading ? [] : toRenderHits.slice(0, 5)}
      refine={refine}
      onSubmit={handleSearchSubmit}
      onChange={handleOnChange}
    />
  )
}

export function SearchAutocomplete() {
  return (
    <InstantSearchProvider>
      <CustomSearchAutocomplete />
    </InstantSearchProvider>
  )
}
