import * as React from 'react'

import { NavItem, useNavItems } from '../hooks/use-nav-items'

export const MenuForSeo = React.memo(function MenuForSEO() {
  const navItems = useNavItems()

  const renderSubMenu = (subMenuItems: NavItem[]) => {
    return (
      <ul>
        {subMenuItems.map((item, index) => (
          <li key={`${item.name}-${index}`}>
            {item.link ? <a href={item.link}>{item.name}</a> : <span>{item.name}</span>}
            {item.subMenuItems && item.subMenuItems.length > 0
              ? renderSubMenu(item.subMenuItems)
              : null}
            {item.secondarySubMenuItems && item.secondarySubMenuItems.length > 0
              ? renderSubMenu(item.secondarySubMenuItems)
              : null}
          </li>
        ))}
      </ul>
    )
  }

  return (
    <nav className="cf-hidden sr-only" aria-hidden>
      <ul>
        {navItems.map((item, index) => (
          <li key={`${item.name}-${index}`}>
            {item.link ? <a href={item.link}>{item.name}</a> : <span>{item.name}</span>}
            {item.subMenuItems && item.subMenuItems.length > 0
              ? renderSubMenu(item.subMenuItems)
              : null}
            {item.secondarySubMenuItems && item.secondarySubMenuItems.length > 0
              ? renderSubMenu(item.secondarySubMenuItems)
              : null}
          </li>
        ))}
      </ul>
    </nav>
  )
})
