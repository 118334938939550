import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'

import { useHeaderContext, useOnCurrencyChange } from '../../../contexts/header-config-context'
import { subMenuClasses } from './language-items'
import { getCurrencies } from '../../../utils/currencies'
import { CheckIcon } from '../../icons/check-icon'

export function CurrencyItems() {
  const { activeCurrency } = useHeaderContext()
  const currencies = getCurrencies()
  const onCurrencyChange = useOnCurrencyChange()

  if (!activeCurrency) {
    return null
  }

  return (
    <ul>
      {currencies.map(item => (
        <li key={item.code} className={subMenuClasses} onClick={() => onCurrencyChange(item.code!)}>
          <NavigationMenu.Link className="flex grow items-center gap-2">
            <span className="font-medium">{item.symbol}</span>
            <span>{item.code?.toUpperCase()}</span>
          </NavigationMenu.Link>
          {item.code === activeCurrency.code ? (
            <CheckIcon className="text-cf-dark-aubergine h-4" />
          ) : null}
        </li>
      ))}
    </ul>
  )
}
