import { ApiEndpoint, BackendErrorListData } from 'api/fetcher.types';
import { NonceType } from 'api/requests/nonce/nonce.types';
import { nonceFetcher } from 'api/requests/nonce/nonce';
import { fetcher } from 'api/fetcher';

import { Subscription, UndoSubscriptionCancellationFetcherParams } from './subscriptions.types';

export const undoSubscriptionCancellationFetcher = async (params: UndoSubscriptionCancellationFetcherParams) => {
  const { id, onError } = params;
  const { nonce } = await nonceFetcher({ nonceType: NonceType.UNDO_SUBSCRIPTION_CANCELLATION });
  const body = JSON.stringify({ sub_id: id, security: nonce });

  return fetcher<Subscription & BackendErrorListData>({
    url: ApiEndpoint.UNDO_SUBSCRIPTION_CANCELLATION,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};
