import { styled } from '@mui/material';

import { Snackbar } from 'components/elements/atoms/snackbar/Snackbar';

export const SnackbarRoot = styled(Snackbar)(({ theme }) => ({
  [theme.breakpoints.down('md')]: {
    transform: 'none',
    left: 0,
    right: 0,
  },
  bottom: theme.spacing(1.25),
  '& .MuiPaper-root.MuiAlert-root': {
    backgroundColor: theme.palette.primary.main,
    borderRadius: 0,
    width: '100%',
    maxWidth: '28.7666rem',
    position: 'relative',
    padding: theme.spacing(1.5, 2.5),
  },
  '& .MuiAlert-message p': {
    lineHeight: 1.5,
    color: theme.palette.common.white,
  },
  '& .MuiAlert-action': {
    position: 'absolute',
    right: theme.spacing(0.75),
    top: theme.spacing(0.75),
    marginRight: 0,
    '& .MuiButtonBase-root > svg': {
      display: 'none',
    },
    '& .MuiIconButton-root': {
      padding: 0,
      height: theme.spacing(2),
      width: theme.spacing(2.125),
      '&::after': {
        content: '"x"',
        fontSize: '1.2rem',
        color: theme.palette.common.white,
        fontFamily: theme.typography.fontFamily,
      },
    },
  },
  '& .MuiLink-root': {
    color: theme.palette.common.white,
  },
}));
