import * as NavigationMenu from 'primitives/navigation-menu'
import { languageMap, defaultLanguage } from 'cf-i18n/constants'

import { useLocale, useOnLanguageChange } from '../../../contexts/header-config-context'
import { CheckIcon } from '../../icons/check-icon'

export const subMenuClasses =
  'flex items-center justify-between rounded-full whitespace-nowrap px-[18px] py-[6px] leading-6 hover:bg-cf-lavender cursor-pointer mx-2 first-of-type:mt-1'

export function LanguageItems() {
  const onLanguageChange = useOnLanguageChange()
  const lang = useLocale()

  return (
    <ul>
      <li
        key="systemDefault"
        className={subMenuClasses}
        onClick={() => onLanguageChange(defaultLanguage)}
      >
        <NavigationMenu.Link>{languageMap.get(defaultLanguage)}</NavigationMenu.Link>
        {lang === defaultLanguage ? <CheckIcon className="text-cf-dark-aubergine h-4" /> : null}
      </li>
      <li className="border-cf-dreamy-cloud-600 m-1 border-b" />
      {Array.from(languageMap)
        .filter(([id]) => id !== defaultLanguage)
        .map(([id, label]) => (
          <li key={id} className={subMenuClasses} onClick={() => onLanguageChange(id)}>
            <NavigationMenu.Link>{label}</NavigationMenu.Link>
            {lang === id ? <CheckIcon className="text-cf-dark-aubergine h-4" /> : null}
          </li>
        ))}
    </ul>
  )
}
