import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import { NavItem } from '../../../hooks/use-nav-items'
import { ScrollableContainer } from './scrollable-container'
import { NavigationSubItem } from './submenu-item'
import { Container } from './nested-menu-container'
import { TertiarySubmenu } from './tertiary-submenu'
import {
  useActivePrimaryItem,
  useActiveSecondaryItem,
  useMaxHeightMenuDropdown,
} from '../../../contexts/main-navigation-context'
import { ArrowNarrowUpRight } from '../../icons/arrow-narrow-up-right'
import { useLinkComponent, useTranslations } from '../../../contexts/header-config-context'
import { TrendingSection } from './trending-section'
import { getColorScheme, ColorScheme } from '../../../utils/get-color-scheme'

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  items: NavItem[]
  secondaryItems?: NavItem[]
  colors?: Partial<ColorScheme>
}

export function SecondarySubmenu({ items, secondaryItems, colors }: Props) {
  const colorScheme = getColorScheme(colors)
  const [primary] = useActivePrimaryItem()

  return (
    <>
      <SecondarySubmenuColumn
        parent={{
          name: primary?.primaryName || primary?.name,
          title: primary?.title,
          link: primary?.link,
          wrapItems: primary?.wrapItems,
          trending: !!primary?.trending,
        }}
        elements={items}
        className={tw((secondaryItems?.length ?? 0) > 0 && 'max-w-[30%]')}
        colors={colorScheme}
      />
      <SecondarySubmenuColumn
        parent={{
          name: primary?.secondaryName,
          link: primary?.link,
          wrapItems: primary?.wrapItems,
          trending: !!primary?.trending,
        }}
        elements={secondaryItems}
        colors={colorScheme}
      />
      <TrendingSection className="bg-cf-dreamy-cloud-300 order-3 flex flex-col items-end justify-between px-4 py-3" />
    </>
  )
}

function SecondarySubmenuColumn({
  parent,
  elements,
  className,
  colors,
}: {
  parent: {
    link?: string
    name?: string
    title?: string
    wrapItems?: boolean
    trending?: boolean
  }
  elements?: NavItem[]
  className?: string
  colors?: Partial<ColorScheme>
}) {
  const colorScheme = getColorScheme(colors)
  const [secondary, setSecondaryItem] = useActiveSecondaryItem()
  const Link = useLinkComponent()
  const wrapItems = parent?.wrapItems
  const hasTrending = parent?.trending
  const maxHeight = useMaxHeightMenuDropdown()

  const t = useTranslations()

  if (!elements) {
    return null
  }

  return (
    <Container asChild key="secondary-items">
      <NavigationMenu.Content
        className={tw(
          'bg-cf-dreamy-cloud-300 order-2',
          hasTrending || wrapItems ? 'grow' : 'grow-0',
          className,
        )}
      >
        <ScrollableContainer className={tw(wrapItems && 'h-full')} enabled={!wrapItems}>
          <NavigationMenu.Root
            orientation="vertical"
            onValueChange={value => {
              const foundSecondary = elements.find(el => el.name === value)
              // We need to prevent changing active items to items that don't have a sub items
              if (!foundSecondary?.id) {
                return
              }

              foundSecondary && setSecondaryItem(foundSecondary)
            }}
            value={secondary?.name}
            defaultValue={secondary?.name}
          >
            {parent?.link ? (
              <NavigationMenu.Link
                href={parent?.link}
                className={tw(
                  'text-16 border-cf-dreamy-cloud-700 my-2 ml-6 mr-16 inline-block whitespace-nowrap border-b border-dotted font-semibold',
                )}
                asChild
              >
                <Link>
                  <span>
                    {parent?.title
                      ? parent.title
                      : t('navigation.all').replace(/\[0]/, parent?.name || '')}{' '}
                  </span>
                  <span className="pl-4">
                    <ArrowNarrowUpRight className="inline-block" />
                  </span>
                </Link>
              </NavigationMenu.Link>
            ) : (
              <div
                className={tw(
                  'text-16 border-cf-dreamy-cloud-700 my-2 ml-6 mr-16 inline-block whitespace-nowrap font-semibold',
                )}
              >
                <span>{parent?.title ? parent.title : parent?.name} </span>
              </div>
            )}
            <NavigationMenu.List
              className={tw(
                'relative flex flex-col gap-[1px] pl-2 pr-1 pt-2',
                wrapItems ? 'w-auto flex-wrap pb-4' : '',
              )}
              style={
                wrapItems ? { maxHeight: `calc(${maxHeight}px - 40px - 3 * 0.75rem)` } : undefined
              }
            >
              {elements.map((element, index) => {
                return (
                  <NavigationSubItem
                    key={`${element.id}_${index}`}
                    item={element}
                    value={element.name}
                    isActive={secondary?.id === element.id}
                    className={wrapItems ? 'w-fit' : 'w-full'}
                    level="secondary"
                    colors={colorScheme}
                  >
                    {element.subMenuItems?.length ? (
                      <TertiarySubmenu items={element.subMenuItems} />
                    ) : null}
                  </NavigationSubItem>
                )
              })}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </ScrollableContainer>
      </NavigationMenu.Content>
    </Container>
  )
}
