import React from 'react';
import { Box } from '@mui/material';

import { SiteWidePopupProps } from 'components/elements/molecules/siteWidePopup/SiteWidePopup.types';

import * as S from './ProfileNudge.styles';

export const ProfileNudge = (props: SiteWidePopupProps) => {
  const { title, text, button, isOpened, onClose } = props;

  return (
    <S.SnackbarStyled
      open={isOpened}
      onClose={onClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      hasCloseButton
      hasHideDuration={false}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Box>
        <S.Title variant="body1">{title}</S.Title>
        <S.Text>{text}</S.Text>
        <S.LinkStyled underline="none" href={button?.link} style={{ backgroundColor: button?.backgroundColor }}>
          <S.LinkText variant="h4">{button?.text}</S.LinkText>
        </S.LinkStyled>
      </Box>
    </S.SnackbarStyled>
  );
};
