import { tw } from 'utils/classnames'

import * as MenuItem from '../menu-item'
import { GradientColoredSparkIcon } from '../icons/gradient-colored-spark-icon'
import { UserAvatar } from '../avatar'
import { SearchAutocomplete } from '../search'
import { HamburgetMenu } from './hamburger-menu'
import { useOnLoginClick, useTranslations } from '../../contexts/header-config-context'
import { useIsAuthLoading, useIsAuthenticated } from '../../contexts/auth'
import { User01Icon } from '../icons/user-01-icon'
import { studioLink } from '../../utils/urls'
import { useIsIpad } from '../../hooks/use-is-ipad'

export function MobileHeader() {
  const isAuthenticated = useIsAuthenticated()
  const isAuthLoading = useIsAuthLoading()
  const t = useTranslations()
  const onLoginClick = useOnLoginClick()
  const isIpad = useIsIpad()

  const renderAccountItems = () => {
    if (isAuthLoading) {
      return null
    }

    return isAuthenticated ? (
      <MenuItem.Link
        href="/masterfeed"
        className="h-[54px] flex-col justify-between gap-1 min-[400px]:absolute min-[400px]:left-14"
      >
        <UserAvatar />
        <span className="grow whitespace-nowrap">{t('top_nav.account')}</span>
      </MenuItem.Link>
    ) : (
      <MenuItem.Button
        onClick={onLoginClick}
        className="h-[54px] flex-col justify-between gap-1 min-[400px]:absolute min-[400px]:left-14"
      >
        <User01Icon className="min-h-5" />
        <span className="grow whitespace-nowrap">{t('top_nav.login')}</span>
      </MenuItem.Button>
    )
  }

  return (
    <div
      id="mobile-header"
      className={tw('px-2 pb-2', isIpad ? 'block' : 'min-[1175px]:cf-hidden')}
    >
      <div className="flex w-full items-center justify-between">
        <div className="flex">
          <HamburgetMenu />
          {renderAccountItems()}
        </div>
        <a
          href="/"
          className="max-w-[100px] text-center text-[16px] font-bold leading-4 md:max-w-full"
        >
          Creative Fabrica
        </a>
        <MenuItem.Link
          href={studioLink}
          target="_blank"
          className="ml-4 h-full flex-col justify-between gap-1 px-2 py-1.5"
        >
          <GradientColoredSparkIcon />
          <span className="drop-shadow">{t('top_nav.create')}</span>
        </MenuItem.Link>
      </div>
      <SearchAutocomplete />
    </div>
  )
}
