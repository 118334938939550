import { styled, Box, BoxProps } from '@mui/material';

const scrollToTopButtonProps = new Set<PropertyKey>(['isVisible', 'withFlames', 'hideOnMobile']);

export const ScrollToTopButton = styled(Box, {
  shouldForwardProp: prop => !scrollToTopButtonProps.has(prop),
})<BoxProps & { isVisible: boolean; withFlames: boolean; hideOnMobile?: boolean }>(
  ({ theme, isVisible, withFlames, hideOnMobile }) => ({
    position: 'fixed',
    bottom: theme.spacing(22),
    right: theme.spacing(5.5),
    visibility: 'hidden',
    opacity: 0,
    transition: 'visibility 0s .5s, opacity .5s',
    zIndex: theme.zIndex.appBar,
    '& > svg': {
      fontSize: 30,
      transform: 'rotate(-45deg)',
    },
    ...(isVisible && {
      visibility: 'visible',
      opacity: 1,
      transition: 'opacity .5s',
    }),
    ...(withFlames && {
      '& span': {
        visibility: 'visible',
        opacity: 1,
        borderWidth: '2px',
        borderTopWidth: '10px',
        transition: 'border-width .25s, opacity .25s',
        '&:nth-of-type(4)': {
          borderWidth: '3px',
          borderTopWidth: '25px',
        },
      },
    }),
    ...(hideOnMobile && {
      [theme.breakpoints.down('lg')]: {
        display: 'none',
      },
    }),
  }),
);

export const Flame = styled('span')(({ theme }) => ({
  position: 'absolute',
  top: '90%',
  left: theme.spacing(0.5),
  height: 0,
  width: 0,
  borderStyle: 'solid',
  borderColor: 'transparent',
  borderTopColor: theme.palette.black.main,
  visibility: 'hidden',
  borderWidth: 0,
  opacity: 0,
  transition: 'visibility 0s .25s, border-width .25s, opacity .25s',
  '&:nth-of-type(2)': {
    left: theme.spacing(2.75),
  },
  '&:nth-of-type(3)': {
    top: '85%',
    left: theme.spacing(1.5),
  },
}));
