import * as React from 'react'
import { ClockIcon, TrashIcon } from 'primitives/icons'
import { ArrowUpLeft } from 'primitives/icons/arrows'

const LOCAL_STORAGE_KEY = 'cf-search-history'
const MAX_HISTORY_LENGTH = 5

function getRecentSearches(): string[] {
  const recentSearches = localStorage.getItem(LOCAL_STORAGE_KEY)
  if (!recentSearches) {
    return []
  }

  try {
    return JSON.parse(recentSearches)
  } catch (e) {
    return []
  }
}

function setRecentSearches(newRecentSearches: string[]) {
  localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newRecentSearches))
}

export function saveQueryToRecentSearches(query: string) {
  if (!query) {
    return
  }
  setRecentSearches(
    [query, ...getRecentSearches().filter(q => q !== query)].slice(0, MAX_HISTORY_LENGTH),
  )
}

export function RecentSearches({
  onItemClick,
  onFillQueryClick,
}: {
  onItemClick: (query: string) => void
  onFillQueryClick: (query: string) => void
}) {
  const [recentSearches, setRecentSearches] = React.useState<string[]>(getRecentSearches)

  function removeQueryFromRecentSearches(query: string) {
    const newRecentSearches = recentSearches.filter(q => q !== query)
    setRecentSearches(newRecentSearches)
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newRecentSearches))
  }

  if (recentSearches.length === 0) {
    return null
  }

  return (
    <div className="bg-cf-white shadow-cf-elevation-light-5 rounded-2 z-cf-menu-recents absolute top-[50px] w-full p-2">
      <ul role="listbox">
        {recentSearches.map((query, index) => (
          <li
            role="option"
            key={index}
            className="hover:bg-cf-dreamy-cloud-400 flex w-full cursor-pointer items-center gap-4 rounded pl-2"
            onClick={() => onItemClick(query)}
          >
            <ClockIcon className="text-cf-dreamy-cloud-800 size-5" />

            <span className="flex-1 truncate text-left">{query}</span>

            <div>
              <button
                type="button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                  removeQueryFromRecentSearches(query)
                }}
                className="text-cf-dreamy-cloud-700 hover:text-cf-dreamy-cloud-900 p-2"
              >
                <TrashIcon className="size-5" />
              </button>

              <button
                type="button"
                onClick={e => {
                  e.preventDefault()
                  e.stopPropagation()
                }}
                onMouseDown={() => {
                  onFillQueryClick(query)
                }}
                className="text-cf-dreamy-cloud-700 hover:text-cf-dreamy-cloud-900 p-2"
              >
                <ArrowUpLeft className="size-5" />
              </button>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}
