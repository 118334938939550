import * as React from 'react'
import { tw } from 'utils/classnames'

import { useMaxHeightMenuDropdown } from '../../../contexts/main-navigation-context'

export function ScrollableContainer({
  children,
  className,
  enabled = true,
}: React.PropsWithChildren<{ className?: string; enabled?: boolean }>) {
  const maxHeight = useMaxHeightMenuDropdown()
  const scrollableDivRef = React.useRef<HTMLDivElement>(null)
  const [scrollable, setScrollable] = React.useState({
    top: false,
    bottom: false,
  })
  const checkScroll = React.useCallback(() => {
    if (scrollableDivRef.current && maxHeight) {
      const hasTopScroll = scrollableDivRef.current.scrollTop > 0
      const hasBottomScroll =
        Math.ceil(scrollableDivRef.current.scrollTop) + scrollableDivRef.current.clientHeight <
        scrollableDivRef.current.scrollHeight
      setScrollable({
        top: hasTopScroll,
        bottom: hasBottomScroll,
      })
    }
  }, [setScrollable, maxHeight])

  React.useEffect(() => {
    checkScroll()
    window.addEventListener('resize', checkScroll)
    return () => {
      window.removeEventListener('resize', checkScroll)
    }
  }, [checkScroll])

  return (
    <div
      className={tw(
        'relative w-full',
        scrollable.top &&
          'before:rounded-t-2 before:to-cf-dreamy-cloud-100 before:pointer-events-none before:absolute before:left-0 before:top-0 before:z-10 before:h-14 before:w-full before:bg-gradient-to-t before:from-transparent',
        scrollable.bottom &&
          'after:rounded-b-2 after:to-cf-dreamy-cloud-100 after:pointer-events-none after:absolute after:bottom-0 after:left-0 after:z-10 after:h-14 after:w-full after:bg-gradient-to-b after:from-transparent',
        className,
      )}
    >
      <div
        ref={scrollableDivRef}
        onScroll={checkScroll}
        className={tw('scroller-menu-y h-full overflow-auto overflow-y-scroll px-0 pt-3')}
        style={enabled ? { maxHeight: `${maxHeight}px` } : undefined}
      >
        {children}
      </div>
    </div>
  )
}
