import * as React from 'react'
import { StudioButton } from 'components/studio-button'

import { useRouterUtil, useTranslations } from '../../contexts/header-config-context'
import { SearchMdIcon } from '../icons/search-md-icon'

interface SearchInputProps {
  autofocus?: boolean
  value: string
  onChange: (newValue: string) => void
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement>
  onFocus?: React.FocusEventHandler<HTMLInputElement>
  onHover?: React.MouseEventHandler<HTMLInputElement>
  onClick?: VoidFunction
}

export const SearchInput = React.forwardRef(function SearchInput(
  { autofocus, value, onChange, onHover, onKeyPress, onFocus, onBlur }: SearchInputProps,
  ref,
) {
  const inputRef = React.useRef<HTMLInputElement>(null)
  const pathname = useRouterUtil().pathname
  const t = useTranslations()

  React.useImperativeHandle(ref, () => inputRef.current, [])

  React.useEffect(() => {
    if (autofocus && inputRef.current) {
      inputRef.current.focus()
    }
  }, [autofocus])

  //remove focus from input when route changes
  React.useEffect(() => {
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }, [pathname])

  return (
    <label className="rounded-2 flex-center border-cf-dreamy-cloud-600 focus-within:border-cf-violet-hover bg-cf-dreamy-cloud-200 group flex w-full border pl-[20px] font-normal transition-colors">
      <input
        ref={inputRef}
        type="text"
        autoComplete="off"
        spellCheck="false"
        dir="auto"
        value={value}
        onChange={e => onChange(e.target.value)}
        onMouseOver={onHover}
        placeholder={t('search.placeholder')}
        onKeyDown={onKeyPress}
        onFocus={onFocus}
        onBlur={onBlur}
        className="placeholder-cf-dreamy-cloud-900 text-14 my-auto ml-2 h-4 w-full border-none bg-transparent outline-none transition-opacity placeholder:opacity-100 group-focus-within:placeholder:opacity-0"
      />
      <StudioButton
        type="submit"
        variant="invisible"
        aria-label="Go to search page"
        className="rounded-r-2 group-focus-within:text-cf-dreamy-cloud-900 group-focus-within:bg-cf-violet -mr-[1px] rounded-l-none transition-colors"
      >
        <SearchMdIcon className="text-cf-dreamy-cloud-900 group-focus-within:text-cf-white h-4 w-4 transition-colors" />
      </StudioButton>
    </label>
  )
})
