import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { languageMap, defaultLanguage } from 'cf-i18n/constants'

import {
  useHeaderContext,
  useOnLanguageChange,
  useOnLanguageOpen,
  useTranslations,
} from '../../contexts/header-config-context'
import { CheckIcon } from '../icons/check-icon'

export function LanguageSwitcher({ setIsMenuOpen }: { setIsMenuOpen: (value: boolean) => void }) {
  const { lang } = useHeaderContext()
  const t = useTranslations()
  const onLanguageOpen = useOnLanguageOpen()
  const onLanguageChange = useOnLanguageChange()

  React.useEffect(() => {
    onLanguageOpen?.()
  }, [onLanguageOpen])

  return (
    <>
      <li className="p-4 text-[18px] font-bold">{t('language_currency.language')}</li>
      <NavigationMenu.Item
        className="border-cf-dreamy-cloud-600 mb-1 flex w-full cursor-pointer items-center justify-between gap-4 border-b p-4 pt-2 text-left"
        onClick={() => {
          onLanguageChange(defaultLanguage)
          setIsMenuOpen(false)
        }}
        asChild
      >
        <button>
          <span className="text-16 grow leading-6">{languageMap.get(defaultLanguage)}</span>
          {lang === defaultLanguage ? <CheckIcon className="text-cf-dark-aubergine h-4" /> : null}
        </button>
      </NavigationMenu.Item>
      <li className="text-cf-dreamy-cloud-900 p-4 text-xs">
        {t('language_currency.all_languages')}
      </li>
      {Array.from(languageMap)
        .filter(([id]) => id !== defaultLanguage)
        .map(([id, label]) => (
          <NavigationMenu.Item
            key={id}
            className="flex w-full cursor-pointer items-center justify-between gap-4 p-4 py-2 text-left"
            onClick={() => {
              onLanguageChange(id)
              setIsMenuOpen(false)
            }}
            asChild
          >
            <button>
              <span className="text-16 grow leading-6">{label}</span>
              {lang === id ? <CheckIcon className="text-cf-dark-aubergine h-4" /> : null}
            </button>
          </NavigationMenu.Item>
        ))}
    </>
  )
}
