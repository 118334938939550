import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import { NavItem } from '../../../hooks/use-nav-items'
import { ScrollableContainer } from './scrollable-container'
import { SecondarySubmenu } from './secondary-submenu'
import { NavigationSubItem } from './submenu-item'
import { Container } from './nested-menu-container'
import {
  useActivePrimaryItem,
  useActiveSecondaryItem,
} from '../../../contexts/main-navigation-context'
import { getColorScheme, ColorScheme } from '../../../utils/get-color-scheme'

export function PrimarySubmenu({
  items,
  colors,
}: {
  items?: NavItem[]
  colors?: Partial<ColorScheme>
}) {
  const colorScheme = getColorScheme(colors)
  const [primaryItem, setPrimaryItem] = useActivePrimaryItem()
  const [, setSecondaryItem] = useActiveSecondaryItem()

  if (!items?.length) {
    return null
  }

  return (
    <Container asChild key="primary-items">
      <NavigationMenu.Content
        className={tw(
          'bg-cf-white rounded-l-2 border-cf-dreamy-cloud-600 order-1 border-r',
          'rounded-l-2 grow-0 pl-2 pr-1',
          primaryItem?.name ? 'rounded-r-0' : 'rounded-r-2',
        )}
      >
        <ScrollableContainer className="h-full">
          <NavigationMenu.Root
            orientation="vertical"
            onValueChange={value => {
              const foundPrimary = items.find(item => item.name === value)
              // We need to prevent changing focus to items that don't have a secondary submenu
              if (
                foundPrimary?.id &&
                ['browse_all_needle_arts', 'start_designing'].includes(foundPrimary.id)
              ) {
                return
              }

              setPrimaryItem(oldPrimary => foundPrimary ?? oldPrimary)
              const firstSecondary = foundPrimary?.subMenuItems?.[0]
              setSecondaryItem(oldSecondary => firstSecondary ?? oldSecondary)
            }}
            value={primaryItem?.name}
            className="grid h-full w-full"
          >
            <NavigationMenu.List className="relative flex h-full flex-col gap-[1px]">
              {items.map((item, index) => {
                return (
                  <NavigationSubItem
                    key={`${item.name}_${index}`}
                    item={item}
                    isActive={primaryItem?.id === item.id}
                    onChange={() => {
                      setPrimaryItem(item)
                    }}
                    level="primary"
                    colors={colorScheme}
                  >
                    {item.subMenuItems?.length ? (
                      <SecondarySubmenu
                        items={item.subMenuItems}
                        secondaryItems={item.secondarySubMenuItems}
                        colors={colorScheme}
                      />
                    ) : null}
                  </NavigationSubItem>
                )
              })}
            </NavigationMenu.List>
          </NavigationMenu.Root>
        </ScrollableContainer>
      </NavigationMenu.Content>
    </Container>
  )
}
