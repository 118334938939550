import { useTranslations } from '../contexts/header-config-context'
import { NavItemType } from './use-menu-items'

type NavItem = {
  id?: NavItemType
  name: string
  link: string
  subMenuItems?: NavItem[]
}

export const useSecondaryNavItems = () => {
  const t = useTranslations()

  const navItems: NavItem[] = [
    {
      id: 'freebies',
      name: t('navigation.freebies.freebies'),
      link: '/freebies/',
      subMenuItems: [
        {
          name: t('navigation.freebies.all'),
          link: '/freebies/',
        },
        {
          name: t('navigation.freebies.fonts'),
          link: '/freebies/free-fonts/',
        },
        {
          name: t('navigation.freebies.svgs'),
          link: '/freebies/free-crafts/',
        },
        {
          name: t('navigation.freebies.graphics'),
          link: '/freebies/free-graphics/',
        },
        {
          name: t('navigation.freebies.embroidery'),
          link: '/freebies/free-embroidery-designs/',
        },
        {
          name: t('navigation.freebies.classes'),
          link: '/freebies/free-classes/',
        },
      ],
    },
    {
      id: 'gifts',
      name: t('navigation.gifts.gifts'),
      link: '/daily-gifts/',
      subMenuItems: [
        {
          name: t('navigation.gifts.discount'),
          link: '/discount-deals/',
        },
        {
          name: t('navigation.gifts.daily'),
          link: '/daily-gifts/',
        },
      ],
    },
  ]

  return navItems
}
