import { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { useSiteDataFetcher } from 'api/requests/site/site';
import { PopupType } from 'api/requests/site/site.types';
import { updateGdprFetcher } from 'api/requests/gdpr/gdpr';
import { NewsletterPopup } from 'components/elements/molecules/siteWidePopup/newsletterPopup/NewsletterPopup';
import { ProfileNudge } from 'components/elements/molecules/siteWidePopup/profileNudge/ProfileNudge';
import { useAuthState } from 'hooks/useAuthState/useAuthState';
import { useRequestErrorHandler } from 'hooks/useRequestErrorHandler/useRequestErrorHandler';

import { SiteWidePopupProps } from './SiteWidePopup.types';

const SiteWidePopupComponent = dynamic<SiteWidePopupProps>(() =>
  import('./SiteWidePopup').then(module => module.SiteWidePopup),
);

export const SiteWidePopup = () => {
  const { data } = useSiteDataFetcher();
  const { popup } = data || {};
  const { user } = useAuthState();
  const { asPath } = useRouter();
  const [shouldMount, setShouldMount] = useState(false);
  const [isOpened, setOpened] = useState(Boolean(popup));
  const onError = useRequestErrorHandler({ hasSnackbar: false });

  useEffect(() => {
    if (isOpened) {
      setShouldMount(true);
    }
  }, [isOpened]);

  useEffect(() => {
    if (popup?.type === PopupType.PROFILE_NUDGE) {
      setOpened(false);

      const timer = setTimeout(() => {
        setOpened(true);
      }, 5000);

      return () => clearTimeout(timer);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!popup || !shouldMount) {
    return null;
  }

  if (popup.type === PopupType.NEWSLETTER) {
    const handleClick = () => {
      if (!user) {
        return;
      }

      updateGdprFetcher({
        userId: user?.identification.id,
        consent: 'news',
        page: asPath,
        onError,
      }).then(() => {
        setOpened(false);
      });
    };

    const handleClose = () => {
      setOpened(false);

      if (!user) {
        return;
      }

      updateGdprFetcher({
        userId: user?.identification?.id,
        consent: 'none',
        page: asPath,
        onError,
      });
    };

    return <NewsletterPopup {...popup} isOpened={isOpened} buttonOnClick={handleClick} onClose={handleClose} />;
  }

  if (popup.type === PopupType.PROFILE_NUDGE) {
    return <ProfileNudge {...popup} isOpened={isOpened} onClose={() => setOpened(false)} />;
  }

  if (popup.type === PopupType.SITEWIDE) {
    return <SiteWidePopupComponent {...popup} isOpened={isOpened} onClose={() => setOpened(false)} />;
  }

  return null;
};
