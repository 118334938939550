export function CheckIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M16.7064 5.29303C16.8939 5.48056 16.9992 5.73487 16.9992 6.00003C16.9992 6.26519 16.8939 6.5195 16.7064 6.70703L8.70643 14.707C8.5189 14.8945 8.2646 14.9998 7.99943 14.9998C7.73427 14.9998 7.47996 14.8945 7.29243 14.707L3.29243 10.707C3.11027 10.5184 3.00948 10.2658 3.01176 10.0036C3.01403 9.74143 3.1192 9.49062 3.30461 9.30521C3.49002 9.1198 3.74083 9.01464 4.00303 9.01236C4.26523 9.01008 4.51783 9.11087 4.70643 9.29303L7.99943 12.586L15.2924 5.29303C15.48 5.10556 15.7343 5.00024 15.9994 5.00024C16.2646 5.00024 16.5189 5.10556 16.7064 5.29303Z"
        fill="currentColor"
      />
    </svg>
  )
}
