export function StorefrontIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7549 7.12045L18.7827 3.07416C18.579 2.24082 17.8568 1.66675 17.0142 1.66675H4.15309C3.31976 1.66675 2.58828 2.25008 2.39383 3.07416L1.42161 7.12045C1.19939 8.0649 1.40309 9.02786 1.99568 9.78712C2.06976 9.88897 2.17161 9.96305 2.25494 10.0556V16.4816C2.25494 17.5001 3.08828 18.3334 4.1068 18.3334H17.0698C18.0883 18.3334 18.9216 17.5001 18.9216 16.4816V10.0556C19.0049 9.9723 19.1068 9.88897 19.1809 9.79638C19.7735 9.03712 19.9864 8.0649 19.7549 7.12045ZM16.9864 3.50934L17.9586 7.55564C18.0512 7.94453 17.9679 8.33341 17.7272 8.63897C17.5975 8.80564 17.3198 9.07416 16.8568 9.07416C16.292 9.07416 15.8012 8.62045 15.7364 8.0186L15.1994 3.5186L16.9864 3.50934ZM11.5142 3.5186H13.329L13.829 7.70379C13.8753 8.0649 13.7642 8.42601 13.5235 8.69453C13.3198 8.93527 13.0235 9.07416 12.6438 9.07416C12.0235 9.07416 11.5142 8.52786 11.5142 7.86119V3.5186ZM7.33828 7.70379L7.84754 3.5186H9.66235V7.86119C9.66235 8.52786 9.15309 9.07416 8.46791 9.07416C8.15309 9.07416 7.86605 8.93527 7.64383 8.69453C7.41235 8.42601 7.30124 8.0649 7.33828 7.70379ZM3.21791 7.55564L4.15309 3.5186H5.97717L5.44013 8.0186C5.36605 8.62045 4.88457 9.07416 4.31976 9.07416C3.86605 9.07416 3.57902 8.80564 3.45865 8.63897C3.20865 8.34267 3.12531 7.94453 3.21791 7.55564ZM4.1068 16.4816V10.8982C4.18087 10.9075 4.24568 10.926 4.31976 10.926C5.12531 10.926 5.85679 10.5927 6.39383 10.0464C6.94939 10.6019 7.69013 10.926 8.53272 10.926C9.33828 10.926 10.0605 10.5927 10.5975 10.0649C11.1438 10.5927 11.8846 10.926 12.7179 10.926C13.4957 10.926 14.2364 10.6019 14.792 10.0464C15.329 10.5927 16.0605 10.926 16.8661 10.926C16.9401 10.926 17.0049 10.9075 17.079 10.8982V16.4816H4.1068Z"
        fill="currentColor"
      />
    </svg>
  )
}
