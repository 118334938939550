import { styled, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Alert } from 'components/elements/atoms/alert/Alert';
import { IconButton } from 'components/elements/atoms/iconButton/IconButton';

export const ModalAlert = styled(Alert)(({ theme }) => ({
  '&.MuiAlert-filled': {
    minWidth: '100%',
    marginTop: theme.spacing(-3),
    padding: theme.spacing(0, 2),
    borderRadius: theme.spacing(1, 1, 0, 0),
    textAlign: 'center',
    justifyContent: 'center',
    '& .MuiAlert-message a': {
      color: theme.palette.common.white,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
}));

export const ModalClose = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(-2.25),
  right: theme.spacing(-2.25),
  width: theme.spacing(4.5),
  height: theme.spacing(4.5),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.primary.dark,
  },
}));

export const Modal = styled(Dialog)<{ BackdropProps?: { 'data-testid'?: string } }>(({ theme }) => ({
  '& .MuiPaper-root': {
    overflow: 'visible',
    maxWidth: theme.spacing(50),
    borderRadius: 8,
    width: '100%',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    '& .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody': {
      maxWidth: `calc(100% - ${theme.spacing(8)})`,
    },
  },
}));

export const Title = styled(DialogTitle)(({ theme }) => ({
  root: {
    padding: theme.spacing(0, 0, 1),
  },
}));

export const Content = styled(DialogContent)(({ theme }) => ({
  root: {
    padding: theme.spacing(1, 3.5),
  },
}));

export const Actions = styled(DialogActions)(({ theme }) => ({
  padding: theme.spacing(2, 3, 1),
}));
