import * as React from 'react'
import { tw } from 'utils/classnames'

import { useImageComponent } from '../contexts/header-config-context'

interface Props extends React.ImgHTMLAttributes<HTMLImageElement> {
  className?: string
  imageClassName?: string
  enableLazyImage?: boolean
  enableAnimation?: boolean
}

export function Image(props: Props) {
  const {
    imageClassName,
    enableLazyImage = false,
    enableAnimation = true,
    className,
    ...rest
  } = props
  const [isLoaded, setIsLoaded] = React.useState(false)
  const Image = useImageComponent()
  const opactityClasses = isLoaded || !enableAnimation ? 'opacity-100' : 'opacity-0'

  return (
    <div
      className={tw(
        'duration-750 bg-cf-dreamy-cloud-600 relative flex h-8 w-14 items-center justify-center transition-opacity',
        className,
      )}
    >
      <Image
        className={tw(imageClassName, opactityClasses)}
        onLoad={() => setIsLoaded(true)}
        loading={enableLazyImage ? 'lazy' : 'eager'}
        {...rest}
      />
    </div>
  )
}
