export function GraduationHat01Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4 8.00006V14.0112C4 14.3702 4 14.5496 4.05465 14.7081C4.10299 14.8482 4.18187 14.9759 4.28558 15.0817C4.40287 15.2015 4.5634 15.2818 4.88446 15.4423L10.2845 18.1423C10.5468 18.2735 10.678 18.3391 10.8156 18.3649C10.9375 18.3877 11.0625 18.3877 11.1844 18.3649C11.322 18.3391 11.4532 18.2735 11.7155 18.1423L17.1155 15.4423C17.4366 15.2818 17.5971 15.2015 17.7144 15.0817C17.8181 14.9759 17.897 14.8482 17.9453 14.7081C18 14.5496 18 14.3702 18 14.0112V8.00006M1 6.50006L10.6422 1.67895C10.7734 1.61336 10.839 1.58056 10.9078 1.56766C10.9687 1.55622 11.0313 1.55622 11.0922 1.56766C11.161 1.58056 11.2266 1.61336 11.3578 1.67895L21 6.50006L11.3578 11.3212C11.2266 11.3868 11.161 11.4196 11.0922 11.4325C11.0313 11.4439 10.9687 11.4439 10.9078 11.4325C10.839 11.4196 10.7734 11.3868 10.6422 11.3212L1 6.50006Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
