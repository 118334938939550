import { tw } from 'utils/classnames'
import { Currency, Language } from 'codegen/types'
import { languageMap } from 'cf-i18n/constants'

import { UserAvatar } from '../components/avatar'
import { NavItem, useNavItems } from './use-nav-items'
import {
  useHeaderContext,
  useOnLogoutClick,
  useTranslations,
} from '../contexts/header-config-context'
import { useAuthContext } from '../contexts/auth'
import { HeartRoundedIcon } from '../components/icons/heart-rounded-icon'
import { Bell03Icon } from '../components/icons/bell-03-icon'
import { User01Icon } from '../components/icons/user-01-icon'
import { ShoppingCart03Icon } from '../components/icons/shopping-cart-03-icon'
import { Globe01Icon } from '../components/icons/globe-01-icon'
import { Speedometer03Icon } from '../components/icons/speedometer-03-icon'
import { FingerPrint01Icon } from '../components/icons/finger-print-01-icon'
import { CreditCardEditIcon } from '../components/icons/credit-card-edit-icon'
import { FaceHappyIcon } from '../components/icons/face-happy-icon'
import { Flag05Icon } from '../components/icons/flag-05-icon'
import { GraduationHat01Icon } from '../components/icons/graduation-hat-01-icon'
import { StorefrontIcon } from '../components/icons/storefront-icon'
import { Edit05Icon } from '../components/icons/edit-05-icon'
import { Star01Icon } from '../components/icons/star-01-icon'
import { CodeSquare02Icon } from '../components/icons/code-square-02-icon'
import { AtSignIcon } from '../components/icons/at-sign-icon'
import { ReceiptIcon } from '../components/icons/receipt-icon'
import { Wallet03Icon } from '../components/icons/wallet-03-icon'
import { NotificationTextIcon } from '../components/icons/notification-text-icon'
import { CreditCard02Icon } from '../components/icons/credit-card-02-icon'
import { LogOut01Icon } from '../components/icons/log-out-01-icon'
import { CodeBrowserIcon } from '../components/icons/code-browser-icon'
import { Link05Icon } from '../components/icons/link-05-icon'
import { Brush02Icon } from '../components/icons/brush-02-icon'
import { Settings01Icon } from '../components/icons/settings-01-icon'
import { LineChartUp04Icon } from '../components/icons/line-chart-up-04-icon'
import { Lightning01Icon } from '../components/icons/lightning-01-icon'
import { PresentationChartIcon } from '../components/icons/presentation-chart-icon'
import { BarLineChartIcon } from '../components/icons/bar-line-chart-icon'
import { SparkInpaintingIcon } from '../components/icons/spark-inpainting-icon'
import { EyeIcon } from '../components/icons/eye-icon'
import { useSecondaryNavItems } from './use-secondary-nav-items'

export type TopMenuItemType =
  | 'root'
  | 'account'
  | 'my-store'
  | 'language-switcher'
  | 'active-language'
  | 'active-currency'

export type NavItemType =
  | 'fonts'
  | 'fonts_sub'
  | 'images'
  | 'mockups'
  | 'kawaii'
  | 'cartoons'
  | 'backgrounds'
  | 'patterns'
  | 'frames'
  | 'quotes'
  | 'borders_and_corners'
  | 'sketches'
  | 'coloring_pages'
  | 'text_tools'
  | 'holidays_events'
  | 'popular_styles'
  | 'png'
  | 'graphics'
  | '3d'
  | '3d_crafts_paper_cut'
  | '3d_crafts_laser_cut'
  | 'spark'
  | 'crafts'
  | 'needlework'
  | 'photos'
  | 'embroidery'
  | 'knitting_patterns'
  | 'browse_all_needle_arts'
  | 'more_patterns'
  | 'animals'
  | 'around_the_world'
  | 'awareness_inspiration'
  | 'babies_kids'
  | 'family_friends'
  | 'fashion_beauty'
  | 'floral_garden'
  | 'food_drink'
  | 'holidays_celebrations'
  | 'house_home'
  | 'inspirational_quotes'
  | 'nature'
  | 'wedding'
  | 'work_religion_school'
  | 'more_categories'
  | 'classes'
  | 'learn'
  | 'blog'
  | 'video-classes'
  | 'craft-ideas'
  | 'tools'
  | 'pod'
  | 'bundles'
  | 'studio'
  | 'create'
  | 'templates'
  | 'ai_tools'
  | 'other_tools'
  | 'features'
  | 'start_designing'
  | 'subscription'
  | 'upgrade'
  | 'freebies'
  | 'gifts'
  | 'community'
  | 'ask-cf'
  | 'cricut-machines'
  | 'cricut-cutting-machines'
  | 'cricut-heat-presses'
  | 'cricut-home'
  | 'cricut-features'
  | 'cricut-assets'
  | 'cricut-online-classes'
  | 'cricut-inspiration'

export type MenuItemType = TopMenuItemType | NavItemType

export interface MenuItem {
  id: string
  icon?: JSX.Element
  label: string
  link?: string
  onClick?: () => void
  subMenuId?: MenuItemType
}

export interface MenuGroup {
  groupName: string
  link?: string
  items: MenuItem[]
}

interface Menu {
  [id: string]: MenuGroup[]
}

export const useMenuItems = (activeMenu: MenuItemType) => {
  const { lang, activeCurrency } = useHeaderContext()
  const onLogoutClick = useOnLogoutClick()
  const authContext = useAuthContext()
  const userData = authContext?.userData
  const isLoggedIn = !!authContext?.userData
  const hasStore = !!authContext?.storeSlug
  const storeSlug = authContext?.storeSlug ?? ''
  const t = useTranslations()
  const navItems = useNavItemsForMobile()
  const iconSize = 'h-5 w-5'

  const menus: Menu = {
    root: [
      {
        groupName: '',
        items: [
          ...(isLoggedIn && userData
            ? [
                {
                  id: 'account',
                  icon: <UserAvatar />,
                  label: t('account.my_account'),
                  subMenuId: 'account' as const,
                },
                {
                  id: 'favorites',
                  icon: <HeartRoundedIcon className={iconSize} />,
                  label: t('top_nav.favorites'),
                  link: '/my-favorites/',
                },
                {
                  id: 'notifications',
                  icon: <Bell03Icon className={iconSize} />,
                  label: t('top_nav.notifications'),
                  link: '/feed/notifications/',
                },
              ]
            : [
                {
                  id: 'login',
                  icon: <User01Icon className={iconSize} />,
                  label: t('top_nav.login'),
                  link: '/login/',
                },
              ]),
          {
            id: 'cart',
            icon: (
              <div className="relative">
                <ShoppingCart03Icon className={iconSize} />
                {/* <div className="absolute -right-1 -top-1 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-[#E21743] text-[8px] text-[#FFF]">
                    {cartItems}
                  </div> */}
              </div>
            ),
            label: t('top_nav.cart'),
            link: '/cart/',
          },
          {
            id: 'create',
            icon: <SparkInpaintingIcon className={iconSize} />,
            label: t('top_nav.create'),
            link: '/studio/',
          },
          {
            id: 'activeLanguage',
            icon: <Globe01Icon className={iconSize} />,
            label: getLanguageCurrencyLabel(lang, activeCurrency),
            subMenuId: 'language-switcher',
          },
        ],
      },
      {
        groupName: '',
        items: [...navItems.root],
      },
    ],
    account: [
      {
        groupName: t('account.my_account'),
        items: [
          {
            id: 'dashboard',
            icon: <Speedometer03Icon className={iconSize} />,
            label: t('account.my_dashboard'),
            link: '/my-account/',
          },
          {
            id: 'email-password',
            icon: <FingerPrint01Icon className={iconSize} />,
            label: t('account.edit_email_password'),
            link: '/my-account/edit-account/',
          },
          {
            id: 'billing',
            icon: <CreditCardEditIcon className={iconSize} />,
            label: t('account.billing_details'),
            link: '/my-account/billing-details/',
          },
          {
            id: 'favorites',
            icon: <HeartRoundedIcon className={iconSize} />,
            label: t('top_nav.favorites'),
            link: '/my-favorites/',
          },
          {
            id: 'referrals',
            icon: <FaceHappyIcon className={iconSize} />,
            label: t('account.refer'),
            link: '/referrals/',
          },
          {
            id: 'following',
            icon: <Flag05Icon className={iconSize} />,
            label: t('account.followed_designers'),
            link: '/following/',
          },
          {
            id: 'my-classes',
            icon: <GraduationHat01Icon className={iconSize} />,
            label: t('account.my_classes'),
            link: '/my-account/my-classes/',
          },
          ...(hasStore
            ? [
                {
                  id: 'my-store',
                  icon: <StorefrontIcon className={iconSize} />,
                  label: t('top_nav.store'),
                  subMenuId: 'my-store' as const,
                },
              ]
            : [
                {
                  id: 'open-store',
                  icon: <StorefrontIcon className={iconSize} />,
                  label: t('account.open_store'),
                  link: '/open-store/',
                },
              ]),
          {
            id: 'edit-profile',
            icon: <Edit05Icon className={iconSize} />,
            label: t('account.edit_profile'),
            link: '/my-account/my-profile/',
          },
          {
            id: 'social-login',
            icon: <Star01Icon className={iconSize} />,
            label: t('account.social_accounts'),
            link: '/my-account/social-accounts/',
          },
          {
            id: 'download-licenses',
            icon: <CodeSquare02Icon className={iconSize} />,
            label: t('account.download_licenses'),
            link: '/my-account/license-keys-overview/',
          },
          {
            id: 'subscriptions',
            icon: <AtSignIcon className={iconSize} />,
            label: t('account.subscriptions'),
            link: '/my-account/subscriptions/',
          },
          {
            id: 'order-statement',
            icon: <ReceiptIcon className={iconSize} />,
            label: t('account.order_statements'),
            link: '/my-account/orders/',
          },
          {
            id: 'store-credit',
            icon: <Wallet03Icon className={iconSize} />,
            label: t('account.store_credit'),
            link: '/store-credit/',
          },
          {
            id: 'notification-settings',
            icon: <NotificationTextIcon className={iconSize} />,
            label: t('account.notification_settings'),
            link: '/my-account/email-settings/',
          },
          {
            id: 'payment-methods',
            icon: <CreditCard02Icon className={iconSize} />,
            label: t('account.payment_methods'),
            link: '/my-account/payment-methods/',
          },
        ],
      },
      {
        groupName: '',
        items: [
          {
            id: 'signout',
            icon: <LogOut01Icon className={iconSize} />,
            label: t('account.sign_out'),
            onClick: onLogoutClick,
            link: '/logout/',
          },
        ],
      },
      {
        groupName: t('account.affiliates'),
        items: [
          {
            id: 'affiliate-dashboard',
            icon: <Speedometer03Icon className={iconSize} />,
            label: t('account.affiliate_dashboard'),
            link: '/affiliate-area/?tab=urls',
          },
          {
            id: 'referral-url-gen',
            icon: <CodeBrowserIcon className={iconSize} />,
            label: t('account.referal_url_gen'),
            link: '/affiliate-area/referral-url-generator/',
          },
          {
            id: 'statistics',
            icon: <BarLineChartIcon className={iconSize} />,
            label: t('account.statistics'),
            link: '/affiliate-area/?tab=stats',
          },
          {
            id: 'referrals',
            icon: <Link05Icon className={iconSize} />,
            label: t('account.referrals'),
            link: '/affiliate-area/?tab=referrals',
          },
          {
            id: 'visits',
            icon: <EyeIcon className={iconSize} />,
            label: t('account.visits'),
            link: '/affiliate-area/?tab=visits',
          },
          {
            id: 'banners-and-creatives',
            icon: <Brush02Icon className={iconSize} />,
            label: t('account.banners_creatives'),
            link: '/affiliate-area/?tab=creatives',
          },
          {
            id: 'affiliate-settings',
            icon: <Settings01Icon className={iconSize} />,
            label: t('account.settings'),
            link: '/affiliate-area/?tab=settings',
          },
        ],
      },
    ],
    'my-store': [
      {
        groupName: t('top_nav.store'),
        items: [
          {
            id: 'my-store',
            icon: <StorefrontIcon className={iconSize} />,
            label: t('top_nav.store'),
            link: `/designer/${storeSlug}/`,
          },
          {
            id: 'store-dashboard',
            icon: <LineChartUp04Icon className={iconSize} />,
            label: t('store.store_dashboard'),
            link: '/dashboard/',
          },
          {
            id: 'store-boost',
            icon: <Lightning01Icon className={iconSize} />,
            label: t('store.boost_store'),
            link: '/dashboard/manage/boost/',
          },
          {
            id: 'search-analytics',
            icon: <PresentationChartIcon className={iconSize} />,
            label: t('store.search_analytics'),
            link: '/dashboard/manage/search-analytics/',
          },
          {
            id: 'store-settings',
            icon: <Settings01Icon className={iconSize} />,
            label: t('store.store_settings'),
            link: '/dashboard/personal-data/',
          },
        ],
      },
    ],
    'language-switcher': [
      {
        groupName: t('language_currency.language_currency'),
        items: [
          {
            id: 'active-language',
            icon: <Globe01Icon className={iconSize} />,
            label: lang.toUpperCase(),
            subMenuId: 'active-language' as const,
          },
          ...(activeCurrency?.code
            ? [
                {
                  id: 'active-currency',
                  icon: (
                    <div
                      className={tw(
                        iconSize,
                        'flex items-center justify-center text-lg font-medium',
                      )}
                    >
                      {activeCurrency.symbol}
                    </div>
                  ),
                  label: activeCurrency.code.toUpperCase(),
                  subMenuId: 'active-currency' as const,
                },
              ]
            : []),
        ],
      },
    ],
    ...navItems.subMenus,
  }

  return menus[activeMenu] ?? []
}

export const useNavItemsForMobile = () => {
  const navItems = useNavItems()
  const secondaryNavItems = useSecondaryNavItems()
  const allItems = [...navItems, ...secondaryNavItems]

  return allItems.reduce(
    (acc, item, index) => {
      acc.root.push({
        id: item.name + index,
        label: item.name,
        link: item.link,
        ...(item.subMenuItems ? { subMenuId: item.id } : {}),
      })

      function getSubMenuItems(item: NavItem, index: number) {
        if (item.subMenuItems?.length && item.id) {
          const submenu: MenuGroup = {
            groupName: item.name,
            link: item.link,
            items: item.subMenuItems.map(subItem => {
              getSubMenuItems(subItem, index)

              return {
                id: subItem.name + index,
                subMenuId: subItem.subMenuItems?.length ? subItem.id : undefined,
                label: subItem.name,
                link: subItem.link,
              }
            }),
          }
          const secondarySubmenu: MenuGroup | undefined = item.secondarySubMenuItems?.length
            ? {
                groupName: item.secondaryName,
                items: item.secondarySubMenuItems?.map(subItem => {
                  getSubMenuItems(subItem, index)

                  return {
                    id: subItem.name + index,
                    subMenuId: subItem.subMenuItems?.length ? subItem.id : undefined,
                    label: subItem.name,
                    link: subItem.link,
                  }
                }),
              }
            : undefined
          acc.subMenus[item.id] = secondarySubmenu ? [submenu, secondarySubmenu] : [submenu]
        }
      }

      getSubMenuItems(item, index)

      return acc
    },
    {
      root: [] as MenuItem[],
      subMenus: {} as Menu,
    },
  )
}

const getLanguageCurrencyLabel = (lang: Language, activeCurrency?: Currency) => {
  return activeCurrency
    ? `${getLanguageLabel(lang)} | ${activeCurrency.code?.toUpperCase()}`
    : getLanguageLabel(lang)
}

const getLanguageLabel = (lang: Language) => {
  return languageMap.get(lang) || lang
}
