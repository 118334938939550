import * as React from 'react'
import { tw } from 'utils/classnames'

import { useLinkComponent } from '../contexts/header-config-context'

interface MenuButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'default' | 'outlined' | 'colored'
}

const variants = {
  base: 'flex items-center rounded-[8px] py-2 px-3 hover:bg-cf-lavender focus:bg-cf-lavender text-12',
  default: 'border border-transparent',
  outlined: 'border border-cf-dark-aubergine rounded-full py-3 hover:shadow-cf-elevation-light-1',
  colored:
    'bg-cf-gradient-spark drop-shadow text-cf-white hover:text-cf-white focus:text-cf-white hover:shadow-cf-elevation-light-1 p-2 gap-1 pr-3 flex-row',
}

export const Button = React.forwardRef(
  (
    { children, variant, className, ...rest }: MenuButtonProps,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const classNames = tw(variants.base, variants[variant || 'default'], className)

    return (
      <button className={classNames} ref={ref} {...rest}>
        {children}
      </button>
    )
  },
)

Button.displayName = 'MenuItem.Button'

interface MenuLinkProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  variant?: 'default' | 'outlined' | 'colored'
}

export const Link = React.forwardRef(
  ({ children, className, variant, ...rest }: MenuLinkProps, ref: React.Ref<HTMLAnchorElement>) => {
    const classNames = tw(variants.base, variants[variant || 'default'], className)
    const Link = useLinkComponent()

    return (
      <Link className={classNames} ref={ref} {...rest}>
        {children}
      </Link>
    )
  },
)

Link.displayName = 'MenuItem.Link'
