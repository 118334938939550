import { fetcher } from 'api/fetcher';
import { ApiEndpoint } from 'api/fetcher.types';

import { UpdateGdprParams } from './gdpr.types';

export const updateGdprFetcher = (params: UpdateGdprParams) => {
  const { userId, consent, page, onError } = params;
  const body = JSON.stringify({ id: userId, consent, page });

  return fetcher({
    url: ApiEndpoint.GDPR,
    options: {
      method: 'POST',
      body,
      headers: { 'Content-Type': 'application/json' },
    },
    onError,
  });
};
