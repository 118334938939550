import { useRouter } from 'next/router';
import { useCallback } from 'react';
import { UseRefinementListProps } from 'react-instantsearch';

import { SidebarFilter } from 'components/elements/organisms/algoliaConnected/SidebarFilter/SidebarFilter';
import { ChromeColorPickerFilter } from 'components/elements/molecules/colorPickerFilter/ChromeColorPickerFilter';
import { useFormatMessage } from 'utilities/i18n';
import { Divider } from 'components/elements/atoms/divider/Divider';
import { useLocale } from 'hooks/useLocale/useLocale';
import { unArray } from 'utilities/js';
import { useGetContentCategoriesQuery, Language } from 'generated/graphql';
import { STOCKPHOTOS_PARENT_ID } from 'components/pages/stockphotos/pages/config';
import { useStockphotosSearchPageContext } from 'components/contexts/instantSearchProvider/StockphotosSearchPageProvider';

export enum SearchableFilters {
  CATEGORY = 'categoryId',
  ORIENTATION = 'format',
  COLORS = 'colors',
  PEOPLE = 'people',
  OBJECTS = 'objects',
  LOCATIONS = 'locations',
}

export function SearchSidebar() {
  const t = useFormatMessage();
  const router = useRouter();
  const { algoliaLang, locale } = useLocale();
  const { color, updateColor } = useStockphotosSearchPageContext();

  const enableCustomExpandedFilters = router.query.expanded ? true : false;

  const expandendFilters = unArray(router.query.expanded)?.split(',') || [];

  const transformOrientationItems: UseRefinementListProps['transformItems'] = items => {
    const mapFormatToLabel = new Map([
      ['PRODUCT_FORMAT_SQUARE_1_1', t('stockphotos.search_page.filters.orientation.square')],
      ['PRODUCT_FORMAT_LANDSCAPE_3_2', t('stockphotos.search_page.filters.orientation.landscape.3_2')],
      ['PRODUCT_FORMAT_LANDSCAPE_16_9', t('stockphotos.search_page.filters.orientation.landscape.16_9')],
      ['PRODUCT_FORMAT_PORTRAIT_2_3', t('stockphotos.search_page.filters.orientation.portrait.2_3')],
      ['PRODUCT_FORMAT_PORTRAIT_4_5', t('stockphotos.search_page.filters.orientation.portrait.4_5')],
      ['PRODUCT_FORMAT_PORTRAIT_9_16', t('stockphotos.search_page.filters.orientation.portrait.9_16')],
    ]);

    const sortOrder = [
      'PRODUCT_FORMAT_SQUARE_1_1',
      'PRODUCT_FORMAT_PORTRAIT_9_16',
      'PRODUCT_FORMAT_PORTRAIT_2_3',
      'PRODUCT_FORMAT_PORTRAIT_4_5',
      'PRODUCT_FORMAT_LANDSCAPE_3_2',
      'PRODUCT_FORMAT_LANDSCAPE_16_9',
    ];

    return items
      .sort((a, b) => sortOrder.indexOf(a.label) - sortOrder.indexOf(b.label))
      .map(item => {
        return {
          ...item,
          label: mapFormatToLabel.get(item.label) || '',
        };
      });
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformOrientationItems = useCallback(transformOrientationItems, []);

  const transformWithPeople: UseRefinementListProps['transformItems'] = (items, metadata) => {
    return items
      .map(item => {
        return {
          ...item,
          label:
            item.value === 'true'
              ? t('stockphotos.search_page.filters.people.people.true')
              : t('stockphotos.search_page.filters.people.people.false'),
        };
      })
      .sort((a, b) => b.value.localeCompare(a.value));
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformWithPeople = useCallback(transformWithPeople, []);

  const { data } = useGetContentCategoriesQuery({ parentId: STOCKPHOTOS_PARENT_ID, locale: locale as Language });

  const normalizeCategories: Record<string, { id: string; name: string; slug: string }> = {};

  data?.listContentCategories?.forEach(element => {
    normalizeCategories[element.id] = element;
  });

  const transformCategories: UseRefinementListProps['transformItems'] = (items, metadata) => {
    return items.map(item => {
      return {
        ...item,
        label: normalizeCategories[item.value]?.name || '',
      };
    });
  };

  //eslint-disable-next-line react-hooks/exhaustive-deps
  const memoedTransformCategories = useCallback(transformCategories, []);

  return (
    <div className="bg-white">
      <h3 className="p-5 text-sm font-bold">{t('stockphotos.search_page.filters')}</h3>
      <Divider thick />
      <div className="flex flex-col gap-5 p-5">
        <SidebarFilter
          attribute={`${SearchableFilters.CATEGORY}`}
          header={t('stockphotos.search_page.filters.categories')}
          isExpanded={enableCustomExpandedFilters ? expandendFilters.includes(SearchableFilters.CATEGORY) : true}
          transformItems={memoedTransformCategories}
        />
        <ChromeColorPickerFilter
          initialColor={color}
          onChange={updateColor}
          header={t('stockphotos.search_page.filters.color_picker')}
          isExpanded={enableCustomExpandedFilters ? expandendFilters.includes(SearchableFilters.COLORS) : true}
        />
        <SidebarFilter
          attribute={`${SearchableFilters.ORIENTATION}`}
          header={t('stockphotos.search_page.filters.orientation')}
          isExpanded={enableCustomExpandedFilters ? expandendFilters.includes(SearchableFilters.ORIENTATION) : true}
          transformItems={memoedTransformOrientationItems}
        />
        <SidebarFilter
          attribute={'flags.hasPeople'}
          header={t('stockphotos.search_page.filters.people.people')}
          isExpanded={enableCustomExpandedFilters ? expandendFilters.includes(SearchableFilters.PEOPLE) : true}
          transformItems={memoedTransformWithPeople}
          isSingleChoice={true}
        />
        <SidebarFilter
          attribute={`imageAnalysis.${algoliaLang}.${SearchableFilters.OBJECTS}`}
          header={t('stockphotos.search_page.filters.objects')}
          isExpanded={enableCustomExpandedFilters ? expandendFilters.includes(SearchableFilters.OBJECTS) : true}
        />
      </div>
    </div>
  );
}
