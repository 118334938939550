import { useCookies } from 'react-cookie';
import { Typography } from '@mui/material';

import { useFormatMessage } from 'utilities/i18n';
import { useMounted } from 'hooks/useMounted/useMounted';
import { useDeviceId } from 'hooks/useDeviceId/useDeviceId';
import { getMainDomain } from 'api/fetcher.utils';
import { Link } from 'components/elements/atoms/link/Link';

import * as S from './CookieConsent.styles';

export const GDPR_COOKIE_NAME = 'cfGdpr';

export const CookieConsent = () => {
  const t = useFormatMessage();
  const [cookies, setCookie] = useCookies([GDPR_COOKIE_NAME]);
  const { deviceId, generateDeviceId } = useDeviceId();
  const showGDPR = isGDPRLocation();

  const isOpen = !cookies[GDPR_COOKIE_NAME];
  const isConsentAccepted = !isOpen;
  const isMounted = useMounted();

  if (!isMounted || !showGDPR) {
    return null;
  }

  const handleConsent = () => {
    const expires = new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10); // 10 years
    setCookie(GDPR_COOKIE_NAME, 1, {
      path: '/',
      expires,
      domain: getMainDomain(),
    });

    generateDeviceId();
  };

  if (isConsentAccepted && !deviceId) {
    generateDeviceId();
  }

  return (
    <S.SnackbarRoot
      hasCloseButton
      hasHideDuration={false}
      onClose={handleConsent}
      open={isOpen}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      ClickAwayListenerProps={{ onClickAway: () => null }}
    >
      <Typography>
        {t('site_cookie_consent')}{' '}
        <Link href="https://www.iubenda.com/privacy-policy/24976076/cookie-policy" color="inherit" external>
          {t('site_cookie_consent_policy')}
        </Link>
      </Typography>
    </S.SnackbarRoot>
  );
};

function isGDPRLocation() {
  const gdprCountriesTimeZones = [
    { country: 'Austria', timeZone: 'Europe/Vienna' },
    { country: 'Belgium', timeZone: 'Europe/Brussels' },
    { country: 'Bulgaria', timeZone: 'Europe/Sofia' },
    { country: 'Croatia', timeZone: 'Europe/Zagreb' },
    { country: 'Cyprus', timeZone: 'Asia/Nicosia' },
    { country: 'Czech Republic', timeZone: 'Europe/Prague' },
    { country: 'Denmark', timeZone: 'Europe/Copenhagen' },
    { country: 'Estonia', timeZone: 'Europe/Tallinn' },
    { country: 'Finland', timeZone: 'Europe/Helsinki' },
    { country: 'France', timeZone: 'Europe/Paris' },
    { country: 'Germany', timeZone: 'Europe/Berlin' },
    { country: 'Greece', timeZone: 'Europe/Athens' },
    { country: 'Hungary', timeZone: 'Europe/Budapest' },
    { country: 'Ireland', timeZone: 'Europe/Dublin' },
    { country: 'Italy', timeZone: 'Europe/Rome' },
    { country: 'Latvia', timeZone: 'Europe/Riga' },
    { country: 'Lithuania', timeZone: 'Europe/Vilnius' },
    { country: 'Luxembourg', timeZone: 'Europe/Luxembourg' },
    { country: 'Malta', timeZone: 'Europe/Malta' },
    { country: 'Netherlands', timeZone: 'Europe/Amsterdam' },
    { country: 'Poland', timeZone: 'Europe/Warsaw' },
    { country: 'Portugal', timeZone: 'Europe/Lisbon' },
    { country: 'Romania', timeZone: 'Europe/Bucharest' },
    { country: 'Slovakia', timeZone: 'Europe/Bratislava' },
    { country: 'Slovenia', timeZone: 'Europe/Ljubljana' },
    { country: 'Spain', timeZone: 'Europe/Madrid' },
    { country: 'Sweden', timeZone: 'Europe/Stockholm' },
    { country: 'Iceland', timeZone: 'Atlantic/Reykjavik' },
    { country: 'Liechtenstein', timeZone: 'Europe/Vaduz' },
    { country: 'Norway', timeZone: 'Europe/Oslo' },
    { country: 'United Kingdom', timeZone: 'Europe/London' },
  ];

  return gdprCountriesTimeZones.map(val => val.timeZone).includes(Intl.DateTimeFormat().resolvedOptions().timeZone);
}
