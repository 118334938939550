import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import { NavItem, useNavItems } from '../../../hooks/use-nav-items'
import { useLinkComponent, useRouterUtil } from '../../../contexts/header-config-context'
import { useSecondaryNavItems } from '../../../hooks/use-secondary-nav-items'
import { PrimarySubmenu } from './primary-submenu'
import { containerClasses } from '..'
import { useHeaderContext } from '../../../contexts/main-navigation-context'
import { useIsIpad } from '../../../hooks/use-is-ipad'
import { getColorScheme, ColorScheme } from '../../../utils/get-color-scheme'

export function MainNavigation() {
  const { pathname } = useRouterUtil()
  const navItems = useNavItems()
  const secondaryNavItems = useSecondaryNavItems()
  const {
    setMainMenuContainerRef,
    activeItem,
    activePrimaryItem,
    activeSecondaryItem,
    horizontalPosition,
  } = useHeaderContext()
  const [, setItem] = activeItem
  const [, setActivePrimaryItem] = activePrimaryItem
  const [, setActiveSecondaryItem] = activeSecondaryItem
  const [item] = activeItem
  const hasSubItems = Boolean(item?.subMenuItems?.length)
  const isIpad = useIsIpad()

  const closeMenu = React.useCallback(() => {
    // We need to blur the active element to prevent the focus from being stuck on the active link
    const activeElement = document.activeElement as HTMLElement
    activeElement?.blur()
    setItem(null)
    setActivePrimaryItem(null)
    setActiveSecondaryItem(null)
  }, [setItem, setActivePrimaryItem, setActiveSecondaryItem])

  React.useEffect(closeMenu, [pathname, closeMenu])

  return (
    <div
      className={tw(
        'bg-cf-white relative z-[1] border-b border-[#D8DAE4]',
        isIpad ? 'cf-hidden' : 'cf-hidden min-[1175px]:block',
      )}
    >
      <div
        id="main-navigation-wrapper"
        className={tw(
          containerClasses,
          'flex flex-nowrap items-center justify-between gap-6',
          'relative',
        )}
      >
        <NavigationBlock items={navItems} closeMenu={closeMenu} />
        <NavigationBlock items={secondaryNavItems} closeMenu={closeMenu} />
        {hasSubItems ? (
          <div
            id="dropdown-wrapper"
            className={tw(
              'rounded-2 z-cf-menu absolute left-0 top-full box-content overflow-hidden px-4 pb-8 pt-[1px]',
              activePrimaryItem[0]?.subMenuItems && 'w-[calc(100%-2*16px)]',
            )}
            style={{ left: activePrimaryItem[0]?.subMenuItems ? 0 : horizontalPosition }}
          >
            <div
              className={tw(
                'rounded-2 bg-cf-dreamy-cloud-400 text-cf-dark-aubergine shadow-cf-elevation-light-5 relative flex overflow-hidden',
              )}
              ref={setMainMenuContainerRef}
            />
          </div>
        ) : null}
      </div>
    </div>
  )
}

export function NavigationBlock({
  items,
  closeMenu,
  colors,
}: {
  items: NavItem[]
  closeMenu: () => void
  colors?: Partial<ColorScheme>
}) {
  const colorScheme = getColorScheme(colors)
  const Link = useLinkComponent()
  const { pathname } = useRouterUtil()
  const { activeItem, activePrimaryItem, activeSecondaryItem } = useHeaderContext()
  const [item, setItem] = activeItem
  const [, setActivePrimaryItem] = activePrimaryItem
  const [, setActiveSecondaryItem] = activeSecondaryItem

  return (
    <NavigationMenu.Root
      className="relative flex"
      value={item?.name || ''}
      onValueChange={value => {
        const foundItem = items.find(i => i.name === value) ?? null
        const firstPrimaryItem = foundItem?.subMenuItems?.[0] ?? null
        const firstSecondaryItem = foundItem?.subMenuItems?.[0]?.subMenuItems?.[0] ?? null
        setItem(foundItem)
        setActivePrimaryItem(firstSecondaryItem ? firstPrimaryItem : null)
        setActiveSecondaryItem(firstSecondaryItem ? firstSecondaryItem : null)
      }}
      onClickCapture={e => {
        const isOpenInNewTab =
          (e.target as HTMLElement).parentElement?.getAttribute('target') === '_blank'
        if (isOpenInNewTab) {
          // We need to blur the active element to prevent the focus from being stuck on the active link
          setTimeout(closeMenu, 300)
        }
      }}
    >
      <NavigationMenu.List className="flex h-full items-center justify-stretch gap-2">
        {items.map((item, index) => {
          return (
            <NavigationMenu.Item key={`${item.id}_${index}`} value={item.name}>
              {/** Passing asChild here breaks keyboard navigation because if the navigation trigger is also a link when hitting keyboard enter it will navigate to the destination instead of opening the content */}
              <NavigationMenu.Trigger
                style={{
                  '--text-color': colorScheme.textColor,
                  '--border-color': colorScheme.borderColor,
                  '--focus-color': colorScheme.textHoverColor,
                }}
                className={tw(
                  'text-[var(--text-color)]',
                  'data-[state=open]:border-[var(--border-color)]',
                  'data-[state=open]:text-[var(--focus-color)]',
                  'focus:border-[var(--focus-color)]',
                  'focus:text-[var(--focus-color)]',
                  'group border-b-4 border-transparent transition-colors',
                  item.link &&
                    replaceLanguageInPath(pathname) === item.link &&
                    'border-[var(--border-color)] text-[var(--text-color)]',
                )}
              >
                {item.link ? (
                  <Link
                    href={item.link}
                    className={tw('block px-2 py-3', '2xl:text-14 text-xs leading-4 2xl:leading-4')}
                  >
                    {item.name}
                  </Link>
                ) : (
                  <div
                    className={tw('block px-2 py-3', '2xl:text-14 text-xs leading-4 2xl:leading-4')}
                  >
                    {item.name}
                  </div>
                )}
              </NavigationMenu.Trigger>
              <PrimarySubmenu items={item.subMenuItems} colors={colorScheme} />
            </NavigationMenu.Item>
          )
        })}
      </NavigationMenu.List>
    </NavigationMenu.Root>
  )
}

function replaceLanguageInPath(path: string) {
  const pathname = path.replace(/^\/(en|es|de|pt|fr|it|nl|pl)\//, '/')
  return pathname.endsWith('/') ? pathname : `${pathname}/`
}
