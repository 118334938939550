import { ModalProps } from 'components/elements/molecules/modal/Modal.types';

export enum AuthModalScenario {
  LOGIN = 'login',
  REGISTER = 'register',
  RESET = 'reset',
}

export type AuthModalProps = ModalProps & {
  scenario: AuthModalScenario;
};
