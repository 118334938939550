import * as React from 'react'

interface TextWrapperProps extends React.HTMLAttributes<HTMLDivElement> {
  text: string
  maxLines: number
}

export function TextWrapper({ text, maxLines, ...rest }: TextWrapperProps) {
  const textRef = React.useRef<HTMLDivElement>(null)
  const [fallback, setFallback] = React.useState(false)

  React.useEffect(() => {
    if (textRef.current && !('webkitLineClamp' in document.body.style)) {
      setFallback(true)
      const lineHeight = parseInt(window.getComputedStyle(textRef.current).lineHeight)
      const maxHeight = lineHeight * maxLines
      textRef.current.style.maxHeight = `${maxHeight}px`
      textRef.current.style.overflow = 'hidden'
    }
  }, [maxLines])

  const style = {
    ...(maxLines > 1
      ? { display: '-webkit-box', WebkitBoxOrient: 'vertical', WebkitLineClamp: maxLines }
      : {}),
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  } as React.CSSProperties

  return (
    <div ref={textRef} {...rest} style={fallback ? {} : style} title={fallback ? text : ''}>
      {text}
    </div>
  )
}
