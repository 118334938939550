export function ShoppingCart03Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.66613 11.6665H15.6126C16.4566 11.6665 16.8786 11.6665 17.2146 11.5091C17.5105 11.3705 17.7592 11.148 17.9296 10.8691C18.1231 10.5526 18.1697 10.1332 18.2629 9.29432L18.7504 4.90679C18.7789 4.65057 18.7931 4.52246 18.7519 4.4233C18.7157 4.33622 18.6511 4.26398 18.5685 4.2184C18.4745 4.1665 18.3456 4.1665 18.0878 4.1665H4.24947M2.16602 1.6665H3.20638C3.4269 1.6665 3.53716 1.6665 3.62342 1.70844C3.69936 1.74536 3.7623 1.80448 3.80388 1.87797C3.85112 1.96145 3.85799 2.0715 3.87175 2.29159L4.62695 14.3748C4.6407 14.5948 4.64758 14.7049 4.69482 14.7884C4.7364 14.8619 4.79933 14.921 4.87528 14.9579C4.96154 14.9998 5.0718 14.9998 5.29232 14.9998H16.3327M6.74935 17.9165H6.75768M14.2493 17.9165H14.2577M7.16602 17.9165C7.16602 18.1466 6.97947 18.3332 6.74935 18.3332C6.51923 18.3332 6.33268 18.1466 6.33268 17.9165C6.33268 17.6864 6.51923 17.4998 6.74935 17.4998C6.97947 17.4998 7.16602 17.6864 7.16602 17.9165ZM14.666 17.9165C14.666 18.1466 14.4795 18.3332 14.2493 18.3332C14.0192 18.3332 13.8327 18.1466 13.8327 17.9165C13.8327 17.6864 14.0192 17.4998 14.2493 17.4998C14.4795 17.4998 14.666 17.6864 14.666 17.9165Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
