import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';

import { AuthModalProps, AuthModalScenario } from 'components/elements/organisms/authModal/AuthModal.types';
import { LoginFormValues, LoginModalProps } from 'components/elements/organisms/authModal/loginModal/LoginModal.types';
import {
  RegisterFormValues,
  RegisterModalProps,
} from 'components/elements/organisms/authModal/registerModal/RegisterModal.types';
import { ResetFormValues, ResetModalProps } from 'components/elements/organisms/authModal/resetModal/ResetModal.types';
import {
  analyticsTrackLoginModalOpen,
  analyticsTrackRegistrationModalOpen,
} from 'components/app/analytics/cfTracking/CfTracking.utils';
import { AuthModalLocations } from 'components/app/analytics/amplitude/Amplitude.types';

const LoginModal = dynamic<LoginModalProps>(() => import('./loginModal/LoginModal').then(module => module.LoginModal));
const RegisterModal = dynamic<RegisterModalProps>(() =>
  import('./registerModal/RegisterModal').then(module => module.RegisterModal),
);
const ResetModal = dynamic<ResetModalProps>(() => import('./resetModal/ResetModal').then(module => module.ResetModal));

const initialLoginForm = { email: '', password: '', remember: false };
const initialRegisterForm = { email: '', password: '', terms: false };
const initialResetForm = { email: '' };

export const AuthModal = (props: AuthModalProps) => {
  const { scenario: initialScenario, ...modalProps } = props;
  const [scenario, setScenario] = useState<AuthModalScenario>(initialScenario);
  const [loginForm, setLoginForm] = useState<LoginFormValues>(initialLoginForm);
  const [registerForm, setRegisterForm] = useState<RegisterFormValues>(initialRegisterForm);
  const [resetForm, setResetForm] = useState<ResetFormValues>(initialResetForm);

  // Update scenario if changed from parent
  useEffect(() => {
    setScenario(initialScenario);
  }, [initialScenario]);

  const handleScenarioChange = (nextScenario: AuthModalScenario) => {
    if (nextScenario === AuthModalScenario.REGISTER) {
      analyticsTrackRegistrationModalOpen(AuthModalLocations.LOGIN_MODAL);
    } else {
      analyticsTrackLoginModalOpen(AuthModalLocations.REGISTRATION_MODAL);
    }

    setScenario(nextScenario);
  };

  if (!modalProps.isOpened) {
    return null;
  }

  if (scenario === AuthModalScenario.LOGIN) {
    return (
      <LoginModal
        onToRegisterClick={() => handleScenarioChange(AuthModalScenario.REGISTER)}
        onToResetClick={() => setScenario(AuthModalScenario.RESET)}
        loginForm={loginForm}
        setLoginForm={setLoginForm}
        postLoginBehaviour="refresh"
        {...modalProps}
      />
    );
  }

  if (scenario === AuthModalScenario.REGISTER) {
    return (
      <RegisterModal
        onToLoginClick={() => handleScenarioChange(AuthModalScenario.LOGIN)}
        registerForm={registerForm}
        setRegisterForm={setRegisterForm}
        postLoginBehaviour="refresh"
        {...modalProps}
      />
    );
  }

  if (scenario === AuthModalScenario.RESET) {
    return (
      <ResetModal
        onToLoginClick={() => handleScenarioChange(AuthModalScenario.LOGIN)}
        resetForm={resetForm}
        setResetForm={setResetForm}
        {...modalProps}
      />
    );
  }

  return null;
};
