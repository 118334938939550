import * as React from 'react'

// This is not a perfect replacement for Next.js router because it does full page reload navigation; it is only to be used as a fallback for WordPress.
export function useRouter() {
  const push = React.useCallback((newLocation: string) => {
    window.location.href = newLocation
  }, [])

  return { push }
}
