import { useEffect } from 'react';
import Script from 'next/script';

import { useLocale } from 'hooks/useLocale/useLocale';
import { useOptionalUser } from 'hooks/useAuthState/useAuthState';
import { User } from 'api/requests/user/user.types';
import { accessSubscriptionStatuses } from 'hooks/useHasSubscription/useHasSubscription';

enum UserType {
  VISITOR = 'visitor',
  USER = 'user',
  CUSTOMER = 'customer',
  SUBSCRIBER = 'subscriber',
  DESIGNER = 'designer',
}

enum ZendeskUserType {
  VISITOR = 'visitor',
  USER = 'user',
  PREMIUM_USER = 'premium_user',
  DESIGNER = 'designer',
}

const mapCustomerTypeToZendeskUserType = new Map<UserType, ZendeskUserType>([
  [UserType.VISITOR, ZendeskUserType.VISITOR],
  [UserType.USER, ZendeskUserType.USER],
  [UserType.CUSTOMER, ZendeskUserType.USER],
  [UserType.SUBSCRIBER, ZendeskUserType.PREMIUM_USER],
  [UserType.DESIGNER, ZendeskUserType.DESIGNER],
]);

export const decideCustomerType = (user: User | undefined): UserType => {
  if (!user) {
    return UserType.VISITOR;
  }

  if (user.identification.isDesigner) {
    return UserType.DESIGNER;
  }

  if (user.subscriptions.find(sub => accessSubscriptionStatuses.has(sub.status))) {
    return UserType.SUBSCRIBER;
  }

  if (user.isCustomer) {
    return UserType.CUSTOMER;
  }

  return UserType.USER;
};

export const TicketGenieSnippet = () => {
  const user = useOptionalUser();
  const { locale } = useLocale();

  const customerType = decideCustomerType(user || undefined);

  const userDataSnippet = user
    ? `userData: () => {
return {
 user: {
   email: '${user.identification.email}',
   displayName: '${user.identification.displayName}',
 },
 metadata: {
   cf_customer_id: '${user.identification.id}',
   customer_type: '${customerType}',
 },
};
},`
    : '';

  useEffect(() => {
    const handleResize = () => {
      const banner = document.getElementsByClassName('fixed-banner');

      if (banner.length > 0) {
        const supportButton = document.getElementById('tg-support-widget-tgsw');

        if (supportButton) {
          if (window.innerWidth < 768) {
            const rect = banner[0].getBoundingClientRect();
            supportButton.style.setProperty('--widget-bottom', `${rect.height + 5}px`);

            return;
          }
          supportButton.style.setProperty('--widget-bottom', '20px');
        }
      }
    };

    const handleScroll = () => {
      setTimeout(() => {
        const banner = document.getElementsByClassName('fixed-banner');
        const supportButton = document.getElementById('tg-support-widget-tgsw');

        if (supportButton) {
          if (banner.length > 0) {
            const rect = banner[0].getBoundingClientRect();
            supportButton.style.setProperty('--widget-bottom', `${rect.height + 5}px`);

            return;
          }
          supportButton.style.setProperty('--widget-bottom', '20px');
        }
      }, 300);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Script id="ticket-genie-script" strategy="afterInteractive">
        {`
         (function (w, d, s, o, f, js, fjs) {
           w.CF = w.CF || {};
           w.CF[o] =
             w.CF[o] ||
             function () {
               (w.CF[o].q = w.CF[o].q || []).push(arguments);
             };
           (js = d.createElement(s)), (fjs = d.getElementsByTagName(s)[0]);
           js.id = o;
           js.src = f;
           js.async = 1;
           fjs.parentNode.insertBefore(js, fjs);
         })(window, document, 'script', 'tgsw', '${process.env.NEXT_PUBLIC_TICKET_GENIE_URL}');
         CF.tgsw('init', {
           channelId: '${process.env.NEXT_PUBLIC_TICKET_GENIE_CHANNEL_ID}',
           customerName: '${process.env.NEXT_PUBLIC_ENV !== 'staging' ? 'creativefabrica' : 'cf'}',
           lang: '${locale}',
           zendeskCustomFields: () => {
             return [
               {
                 fieldId: 4807001644316,
                 value: '${mapCustomerTypeToZendeskUserType.get(customerType)}',
               },
             ];
           },
           ${userDataSnippet}
         });
        `}
      </Script>
    </>
  );
};
