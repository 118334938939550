export function ChevronDownIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.98999 7.12012L7.97999 11.1101L11.97 7.12012"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
    </svg>
  )
}
