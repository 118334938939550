import { ReactNode } from 'react';

export enum FooterVariant {
  SIMPLE = 'simple',
  STANDARD = 'standard',
}

export enum HeaderVariant {
  SIMPLE = 'simple',
  STANDARD = 'standard',
}

export type LayoutProps = {
  children: ReactNode;
  hideFooter?: boolean;
  hideBanner?: boolean;
  hasBackground?: boolean;
  footerVariant?: FooterVariant;
  headerVariant?: HeaderVariant;
  hideScrollToTopOnMobile?: boolean;
  hideSupportWidget?: boolean;
};
