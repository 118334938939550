import * as React from 'react'
import {
  InstantSearch,
  useInstantSearch,
  InstantSearchServerState,
  InstantSearchSSRProvider,
} from 'react-instantsearch'
import algoliaAnalytics from 'search-insights'
import algoliasearch from 'algoliasearch/lite'
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares'
import invariant from 'tiny-invariant'

import {
  productsAlgoliaApiKey,
  productsAlgoliaAppId,
  productsAlgoliaIndex,
} from './algolia.configs'

invariant(productsAlgoliaAppId, 'Missing Algolia App ID')
invariant(productsAlgoliaApiKey, 'Missing Algolia API Key')
invariant(productsAlgoliaIndex, 'Missing Algolia Index')

export const searchClient = algoliasearch(productsAlgoliaAppId, productsAlgoliaApiKey)

const initialAlgoliaData: InstantSearchServerState = {
  initialResults: {
    [productsAlgoliaIndex]: {
      state: {
        facets: [],
        disjunctiveFacets: [],
        disjunctiveFacetsRefinements: {},
        hierarchicalFacets: [],
        facetsRefinements: {},
        facetsExcludes: {},
        numericRefinements: {},
        tagRefinements: [],
        hierarchicalFacetsRefinements: {},
        index: productsAlgoliaIndex,
        clickAnalytics: true,
        maxValuesPerFacet: 20,
        query: '',
        highlightPreTag: '__ais-highlight__',
        highlightPostTag: '__/ais-highlight__',
      },
      results: [
        {
          query: '',
          hits: [],
          index: productsAlgoliaIndex,
          hitsPerPage: 5,
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0,
          exhaustiveNbHits: false,
          params: '',
        },
      ],
    },
  },
}

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch()

  React.useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: algoliaAnalytics,
    })

    return addMiddlewares(middleware)
  }, [addMiddlewares])

  return null
}

export const InstantSearchProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <InstantSearchSSRProvider {...initialAlgoliaData}>
      <InstantSearch
        searchClient={searchClient}
        indexName={productsAlgoliaIndex}
        future={{ preserveSharedStateOnUnmount: false }} // Silences console warning about next major version change regarding shared state. Read more on https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-future
      >
        {children}
        <InsightsMiddleware />
      </InstantSearch>
    </InstantSearchSSRProvider>
  )
}
