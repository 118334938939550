import { Box, useMediaQuery, useTheme } from '@mui/material';
import { useState } from 'react';

import { Spinner } from 'components/elements/atoms/spinner/Spinner';

import { WindowWithZendesk } from './ZendeskSnippet.types';
import * as S from './ZendeskSnippet.styles';

const zendeskSnippetId = 'ze-snippet';
const zendeskSnippetUrl = 'https://static.zdassets.com/ekr/snippet.js';
const zendeskKey = process.env.NEXT_PUBLIC_ZENDESK_KEY;
const zendeskSettings = {
  webWidget: {
    offset: {
      horizontal: '0px',
      vertical: '0px',
      mobile: {
        horizontal: '15px',
        vertical: '110px',
      },
    },
    color: {
      theme: '#30aabc',
    },
  },
};

export const ZendeskSnippet = () => {
  const [isInitialized, setInitialized] = useState(false);
  const [isActivated, setActivated] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const activateSnippet = () => {
    const Window = window as WindowWithZendesk;

    Window.zESettings = zendeskSettings;

    const interval = setInterval(() => {
      if (Window?.zE && typeof Window?.zE.activate === 'function') {
        clearInterval(interval);
        Window?.zE?.activate();
        setActivated(true);
      }
    }, 50);
  };

  const insertScript = () => {
    setInitialized(true);

    if (isInitialized) {
      return;
    }

    const script = document.createElement('script');
    script.id = zendeskSnippetId;
    script.src = `${zendeskSnippetUrl}?key=${zendeskKey}`;
    document.body.appendChild(script);

    activateSnippet();
  };

  if (!zendeskKey) {
    return null;
  }

  const isLoading = isInitialized && !isActivated;
  const rootOffsets = isMobile
    ? {
        bottom: zendeskSettings.webWidget.offset.mobile.vertical,
        right: zendeskSettings.webWidget.offset.mobile.horizontal,
      }
    : {
        bottom: zendeskSettings.webWidget.offset.vertical,
        right: zendeskSettings.webWidget.offset.horizontal,
      };
  const activatedStyles = isActivated
    ? ({
        transform: 'translateY(10px)',
        opacity: 0,
        visibility: 'hidden',
      } as const)
    : {};

  return (
    <S.BoxRoot sx={{ ...rootOffsets, ...activatedStyles }}>
      <S.ButtonStyled onClick={insertScript} aria-label={isMobile ? 'support' : undefined}>
        <S.BoxIcon>
          {isLoading ? (
            <Spinner />
          ) : (
            <svg width="20" height="20" viewBox="0 0 20 20" aria-hidden="true" style={{ display: 'block' }}>
              <g id="Layer_4">
                <path d="M11,12.3V13c0,0-1.8,0-2,0v-0.6c0-0.6,0.1-1.4,0.8-2.1c0.7-0.7,1.6-1.2,1.6-2.1c0-0.9-0.7-1.4-1.4-1.4 c-1.3,0-1.4,1.4-1.5,1.7H6.6C6.6,7.1,7.2,5,10,5c2.4,0,3.4,1.6,3.4,3C13.4,10.4,11,10.8,11,12.3z"></path>
                <circle cx="10" cy="15" r="1"></circle>
                <path d="M10,2c4.4,0,8,3.6,8,8s-3.6,8-8,8s-8-3.6-8-8S5.6,2,10,2 M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0 L10,0z"></path>
              </g>
            </svg>
          )}
        </S.BoxIcon>
        {!isMobile && <Box>Support</Box>}
      </S.ButtonStyled>
    </S.BoxRoot>
  );
};
