import * as React from 'react'

import { NavItem } from '../hooks/use-nav-items'

export interface MainNavigationData {
  mainMenuContainerRef: HTMLDivElement | null
  activeItem: [NavItem | null, React.Dispatch<React.SetStateAction<NavItem | null>>]
  activePrimaryItem: [NavItem | null, React.Dispatch<React.SetStateAction<NavItem | null>>]
  activeSecondaryItem: [NavItem | null, React.Dispatch<React.SetStateAction<NavItem | null>>]
  setMainMenuContainerRef: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>
  horizontalPosition: number
  mainMenuContainerMaxHeight: number
}

const MIN_MENU_DROPDOWN_HEIGHT = 300
export const HeaderContext = React.createContext<MainNavigationData>(null!)

export function MainNavigationContextProvider({ children }: React.PropsWithChildren<unknown>) {
  const [mainMenuContainerRef, setMainMenuContainerRef] = React.useState<HTMLDivElement | null>(
    null,
  )
  const activeItem = React.useState<NavItem | null>(null)
  const [activeItemRef, setActiveItemRef] = React.useState<HTMLDivElement | null>(null)
  const activePrimaryItem = React.useState<NavItem | null>(null)
  const activeSecondaryItem = React.useState<NavItem | null>(null)
  const [horizontalPosition, setHorizontalPosition] = React.useState<number>(-5000)
  const [mainMenuContainerMaxHeight, setMainMenuContainerMaxHeight] =
    React.useState<number>(MIN_MENU_DROPDOWN_HEIGHT)

  React.useEffect(() => {
    if (activeItem[0]?.name) {
      const element = document.querySelector(`button[id$="-trigger-${activeItem[0]?.name}"]`)
      setActiveItemRef(element as HTMLDivElement)
    } else {
      setActiveItemRef(null)
      setMainMenuContainerRef(null)
    }
  }, [activeItem, setActiveItemRef])

  React.useEffect(() => {
    const parent = document.querySelector('#main-navigation-wrapper')
    const elementRec = activeItemRef?.getBoundingClientRect()
    const parentRec = parent?.getBoundingClientRect()
    const parentStyle = window.getComputedStyle(parent as Element)
    const parentPadding =
      parseInt(parentStyle.getPropertyValue('padding-left')) +
        parseInt(parentStyle.getPropertyValue('padding-right')) || 0
    const dropDownContainerWidth = document.querySelector('#dropdown-wrapper')?.clientWidth || 0

    if (elementRec && parentRec) {
      const maxLeft = document.documentElement.clientWidth - dropDownContainerWidth - parentRec.left
      const position = elementRec.left - parentRec.left - parentPadding
      const isOutsideViewport = position > maxLeft
      if (isOutsideViewport) {
        setHorizontalPosition(maxLeft)
        return
      }
      if ((position < 0 && parentRec.left + position > 0) || position > 0) {
        setHorizontalPosition(position)
      } else {
        setHorizontalPosition(0)
      }
    }
  }, [activeItemRef])

  React.useEffect(() => {
    const parent = document.querySelector('#main-navigation-wrapper')
    if (parent) {
      const windowHeight = window.innerHeight
      const rect = parent.getBoundingClientRect()
      const bottomSpace = 50

      setMainMenuContainerMaxHeight(
        Math.floor(
          Math.max(windowHeight - rect.top - rect.height - bottomSpace, MIN_MENU_DROPDOWN_HEIGHT),
        ),
      )
    }
  }, [mainMenuContainerRef])

  return (
    <HeaderContext.Provider
      value={{
        mainMenuContainerRef,
        setMainMenuContainerRef,
        activeItem,
        activePrimaryItem,
        activeSecondaryItem,
        horizontalPosition,
        mainMenuContainerMaxHeight,
      }}
    >
      {React.useMemo(() => children, [children])}
    </HeaderContext.Provider>
  )
}

export function useHeaderContext() {
  return React.useContext(HeaderContext)
}

export function useMainMenuContainerRef() {
  return useHeaderContext().mainMenuContainerRef
}

export function useActiveItem() {
  return useHeaderContext().activeItem
}

export function useActivePrimaryItem() {
  return useHeaderContext().activePrimaryItem
}

export function useActiveSecondaryItem() {
  return useHeaderContext().activeSecondaryItem
}

export function useMaxHeightMenuDropdown() {
  return useHeaderContext().mainMenuContainerMaxHeight
}
