import React from 'react';
import { useRouter } from 'next/router';

import { useFormatMessage } from 'utilities/i18n';
import { AppRoute } from 'components/app/App.types';
import { Link } from 'components/elements/atoms/link/Link';
import { FutureImage } from 'components/elements/atoms/image/FutureImage';

import footerLinks from './data.json';

type Languages = keyof typeof footerLinks;
type Sections = keyof typeof footerLinks['en'];

const normalizeFooterLinkUrl = (url: string) => {
  if (url.indexOf('http') === 0 || url.indexOf('https') === 0) {
    return url;
  }

  return url.indexOf('/') === 0 ? url : `/${url}`;
};

const FooterHeading = ({ children }: { children: React.ReactNode }) => (
  <p className="m-[20px_0px_15px_0px] text-base leading-[1.4]">{children}</p>
);

const FooterText = ({ children }: { children: React.ReactNode }) => (
  <p className="pb-4 text-xs leading-[1.6]">{children}</p>
);

export const Footer = () => {
  const t = useFormatMessage();
  const { locale } = useRouter();
  const renderFooterLinks = (section: Sections) => {
    const lang = (locale || 'en') as Languages;
    const links = footerLinks[lang][section];

    return (
      <ul className="m-0 list-none p-0">
        {links.map(link => (
          <li key={`${link.url}-${link.title}-${section}`}>
            <Link className="mb-3 text-xs" href={normalizeFooterLinkUrl(link.url)} underline="none" color="secondary">
              {link.title.replace('&amp;', '&')}
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <footer className="whitespace-pre-line bg-[#393c57] p-1 text-white md:p-[20px_0px_16px_0px]">
      <div className="mx-auto w-full px-5 md:max-w-[1220px]">
        <div className="ml-[-16px] mt-[-16px] flex w-[calc(100%+16px)] flex-wrap">
          <div className="w-full flex-grow-0 basis-[100%] pl-4 pt-4 md:max-w-[50%] md:basis-1/2 lg:max-w-[25%] lg:basis-1/4">
            <div>
              <FooterHeading>Creative Fabrica</FooterHeading>
              <FooterText>{t('footer.created_at')}</FooterText>
              <FooterText>{t('footer.mission')}</FooterText>
              <Link className="mb-3 block text-xs" href={AppRoute.ABOUT} underline="none" color="secondary">
                {t('footer.learn_more')}
              </Link>
              <Link className="mb-3 block text-xs" href={AppRoute.HIRING} underline="none" color="secondary" external>
                {t('footer.hiring')}
              </Link>
              <Link className="mb-3 block text-xs" href={AppRoute.ARTISTRY_BLOG} underline="none" color="secondary">
                {t('footer.blog')}
              </Link>
            </div>
            <div className="mt-8" data-testid="footer-learn">
              <FooterHeading>{t('footer.learn')}</FooterHeading>
              {renderFooterLinks('footer-learn')}
            </div>
          </div>

          <div className="w-full flex-grow-0 basis-[100%] pl-4 pt-4 md:max-w-[50%] md:basis-1/2 lg:max-w-[25%] lg:basis-1/4">
            <div className="pl-0 lg:pl-14" data-testid="footer-links">
              <FooterHeading>{t('footer.links')}</FooterHeading>
              {renderFooterLinks('footer-links')}
            </div>
          </div>

          <div className="w-full flex-grow-0 basis-[100%] pl-4 pt-4 md:max-w-[50%] md:basis-1/2 lg:max-w-[25%] lg:basis-1/4">
            <FooterHeading>{t('footer.contact')}</FooterHeading>
            <FooterText>{t('footer.contact_us')}</FooterText>
            <FooterText>{t('footer.address')}</FooterText>
            <FooterText>{t('footer.chamber_of_commerce')}</FooterText>

            <div data-testid="footer-tools">
              <FooterHeading>{t('footer.tools')}</FooterHeading>
              {renderFooterLinks('footer-tools')}
            </div>
          </div>

          <div className="w-full flex-grow-0 basis-[100%] pl-4 pt-4 md:max-w-[50%] md:basis-1/2 lg:max-w-[25%] lg:basis-1/4">
            <div className="pr-0 lg:pr-8">
              <FooterHeading>{t('footer.secure_checkout')}</FooterHeading>
              <FooterText>{t('footer.your_data')}</FooterText>
              <FutureImage
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/powered-by-stripe.png`}
                alt="Stripe"
                width={150}
                height={33}
                className="!justify-start"
              />
              <div className="mb-2" />
              <FutureImage
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/pp-secure-checkout.png`}
                alt="Paypal"
                width={150}
                height={60}
                className="!justify-start"
              />
            </div>
          </div>

          <div className="w-full flex-grow-0 basis-[100%] pl-4 pt-4">
            <div className="border-1 mt-6 flex flex-col justify-between border-t border-[rgba(255,255,255,.2)] pt-7 md:flex-row">
              <FooterText>
                {t('footer.copy', {
                  values: {
                    currentYear: new Date().getFullYear(),
                  },
                })}
              </FooterText>
              <div className="text-xs">
                {t('footer.questions_mail_us')}
                <Link
                  className="mb-3 ml-1 text-xs"
                  href="mailto:hi@creativefabrica.com"
                  color="secondary"
                  underline="none"
                >
                  hi@creativefabrica.com
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
