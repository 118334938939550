import { Container, Grid } from '@mui/material';

import { useFormatMessage } from 'utilities/i18n';
import { AppRoute } from 'components/app/App.types';

import * as S from './SimpleHeader.styles';

export const SimpleHeader = () => {
  const t = useFormatMessage();

  return (
    <S.HeaderRoot>
      <Container maxWidth="xl">
        <Grid container>
          <S.GridColumnLeft item xs={3}>
            <S.LinkStyled color="black" underline="none" href={AppRoute.HOME}>
              <S.IconBack icon="arrow-left" />
              <S.TypographyStyled>{t('common.header.simple.back')}</S.TypographyStyled>
            </S.LinkStyled>
          </S.GridColumnLeft>
          <S.GridColumnCenter item xs={6}>
            <S.LinkLogo href={AppRoute.HOME} underline="none" color="inherit">
              Creative Fabrica
            </S.LinkLogo>
          </S.GridColumnCenter>
          <Grid item xs={3} />
        </Grid>
      </Container>
    </S.HeaderRoot>
  );
};
