import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'
import { type NavigationMenuItemProps } from 'primitives/navigation-menu'

import { NavItem } from '../../../hooks/use-nav-items'
import { ArrowNarrowUpRight } from '../../icons/arrow-narrow-up-right'
import { useActivePrimaryItem } from '../../../contexts/main-navigation-context'
import { ChevronRightIcon } from '../../icons/chevron-right-icon'
import { useLinkComponent } from '../../../contexts/header-config-context'
import { TextWrapper } from '../../text-wrapper'
import { getColorScheme, ColorScheme } from '../../../utils/get-color-scheme'

type NavItemProps = NavigationMenuItemProps &
  React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>

interface NavigationSubItemProps extends NavItemProps {
  item: NavItem
  isActive?: boolean
  level: 'primary' | 'secondary' | 'tertiary'
  colors?: Partial<ColorScheme>
}

export function NavigationSubItem({
  item,
  onChange,
  isActive,
  children,
  className,
  level,
  colors,
}: NavigationSubItemProps) {
  const colorScheme = getColorScheme(colors)
  const Link = useLinkComponent()
  const [primary] = useActivePrimaryItem()

  const selectableItemClasses = tw(
    'text-14 relative inline-block w-full select-none items-center rounded-[18px] py-2 pl-4 pr-8 text-start outline-none hover:bg-[var(--hover-bg-color)] focus:bg-[var(--hover-bg-color)]',
    item.wrapTitle ? '' : 'whitespace-nowrap',
  )

  const isFootOption = item.isFootOption

  if (level === 'secondary' && primary?.trending && isFootOption) {
    return null
  }

  if (!item.subMenuItems?.length) {
    // We need to have NavigationMenu.Trigger here so that the parent NavigationMenu.Root can handle the click event
    return (
      <NavigationMenu.Item
        value={item.name}
        onClick={onChange}
        className="last-of-type:grow-1 flex h-full items-end last-of-type:mb-3"
      >
        <NavigationMenu.Trigger
          style={{
            '--hover-bg-color': colorScheme.backgroundHoverColor,
          }}
          className={tw('flex w-full', isFootOption ? 'items-end' : 'items-start')}
        >
          <NavigationMenu.Link
            className={tw(
              isFootOption
                ? 'text-14 border-cf-dreamy-cloud-600 mt-12 inline-flex w-full items-center whitespace-nowrap border-t px-4 py-2 text-start font-semibold'
                : selectableItemClasses,
              className,
            )}
            href={item.link}
            asChild
          >
            <Link>
              <TextWrapper text={item.name} maxLines={item.wrapTitle ? 2 : 1} />
              {isFootOption ? (
                <span className="pl-4">
                  <ArrowNarrowUpRight className="inline-block" />
                </span>
              ) : null}
            </Link>
          </NavigationMenu.Link>
        </NavigationMenu.Trigger>
        <NavigationMenu.Content>{children}</NavigationMenu.Content>
      </NavigationMenu.Item>
    )
  }

  return (
    <NavigationMenu.Item value={item.name} className="last-of-type:mb-3">
      <NavigationMenu.Trigger
        style={{
          '--bg-color': colorScheme.backgroundColor,
          '--hover-bg-color': colorScheme.backgroundHoverColor,
        }}
        className={tw(
          selectableItemClasses,
          'flex w-full items-center justify-between px-4',
          isActive ? 'bg-[var(--bg-color)]' : '',
        )}
      >
        <span className="pr-14">{item.name}</span>
        <ChevronRightIcon className="h-4 w-4" />
      </NavigationMenu.Trigger>
      <NavigationMenu.Content>{children}</NavigationMenu.Content>
    </NavigationMenu.Item>
  )
}
