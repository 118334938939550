export function Bell03Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.9998 15.8333C12.9998 17.214 11.8805 18.3333 10.4998 18.3333C9.11906 18.3333 7.99977 17.214 7.99977 15.8333M11.9969 5.1988C12.3598 4.82387 12.5831 4.31301 12.5831 3.75C12.5831 2.5994 11.6504 1.66666 10.4998 1.66666C9.34917 1.66666 8.41643 2.5994 8.41643 3.75C8.41643 4.31301 8.63977 4.82387 9.00268 5.1988M15.4998 9.33333C15.4998 8.18406 14.973 7.08186 14.0353 6.2692C13.0976 5.45654 11.8258 5 10.4998 5C9.17368 5 7.90192 5.45654 6.96423 6.2692C6.02655 7.08186 5.49977 8.18406 5.49977 9.33333C5.49977 11.2348 5.02821 12.6255 4.43982 13.6206C3.76922 14.7547 3.43391 15.3217 3.44715 15.4572C3.4623 15.6122 3.4902 15.6611 3.61588 15.753C3.72574 15.8333 4.27769 15.8333 5.38159 15.8333H15.6179C16.7218 15.8333 17.2738 15.8333 17.3837 15.753C17.5093 15.6611 17.5372 15.6122 17.5524 15.4572C17.5656 15.3217 17.2303 14.7547 16.5597 13.6206C15.9713 12.6255 15.4998 11.2348 15.4998 9.33333Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
