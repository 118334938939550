import { styled, Box, Typography } from '@mui/material';

import { Modal } from 'components/elements/molecules/modal/Modal';

export const ModalStyled = styled(Modal)(({ theme }) => ({
  '& .MuiPaper-root': {
    padding: 0,
    borderRadius: 0,
    '& .MuiDialogContent-root': {
      backgroundSize: 'cover',
      padding: 0,
    },
    '&.MuiDialog-paper': {
      maxWidth: theme.spacing(52.5),
    },
  },
}));

export const Content = styled(Box)(({ theme }) => ({
  color: theme.palette.common.white,
  textAlign: 'center',
  padding: theme.spacing(4, 0),
}));

export const Title = styled(Typography)(({ theme }) => ({
  lineHeight: 1.1,
  marginBottom: theme.spacing(2),
}));

export const Text = styled(Typography)(({ theme }) => ({
  lineHeight: 1.3,
  padding: theme.spacing(0, 6),
}));

export const CloseText = styled(Box)(({ theme }) => ({
  fontSize: 11,
  fontWeight: theme.typography.fontWeightRegular,
  color: theme.palette.common.white,
  textDecoration: 'underline',
  cursor: 'pointer',
}));

export const FooterText = styled(Typography)(({ theme }) => ({
  fontSize: 11,
  color: theme.palette.common.white,
}));
