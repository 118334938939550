import { Box } from '@mui/material';

import { SiteWideBanner } from 'components/elements/molecules/siteWideBanner/SiteWideBannerContainer';
import { SiteWidePopup } from 'components/elements/molecules/siteWidePopup/SiteWidePopupContainer';
import { CookieConsent } from 'components/elements/molecules/cookieConsent/CookieConsent';
import { ScrollToTopButton } from 'components/elements/molecules/scrollToTopButton/ScrollToTopButton';
import { Header } from 'components/elements/organisms/header/Header';
import { Footer } from 'components/elements/organisms/footer/Footer';
import { SimpleFooter } from 'components/elements/organisms/simpleFooter/SimpleFooter';
import { SimpleHeader } from 'components/elements/organisms/simpleHeader/SimpleHeader';

import { TicketGenieSnippet } from './TicketGenie/TicketGenieSnippet';
import { ZendeskSnippet } from './ZendeskSnippet/ZendeskSnippet';
import { HeaderVariant, FooterVariant, LayoutProps } from './Layout.types';

const showZendeskSupportWidget = process.env.NEXT_PUBLIC_SHOW_OLD_ZENDESK_SUPPORT_WIDGET === 'true';

export const Layout = ({
  children,
  hideBanner,
  hideFooter = false,
  hasBackground,
  headerVariant = HeaderVariant.STANDARD,
  footerVariant = FooterVariant.STANDARD,
  hideScrollToTopOnMobile,
  hideSupportWidget,
}: LayoutProps) => {
  return (
    <>
      <ScrollToTopButton hideOnMobile={hideScrollToTopOnMobile} />
      {!hideBanner && <SiteWideBanner />}
      <SiteWidePopup />
      <CookieConsent />
      {headerVariant === HeaderVariant.SIMPLE ? <SimpleHeader /> : <Header />}
      <Box component="main" sx={{ ...(hasBackground ? { backgroundColor: 'gray.extraLight' } : {}) }}>
        {children}
      </Box>
      {!hideSupportWidget && !showZendeskSupportWidget && <TicketGenieSnippet />}
      {!hideSupportWidget && showZendeskSupportWidget && <ZendeskSnippet />}
      {hideFooter ? null : footerVariant === FooterVariant.SIMPLE ? <SimpleFooter /> : <Footer />}
    </>
  );
};
