import { Box } from '@mui/material';

import { SiteWidePopupProps } from 'components/elements/molecules/siteWidePopup/SiteWidePopup.types';
import { Link } from 'components/elements/atoms/link/Link';

import * as S from './NewsletterPopup.styles';

export const NewsletterPopup = (props: SiteWidePopupProps) => {
  const { title, text, button, closeText, footerText, backgroundImageSrc, isOpened, onClose, buttonOnClick } = props;

  const handleOnClose = (event: Record<string, unknown> | undefined, reason: string | undefined) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  const buttonClassName =
    'flex items-center m-[auto] w-fit rounded text-sm text-white uppercase font-bold px-5 h-[45px] rounded bg-gradient-to-b from-[#8beb6a] to-[#1d9166] hover:bg-gradient-to-b hover:from-[#51bd2b] hover:to-[#118258]';

  return (
    <S.ModalStyled isOpened={isOpened} onClose={handleOnClose} hasCloseButton={false}>
      <S.Content style={{ backgroundImage: `url(${backgroundImageSrc})`, backgroundSize: 'cover' }}>
        <Box maxWidth={200} m="auto">
          <S.Title variant="h2">{title}</S.Title>
        </Box>
        <S.Text dangerouslySetInnerHTML={{ __html: text }} />
        {button && (
          <div className="my-5">
            {button.link ? (
              <Link
                className={buttonClassName}
                onClick={buttonOnClick}
                underline="none"
                color="inherit"
                href={button.link}
              >
                {button.text}
              </Link>
            ) : (
              <button className={buttonClassName} onClick={buttonOnClick}>
                {button.text}
              </button>
            )}
          </div>
        )}
        <S.CloseText onClick={onClose}>{closeText}</S.CloseText>
        <S.FooterText>{footerText}</S.FooterText>
      </S.Content>
    </S.ModalStyled>
  );
};
