import * as React from 'react'

// This is not a perfect replacement for Next.js pathname because it does not update on route changes; it is only to be used as a fallback for WordPress where there's no clientside navigation
export function usePathname() {
  const [pathname, setPathname] = React.useState('')

  React.useEffect(() => {
    const pathname = window.location.pathname
    setPathname(pathname)
  }, [])

  return pathname.endsWith('/') ? pathname : pathname + '/'
}
