import * as NavigationMenu from 'primitives/navigation-menu'

import { NavigationItem } from './navigation-item'
import * as MenuItem from '../menu-item'
import { UserAvatar } from '../avatar'
import { useTranslations } from '../../contexts/header-config-context'
import { useUserData } from '../../contexts/auth'
import { NavItem } from '../../hooks/use-nav-items'

export function UserMenu() {
  const userData = useUserData()
  const t = useTranslations()

  if (!userData) {
    return null
  }

  const menuItems: NavItem = {
    name: 'user',
    link: '/masterfeed/',
    subMenuItems: [
      {
        name: t('account.my_account'),
        link: '/my-account/',
      },
      {
        name: t('account.profile'),
        link: `/crafter/${userData.slug}/`,
      },
      {
        name: t('account.downloads'),
        link: '/my-account/license-keys-overview/',
      },
      {
        name: t('account.refer'),
        link: '/referrals/',
      },
      {
        name: t('account.product_feed'),
        link: '/following/',
      },
      {
        name: t('account.store_credit'),
        link: '/store-credit/',
      },
      {
        name: t('account.affiliate_dashboard'),
        link: '/affiliate-area/?tab=urls',
      },
    ],
  }

  return (
    <NavigationItem
      key="user"
      trigger={
        <NavigationMenu.Trigger className="focus:bg-cf-lavender">
          <MenuItem.Link href="/masterfeed/" className="flex-col gap-0.5">
            <UserAvatar />
            <span className="grow whitespace-nowrap">{t('top_nav.account')}</span>
          </MenuItem.Link>
        </NavigationMenu.Trigger>
      }
      className="pb-4"
      item={menuItems}
    />
  )
}
