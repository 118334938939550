import React from 'react';
import { Container } from '@mui/material';

import { useFormatMessage } from 'utilities/i18n';
import { Link } from 'components/elements/atoms/link/Link';

import * as S from './SimpleFooter.styles';

export const SimpleFooter = () => {
  const t = useFormatMessage();

  return (
    <S.FooterRoot>
      <Container maxWidth="xl">
        <S.GridColumns container rowSpacing={2}>
          <S.GridLeftColumn item xs={12} md={6}>
            <S.TypographyHeading component="h3">{t('footer.heed_help')}</S.TypographyHeading>
            <S.TypographyText>{t('footer.we_are_here')}</S.TypographyText>
            <S.TypographyText>
              {t('footer.mail_us')}{' '}
              <Link href="mailto:hi@creativefabrica.com" color="secondary" underline="none">
                hi@creativefabrica.com
              </Link>
            </S.TypographyText>
          </S.GridLeftColumn>
          <S.GridRightColumn item xs={12} md={6}>
            <S.TypographyHeading component="h3">{t('footer.secure_checkout')}</S.TypographyHeading>
            <S.TypographyText>{t('footer.your_checkout')}</S.TypographyText>
            <S.BoxPayments>
              <S.ImageStyled
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/powered-by-stripe.png`}
                alt="Stripe"
                width={150}
                height={33}
              />
              <S.ImageStyled
                src={`${process.env.NEXT_PUBLIC_IMAGE_URL}/pp-secure-checkout.png`}
                alt="Paypal"
                width={150}
                height={60}
              />
            </S.BoxPayments>
          </S.GridRightColumn>
        </S.GridColumns>
        <S.TypographyCopy>
          {t('footer.copy', {
            values: {
              currentYear: new Date().getFullYear(),
            },
          })}
        </S.TypographyCopy>
      </Container>
    </S.FooterRoot>
  );
};
