import { useState } from 'react';

import { AuthModalProps, AuthModalScenario } from 'components/elements/organisms/authModal/AuthModal.types';
import { AuthModalLocations } from 'components/app/analytics/amplitude/Amplitude.types';
import {
  analyticsTrackLoginModalOpen,
  analyticsTrackRegistrationModalOpen,
} from 'components/app/analytics/cfTracking/CfTracking.utils';

export function useAuthModalState() {
  const [isAuthModalOpened, setAuthModalOpened] = useState(false);
  const [authModalScenario, setAuthModalScenario] = useState(AuthModalScenario.LOGIN);

  const handleLoginClick = (location?: AuthModalLocations) => {
    setAuthModalScenario(AuthModalScenario.LOGIN);
    setAuthModalOpened(true);
    analyticsTrackLoginModalOpen(location);
  };

  const handleSignUpClick = (location?: AuthModalLocations) => {
    setAuthModalScenario(AuthModalScenario.REGISTER);
    setAuthModalOpened(true);
    analyticsTrackRegistrationModalOpen(location);
  };

  return {
    handleLoginClick,
    handleSignUpClick,
    getAuthModalProps(props?: Partial<AuthModalProps>) {
      return {
        scenario: authModalScenario,
        isOpened: isAuthModalOpened,
        ...props,
        onClose: () => {
          props?.onClose?.();
          setAuthModalOpened(false);
        },
      };
    },
  };
}
