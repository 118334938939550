import { tw } from 'utils/classnames'

import { useHeaderContext } from '../contexts/header-config-context'
import { DesktopHeader } from './desktop'
import { MobileHeader } from './mobile'
import { useUserLanguageRedirect } from '../hooks/use-user-language-redirect'
import { MenuForSeo } from './nav-for-seo'

interface TopHeaderProps {
  className?: string
}

export const Header = ({ className }: TopHeaderProps) => {
  useUserLanguageRedirect()
  const { lang, activeCurrency } = useHeaderContext()

  return (
    <header className={tw('bg-cf-white relative z-[2]', className)}>
      {/* Hidden input to store the active language and currency, needed by wordpress */}
      <input id="active-language" type="hidden" name="language" value={lang} />
      <input
        id="active-currency"
        type="hidden"
        name="currency"
        value={activeCurrency?.code ?? ''}
      />
      <DesktopHeader />
      <MobileHeader />
      <MenuForSeo />
    </header>
  )
}
