import tinycolor from 'tinycolor2';

const hueRange = 5;
const subRange = 50;

function rgbToHSV({ r, g, b }: { r: number; g: number; b: number }) {
  r /= 255.0;
  g /= 255.0;
  b /= 255.0;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);
  const diff = max - min;
  let h = 0;

  if (max === min) {
    h = 0;
  } else if (max === r) {
    h = (60 * ((g - b) / diff) + 360) % 360;
  } else if (max === g) {
    h = (60 * ((b - r) / diff) + 120) % 360;
  } else if (max === b) {
    h = (60 * ((r - g) / diff) + 240) % 360;
  }

  const s = max === 0 ? 0 : (diff / max) * 100;
  const v = max * 100;

  return { h: Math.round(h), s: Math.round(s), v: Math.round(v) };
}

function hsvToInt({ h, s, v }: { h: number; s: number; v: number }) {
  // Scale HSV values
  const H = h;
  const S = Math.round((s * 255) / 100);
  const V = Math.round((v * 255) / 100);

  // Combine into a single integer
  return H * 256 * 256 + S * 256 + V;
}

export const buildAlgoliaColorFilter = (color: string | undefined) => {
  let colorFilter = '';

  if (Boolean(color)) {
    const colorRGB = tinycolor(color).toRgb();
    const colorHSV = rgbToHSV(colorRGB);

    colorFilter += '(';

    // Range in the hue spectrum
    for (let i = hueRange * -1; i < hueRange; i++) {
      const colorHSVMin = {
        h: colorHSV.h + i,
        s: Math.max(0, colorHSV.s - subRange),
        v: Math.max(0, colorHSV.v - subRange),
      };
      const colorHSVMax = {
        h: colorHSV.h + i,
        s: Math.min(100, colorHSV.s + subRange),
        v: Math.min(100, colorHSV.v + subRange),
      };

      colorFilter += `colors.dominant_hsv_quantized.4:${hsvToInt(colorHSVMin)} TO ${hsvToInt(
        colorHSVMax,
      )} OR colors.dominant_hsv_quantized.8:${hsvToInt(colorHSVMin)} TO ${hsvToInt(
        colorHSVMax,
      )} OR colors.dominant_hsv_quantized.16:${hsvToInt(colorHSVMin)} TO ${hsvToInt(colorHSVMax)} OR `;
    }

    // Remove trailing OR
    colorFilter = colorFilter.slice(0, -4);

    // Close color filter
    colorFilter += ')';
  }

  return colorFilter.replace(/\n/gi, '');
};
