import { usePopper } from 'react-popper'

export function useCollisionDetection(
  relativeElement: HTMLElement | null,
  absoluteElement: HTMLElement | null,
) {
  const { styles } = usePopper(relativeElement, absoluteElement, {
    placement: 'bottom-start',
    strategy: 'absolute',
  })
  const popperStyles = styles.popper

  return popperStyles
}
