import * as NavigationMenu from 'primitives/navigation-menu'
import * as React from 'react'

import {
  useHeaderContext,
  useOnCurrencyChange,
  useOnCurrencyOpen,
  useTranslations,
} from '../../contexts/header-config-context'
import { getCurrencies } from '../../utils/currencies'
import { CheckIcon } from '../icons/check-icon'

export function CurrencySwitcher({ setIsMenuOpen }: { setIsMenuOpen: (value: boolean) => void }) {
  const { activeCurrency } = useHeaderContext()
  const currencies = getCurrencies()
  const t = useTranslations()
  const onCurrencyOpen = useOnCurrencyOpen()
  const onCurrencyChange = useOnCurrencyChange()

  React.useEffect(() => {
    onCurrencyOpen?.()
  }, [onCurrencyOpen])

  if (!activeCurrency) {
    return null
  }

  return (
    <>
      <li className="p-4 text-lg font-medium">{t('language_currency.currency')}</li>
      {currencies.map(currency => (
        <NavigationMenu.Item
          key={currency.code}
          className="flex w-full cursor-pointer items-center justify-between gap-4 p-4 py-2 text-left"
          onClick={() => {
            onCurrencyChange(currency.code!)
            setIsMenuOpen(false)
          }}
          asChild
        >
          <button>
            <div className="flex h-5 w-5 items-center justify-center text-lg font-medium">
              {currency.symbol}
            </div>
            <span className="text-16 grow leading-6">{currency.code?.toUpperCase()}</span>
            {activeCurrency.code === currency.code ? (
              <CheckIcon className="text-cf-dark-aubergine h-4" />
            ) : null}
          </button>
        </NavigationMenu.Item>
      ))}
    </>
  )
}
