import { Currency, CurrencyCode } from 'codegen/types'

const currencyData: Currency[] = [
  {
    code: CurrencyCode.Usd,
    symbol: '$',
  },
  {
    code: CurrencyCode.Eur,
    symbol: '€',
  },
  {
    code: CurrencyCode.Gbp,
    symbol: '£',
  },
]

export const getCurrencies = (): Currency[] => {
  return currencyData
}

export function currencyCodeToCurrency(value?: string) {
  return (
    currencyData.find(currency => currency.code === value?.toLowerCase()) ?? {
      code: CurrencyCode.Usd,
      symbol: '$',
    }
  )
}
