import classNames from 'classnames';
import { useEffect } from 'react';
import { useRefinementList, useInstantSearch } from 'react-instantsearch';

import { useFormatMessage } from 'utilities/i18n';
import { Checkbox } from 'components/elements/atoms/checkbox/Checkbox';
import { Divider } from 'components/elements/atoms/divider/Divider';
import { Accordion } from 'components/elements/molecules/accordion/AccordionContainer';

import { SidebarFilterProps } from './SidebarFilter.types';

export function SidebarFilter(props: SidebarFilterProps) {
  const {
    header,
    isExpanded = true,
    showMore = true,
    limit = 20,
    showMoreLimit = 999,
    isSingleChoice = false,
    children,
    selectAll = false,
    hideEndDivider = false,
    // TODO: Remove this prop when we remove the old search
    searchV2 = false,
    ...useRefinementListProps
  } = props;
  const t = useFormatMessage();
  const refinementListApi = useRefinementList({
    showMore,
    showMoreLimit,
    limit,
    sortBy: searchV2 ? ['count:desc'] : undefined,
    ...useRefinementListProps,
  });
  const { setIndexUiState } = useInstantSearch();

  const { items, refine, canToggleShowMore, toggleShowMore, isShowingMore } = refinementListApi;

  useEffect(() => {
    if (selectAll) {
      const itemsToRefine = items.map(item => item.value);

      setIndexUiState(prevState => {
        return {
          ...prevState,
          refinementList: {
            ...prevState.refinementList,
            [useRefinementListProps.attribute]: itemsToRefine,
          },
        };
      });
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!items.length && !children) {
    return null;
  }

  const handleRefine = (value: string) => {
    if (isSingleChoice) {
      items?.forEach(item => {
        if (item.value !== value && item.isRefined) {
          refine(item.value);
        }
      });
    }

    refine(value);
  };

  const filterContent = (
    <div className="mt-5 flex flex-col">
      {items.map(
        (item, index) =>
          ((limit && index < limit) || isShowingMore) && (
            <div key={item.label + index} className="wrap flex items-center">
              <Checkbox
                searchV2={searchV2}
                label={
                  <>
                    <span className={classNames(item.isRefined && !searchV2 && 'font-semibold', 'capitalize')}>
                      {item.label}
                    </span>
                    <span className="ml-[5px] text-xs text-gray-500">({item.count})</span>
                  </>
                }
                checked={item.isRefined}
                onClick={() => handleRefine(item.value)}
              />
            </div>
          ),
      )}
    </div>
  );

  return (
    <div>
      <Accordion header={header} isExpanded={isExpanded}>
        <div className="flex flex-col gap-[10px]">
          {children ? children({ items, handleRefine }) : filterContent}
          {showMore && limit && canToggleShowMore && (
            <span
              className="cursor-pointer text-xs text-gray-500"
              onClick={() => {
                toggleShowMore();
              }}
            >
              {isShowingMore ? t('search.show_less') : t('search.show_more')}
            </span>
          )}
        </div>
      </Accordion>
      {!hideEndDivider ? <Divider className="relative left-[-20px] mt-5 w-[calc(100%+40px)]" /> : null}
    </div>
  );
}
