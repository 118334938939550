import { styled } from '@mui/material';
import { Checkbox as MuiCheckbox, FormControlLabel } from '@mui/material';

export const Checkbox = styled(MuiCheckbox)(({ theme }) => ({
  color: theme.palette.grayPurple.light,
  '&:hover': {
    backgroundColor: 'transparent',
  },
  '&.Mui-checked': {
    color: theme.palette.black.main,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  '&.MuiCheckbox-colorError': {
    color: theme.palette.red.dark,
  },
  '&.Mui-disabled': {
    color: theme.palette.gray.main,
  },
  '& .icon': {
    width: theme.spacing(1.875),
    height: theme.spacing(1.875),
    border: 'solid 2px currentColor',
  },
  '& .MuiSvgIcon-root': {
    display: 'none',
  },
  '&.MuiIconButton-edgeStart': {
    marginLeft: theme.spacing(-1.125),
  },
  '&.MuiIconButton-edgeEnd': {
    marginRight: theme.spacing(-1.125),
  },
}));

export const Label = styled(FormControlLabel)(({ theme }) => ({
  alignItems: 'flex-start',
  marginRight: theme.spacing(2.5),
  marginLeft: theme.spacing(-1.125),
  color: theme.palette.black.main,
  '&:hover .MuiCheckbox-root': {
    color: theme.palette.black.main,
  },
  '&.Mui-disabled': {
    color: theme.palette.gray.main,
    '&:hover .MuiCheckbox-root': {
      color: theme.palette.gray.main,
    },
  },
  '& > .MuiTypography-root': {
    marginTop: theme.spacing(0.75),
  },
}));
