export function ChevronRightIcon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      id="img"
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.18005 11.97L11.1701 7.97997L7.18005 3.98997"
        stroke="currentColor"
        strokeWidth="1.33"
        strokeLinecap="round"
      />
    </svg>
  )
}
