import type { Language } from 'codegen/types'
import * as React from 'react'
import { useCookies } from 'react-cookie'

import { validateLocale } from './helpers'

export const LANGUAGE_COOKIE_NAME = 'lang'

const env = process.env.NEXT_PUBLIC_ENV || process.env.VITE_PUBLIC_ENV
const isPreview = env !== 'staging' && env !== 'production'
const domain = isPreview ? undefined : '.creativefabrica.com'
const path = '/'

export function useLangCookie() {
  const [cookies, setCookie, removeCookie] = useCookies([LANGUAGE_COOKIE_NAME])

  const langCookie = validateLocale(cookies[LANGUAGE_COOKIE_NAME])
    ? cookies[LANGUAGE_COOKIE_NAME]
    : null

  const setLangCookie = React.useCallback(
    (lang: Language) => {
      // Fix to prevent infinite redirects due to cookie set on an unexpected domain from wordpress
      removeCookie(LANGUAGE_COOKIE_NAME, { path })
      removeCookie(LANGUAGE_COOKIE_NAME, { path, domain: 'staging.creativefabrica.com' })
      // End-fix

      setCookie(LANGUAGE_COOKIE_NAME, lang, { domain, path })
    },
    [setCookie, removeCookie],
  )

  const removeLangCookie = React.useCallback(() => {
    removeCookie(LANGUAGE_COOKIE_NAME, { domain, path })
  }, [removeCookie])

  return [langCookie, setLangCookie, removeLangCookie] as const
}
