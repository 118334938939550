import * as React from 'react'
import { Currency, CurrencyCode, Language } from 'codegen/types'
import { defaultLanguage } from 'cf-i18n/constants'

import { Key } from '../i18n'
import { useRouter } from '../hooks/use-router'
import { usePathname } from '../hooks/use-path-name'
import { dynamic } from './dynamic'

export interface HeaderData {
  lang: Language
  i18n: (key: Key) => string
  activeCurrency: Currency
  onLoginClick: () => void
  onSignUpClick: () => void
  onLogoutClick: () => void
  onCurrencyChange: (currency: CurrencyCode) => void
  onLanguageChange: (language: Language) => void
  onLanguageOpen?: () => void
  onCurrencyOpen?: () => void
  utils?: {
    Link: React.ElementType
    Image: React.ElementType
    router: {
      push: (url: string) => void
      pathname: string
    }
    dynamic?: typeof dynamic
  }
}

type ContextProps = React.PropsWithChildren<HeaderData>

const HeaderContext = React.createContext<HeaderData>(null!)

export function HeaderContextProvider({ children, activeCurrency, ...rest }: ContextProps) {
  return (
    <HeaderContext.Provider
      value={{
        activeCurrency: activeCurrency,
        ...rest,
      }}
    >
      {React.useMemo(() => children, [children])}
    </HeaderContext.Provider>
  )
}

export function useHeaderContext() {
  return React.useContext(HeaderContext)
}

export function useLocale() {
  return useHeaderContext().lang
}

export function useActiveCurrency() {
  return useHeaderContext().activeCurrency
}

export function useTranslations() {
  return useHeaderContext().i18n
}

function LinkWithSameRouteReloading(
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const { utils } = useHeaderContext()

  if (props.href === utils?.router.pathname) {
    return <LanguageAwareLink ref={ref} {...props} />
  }

  const Component = utils?.Link || LanguageAwareLink

  if (props.href?.startsWith('http')) {
    return <Component ref={ref} target="_blank" {...props} />
  }

  return <Component ref={ref} {...props} />
}

const LinkWithSameRouteReloadingRefed = React.forwardRef(LinkWithSameRouteReloading)

export function useLinkComponent() {
  return LinkWithSameRouteReloadingRefed
}

export function useImageComponent() {
  const { utils } = useHeaderContext()

  const Component = utils?.Image || 'img'

  return Component
}

export function useRouterUtil() {
  const { utils } = useHeaderContext()
  const { push } = useRouter()
  const pathname = usePathname()

  const router = utils?.router || {
    push,
    pathname,
  }

  return router
}

export function useOnLoginClick() {
  return useHeaderContext().onLoginClick
}

export function useOnSignUpClick() {
  return useHeaderContext().onSignUpClick
}

export function useOnLogoutClick() {
  return useHeaderContext().onLogoutClick
}

export function useOnLanguageOpen() {
  return useHeaderContext().onLanguageOpen
}

export function useOnCurrencyOpen() {
  return useHeaderContext().onCurrencyOpen
}

export function useOnCurrencyChange() {
  return useHeaderContext().onCurrencyChange
}

export function useOnLanguageChange() {
  return useHeaderContext().onLanguageChange
}

export function useDynamicLoader() {
  return useHeaderContext().utils?.dynamic
}

function DefaultLink(
  props: React.AnchorHTMLAttributes<HTMLAnchorElement>,
  ref: React.Ref<HTMLAnchorElement>,
) {
  const locale = useLocale()
  const { href, ...rest } = props

  if (locale === defaultLanguage || href?.startsWith('http')) {
    return <a ref={ref} href={href} {...rest} />
  }

  return <a ref={ref} {...props} href={`/${locale}${href}`} hrefLang={locale} />
}
export const LanguageAwareLink = React.forwardRef(DefaultLink)
