import { Language } from 'codegen/types'

export enum AlgoliaLanguage {
  EN = 'LANGUAGE_EN',
  FR = 'LANGUAGE_FR',
  DE = 'LANGUAGE_DE',
  ES = 'LANGUAGE_ES',
  IT = 'LANGUAGE_IT',
  PT = 'LANGUAGE_PT_BR',
  NL = 'LANGUAGE_NL',
  PL = 'LANGUAGE_PL',
}

export const mapLocaleToContentLanguage = new Map<Language, AlgoliaLanguage>([
  [Language.En, AlgoliaLanguage.EN],
  [Language.Es, AlgoliaLanguage.ES],
  [Language.De, AlgoliaLanguage.DE],
  [Language.Fr, AlgoliaLanguage.FR],
  [Language.It, AlgoliaLanguage.IT],
  [Language.Nl, AlgoliaLanguage.NL],
  [Language.Pt, AlgoliaLanguage.PT],
  [Language.Pl, AlgoliaLanguage.PL],
])

export const DEFAULT_ALGOLIA_LANGUAGE = AlgoliaLanguage.EN

export const MIN_REFINEMENT_LENGTH = 3

export const contentAlgoliaAppId = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_APP_ID!
export const contentAlgoliaApiKey = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_API_KEY!
export const contentAlgoliaIndex = process.env.NEXT_PUBLIC_CONTENT_ALGOLIA_INDEX!

export const productsAlgoliaAppId = process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!
export const productsAlgoliaApiKey = process.env.NEXT_PUBLIC_ALGOLIA_API_KEY!
export const productsAlgoliaIndex = process.env.NEXT_PUBLIC_ALGOLIA_INDEX!
