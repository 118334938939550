'use client'

import * as RadixTabs from '@radix-ui/react-tabs'
import * as React from 'react'
import { tw } from 'utils/classnames'

// eslint-disable-next-line no-barrel-files/no-barrel-files
export { List, Trigger, Root } from '@radix-ui/react-tabs'

export function Content({
  children,
  ...props
}: React.ComponentProps<typeof RadixTabs.TabsContent>) {
  return (
    <RadixTabs.Content
      {...props}
      className={tw('outline-none data-[state=inactive]:hidden', props.className)}
    >
      {children}
    </RadixTabs.Content>
  )
}
