import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import { tw } from 'utils/classnames'

import { useLinkComponent } from '../../contexts/header-config-context'
import { NavItem } from '../../hooks/use-nav-items'
import { ScrollableContainer } from './main-navigation/scrollable-container'
import { useCollisionDetection } from '../../hooks/use-collision-detection'

export function NavigationItem({
  item,
  trigger,
  className,
}: {
  item: NavItem
  trigger: React.ReactNode
  className?: string
}) {
  const Link = useLinkComponent()
  const [referenceElement, setReferenceElement] = React.useState<HTMLLIElement | null>(null)
  const [absoluteElement, setAbsoluteElement] = React.useState<HTMLDivElement | null>(null)
  const style = useCollisionDetection(referenceElement, absoluteElement)

  return (
    <NavigationMenu.Item ref={setReferenceElement}>
      {trigger}
      {item.subMenuItems?.length ? (
        <NavigationMenu.Content
          className="z-cf-menu absolute"
          style={style}
          ref={setAbsoluteElement}
        >
          <ScrollableContainer
            className={tw(
              'bg-cf-white shadow-cf-elevation-light-5 rounded-2 relative pl-2 pr-1',
              className,
            )}
          >
            <NavigationMenu.List className="relative flex flex-col">
              {item.subMenuItems.map((subItem, index2) => {
                return (
                  <li key={`${subItem.id}_${index2}`}>
                    <NavigationMenu.Link
                      asChild
                      className="hover:bg-cf-lavender focus:bg-cf-lavender text-14 relative inline-block w-full select-none items-center whitespace-nowrap rounded-full py-2 pl-4 pr-16 outline-none"
                    >
                      <Link href={subItem.link}>{subItem.name}</Link>
                    </NavigationMenu.Link>
                  </li>
                )
              })}
            </NavigationMenu.List>
          </ScrollableContainer>
        </NavigationMenu.Content>
      ) : null}
    </NavigationMenu.Item>
  )
}
