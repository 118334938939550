import { useCallback, createContext, useState, useContext, useEffect } from 'react';
import { useRouter } from 'next/router';
import algoliasearch from 'algoliasearch/lite';
import algoliaAnalytics from 'search-insights';
import { InstantSearch, useInstantSearch } from 'react-instantsearch';
import { createInsightsMiddleware } from 'instantsearch.js/es/middlewares';

import {
  getSearchState,
  searchStateToQuery,
} from 'components/pages/stockphotos/pages/stockPhotosSearchPage/StockPhotosSearchPage.utils';
import { useLocale } from 'hooks/useLocale/useLocale';
import { unArray } from 'utilities/js';
import { useGetContentCategoriesQuery, Language } from 'generated/graphql';
import { STOCKPHOTOS_PARENT_ID } from 'components/pages/stockphotos/pages/config';

import {
  contentAlgoliaAppId as algoliaAppId,
  contentAlgoliaApiKey as algoliaApiKey,
  contentAlgoliaIndex as algoliaIndex,
} from './InstantSearchProvider.utils';

export const searchClient = algoliasearch(algoliaAppId, algoliaApiKey);

function InsightsMiddleware() {
  const { addMiddlewares } = useInstantSearch();

  useEffect(() => {
    const middleware = createInsightsMiddleware({
      insightsClient: algoliaAnalytics,
    });

    return addMiddlewares(middleware);
  }, [addMiddlewares]);

  return null;
}

export const StockphotosSearchPageContext = createContext<{
  color: string | undefined;
  updateColor: (newColor: string | undefined) => void;
}>(null!);

export function useStockphotosSearchPageContext() {
  const context = useContext(StockphotosSearchPageContext);

  if (!context) {
    throw new Error('useSearchPageContext must be used within a SearchPageContext');
  }

  return context;
}

export const SearchPageProvider: React.FC<{ initialQuery: string }> = ({ children, initialQuery }) => {
  const { algoliaLang, locale } = useLocale();
  const router = useRouter();
  const queryColor = unArray(router.query?.color);
  const [color, setColor] = useState<string | undefined>(queryColor ? decodeURIComponent(queryColor) : queryColor);

  const { data } = useGetContentCategoriesQuery({ parentId: STOCKPHOTOS_PARENT_ID, locale: locale as Language });

  const normalizeCategories: Record<string, { id: string; name: string; slug: string }> = {};

  data?.listContentCategories?.forEach(element => {
    normalizeCategories[element.id] = element;
  });

  const searchState = getSearchState({ url: initialQuery, lang: algoliaLang, categories: normalizeCategories });

  const handleStateChange = useCallback(
    ({ uiState, setUiState }) => {
      const query = searchStateToQuery(
        {
          uiState,
          other: {
            expanded: router.query?.expanded ? router.query?.expanded : '',
            color: color,
          },
        },
        algoliaLang,
        normalizeCategories,
      );
      const newState = getSearchState({ url: query, lang: algoliaLang, categories: normalizeCategories });

      setUiState(newState.uiState);

      router.push({ query: query }, undefined, {
        scroll: false,
        shallow: true,
      });
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [router, algoliaLang, color],
  );

  const handleColorChange = (newColor: string | undefined) => {
    setColor(newColor);
  };

  return (
    <InstantSearch
      initialUiState={searchState.uiState}
      onStateChange={handleStateChange}
      searchClient={searchClient}
      indexName={algoliaIndex}
    >
      <StockphotosSearchPageContext.Provider value={{ color: color, updateColor: handleColorChange }}>
        {children}
      </StockphotosSearchPageContext.Provider>
      <InsightsMiddleware />
    </InstantSearch>
  );
};
