export type ColorScheme = {
  textColor: string
  textHoverColor: string
  backgroundColor: string
  backgroundHoverColor: string
  borderColor: string
}

export const getColorScheme = (colors?: Partial<ColorScheme>): ColorScheme => ({
  textColor: colors?.textColor || '#261d35', // Default cf-dark-aubergine
  textHoverColor: colors?.textHoverColor || '#432981', // Default cf-violet
  backgroundColor: colors?.backgroundColor || '#e5def5', // Default cf-lavender
  backgroundHoverColor: colors?.backgroundHoverColor || '#e5def5', // Default cf-lavender
  borderColor: colors?.borderColor || '#432981', // Default cf-violet
})
