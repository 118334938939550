import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'
import * as Tabs from 'primitives/tabs'
import { tw } from 'utils/classnames'

import {
  useHeaderContext,
  useOnCurrencyOpen,
  useOnLanguageOpen,
  useTranslations,
} from '../../../contexts/header-config-context'
import { useIsAuthenticated } from '../../../contexts/auth'
import { LanguageItems } from './language-items'
import { CurrencyItems } from './currency-items'
import { Globe01Icon } from '../../icons/globe-01-icon'
import { ChevronDownIcon } from '../../icons/chevron-down-icon'

interface LanguageSwitcherProps {
  classNames?: string
  forceMount?: boolean
}

export function LanguageSwitcher({ classNames, forceMount }: LanguageSwitcherProps) {
  const [openTab, setOpenTab] = React.useState<'language' | 'currency' | undefined>()
  const { lang } = useHeaderContext()
  const isLoggedIn = useIsAuthenticated()
  const t = useTranslations()
  const onLanguageOpen = useOnLanguageOpen()
  const onCurrencyOpen = useOnCurrencyOpen()

  React.useEffect(() => {
    openTab === 'language' && onLanguageOpen?.()
    openTab === 'currency' && onCurrencyOpen?.()
  }, [openTab, onLanguageOpen, onCurrencyOpen])

  return (
    <NavigationMenu.Item>
      <NavigationMenu.Trigger
        className={tw(
          'ml-1 px-4 py-2',
          isLoggedIn && 'flex-col gap-0.5',
          'hover:bg-cf-lavender focus:bg-cf-lavender flex items-center rounded-[8px] text-[11px]',
          classNames,
        )}
      >
        <Globe01Icon className={tw('text-cf-dreamy-cloud-900 h-5', !isLoggedIn && 'mr-2')} />
        <span className="uppercase">{lang}</span>
        {!isLoggedIn ? (
          <ChevronDownIcon className="h-3.4 text-cf-dreamy-cloud-900 ml-1 w-3.5" />
        ) : null}
      </NavigationMenu.Trigger>
      <NavigationMenu.Content
        className={tw(
          'bg-cf-white shadow-cf-elevation-light-5 rounded-2 z-cf-menu absolute min-w-[220px] rounded-md pb-4 pt-[5px]',
          forceMount && 'cf-hidden data-[state=open]:block',
        )}
        {...(forceMount ? { forceMount: true } : {})} // if we always forceMount here sibling menu items that that dropdowns will stop working (the dropdown will be hidden before the click happens)
      >
        <Tabs.Root
          className="text-14 flex w-[244px] flex-col"
          defaultValue={'language'}
          onValueChange={value => {
            setOpenTab(value as 'language' | 'currency')
          }}
        >
          <Tabs.List className="bg-cf-white flex shrink-0" aria-label="Manage your account">
            <Tabs.Trigger
              className="data-[state=active]:border-cf-violet flex h-[48px] flex-1 cursor-pointer select-none items-center justify-center border-b-4 border-transparent px-5"
              value="language"
            >
              {t('language_currency.language')}
            </Tabs.Trigger>
            <Tabs.Trigger
              className="data-[state=active]:border-cf-violet flex h-[48px] flex-1 cursor-pointer select-none items-center justify-center border-b-4 border-transparent px-5"
              value="currency"
            >
              {t('language_currency.currency')}
            </Tabs.Trigger>
          </Tabs.List>
          <Tabs.Content className="grow" value="language">
            <LanguageItems />
          </Tabs.Content>
          <Tabs.Content className="grow" value="currency">
            <CurrencyItems />
          </Tabs.Content>
        </Tabs.Root>
      </NavigationMenu.Content>
    </NavigationMenu.Item>
  )
}
