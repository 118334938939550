import { Highlight } from 'react-instantsearch'
import { Hit } from 'instantsearch.js/es/types'

import { Image } from '../image'
import { SearchProduct } from './search.types'
import { useLinkComponent } from '../../contexts/header-config-context'

interface ResultsProps {
  href: string
  hit: Hit<SearchProduct>
}

export function Results(props: ResultsProps) {
  const { href, hit } = props
  const Link = useLinkComponent()

  return (
    <Link href={href} color="inherit">
      <div className="hover:bg-cf-lavender bg-cf-white flex w-full items-center gap-4 rounded-full px-4 py-2">
        <Image
          src={hit.image}
          width={100}
          height={30}
          alt={hit.name_en || ''}
          imageClassName="h-full w-full object-cover overflow-hidden"
        />
        <span className="text-xs">
          <Highlight
            attribute="name_en"
            hit={hit}
            highlightedTagName="span"
            nonHighlightedTagName="span"
            className="text-xs"
            classNames={{ highlighted: 'font-bold' }}
          />
          <span>{` - by ${hit.designer.designerName}`}</span>
        </span>
      </div>
    </Link>
  )
}
