import algoliasearch from 'algoliasearch/lite'

import { contentAlgoliaApiKey, contentAlgoliaAppId, contentAlgoliaIndex } from './algolia.configs'
import { AlgoliaContentHit } from './search.types'

const PNG_AUTHOR_ID = 'pngs'
const STOCKPHOTOS_AUTHOR_ID = 'stockphotos'
const KAWAII_AUTHOR_ID = 'kawaii'
const MOCKUPS_AUTHOR_ID = 'mockups'
const BACKGROUNDS_AUTHOR_ID = 'backgrounds'
const SKETCHES_AUTHOR_ID = 'sketches'
const PATTERNS_AUTHOR_ID = 'patterns'
const BORDERS_AND_CORNERS_AUTHOR_ID = 'borders-and-corners'
const FRAMES_AUTHOR_ID = 'frames'
const CARTOONS_AUTHOR_ID = 'cartoons'
const TEXTURES_AUTHOR_ID = 'textures'
const SILHOUETTES_AUTHOR_ID = 'silhouettes'
const DRAWINGS_AUTHOR_ID = 'drawings'
const CLIP_ART_AUTHOR_ID = 'clip-art'
const QUOTES_AUTHOR_ID = 'quotes'
const STICKERS_AUTHOR_ID = 'stickers'
const SVGS_AUTHOR_ID = 'svgs'
const COLORING_PAGES_AUTHOR_ID = 'coloring-pages'

const ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS = [
  PNG_AUTHOR_ID,
  STOCKPHOTOS_AUTHOR_ID,
  KAWAII_AUTHOR_ID,
  MOCKUPS_AUTHOR_ID,
  BACKGROUNDS_AUTHOR_ID,
  SKETCHES_AUTHOR_ID,
  PATTERNS_AUTHOR_ID,
  BORDERS_AND_CORNERS_AUTHOR_ID,
  FRAMES_AUTHOR_ID,
  CARTOONS_AUTHOR_ID,
  TEXTURES_AUTHOR_ID,
  SILHOUETTES_AUTHOR_ID,
  DRAWINGS_AUTHOR_ID,
  CLIP_ART_AUTHOR_ID,
  QUOTES_AUTHOR_ID,
  STICKERS_AUTHOR_ID,
  SVGS_AUTHOR_ID,
  COLORING_PAGES_AUTHOR_ID,
]
const contentFilter = ALLOWED_CONTENT_PRODUCTS_AUTHOR_IDS.map(id => `authorId:${id}`).join(' OR ')

const searchClient = algoliasearch(contentAlgoliaAppId, contentAlgoliaApiKey)
const contentIndex = searchClient.initIndex(contentAlgoliaIndex)

export const fetchContentData = async (query = '', page: number, hitsPerPage = 23) => {
  // fetching content data for new query
  const contentRequests = {
    hitsPerPage: hitsPerPage,
    maxValuesPerFacet: 999,
    filters: contentFilter,
    optionalFilters: '',
    page: page,
    query: query,
    facets: [],
    facetFilters: [],
    tagFilters: [],
    highlightPostTag: '</span>',
    highlightPreTag: "<span style='font-weight: 900;'>",
  }
  const contentData = await contentIndex.search<AlgoliaContentHit>(query, {
    ...contentRequests,
    headers: {
      accept: 'application/json',
    },
  })

  return contentData
}
