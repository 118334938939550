export function Globe01Icon(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.66602 10.0001H18.3327M1.66602 10.0001C1.66602 14.6025 5.39698 18.3334 9.99935 18.3334M1.66602 10.0001C1.66602 5.39771 5.39698 1.66675 9.99935 1.66675M18.3327 10.0001C18.3327 14.6025 14.6017 18.3334 9.99935 18.3334M18.3327 10.0001C18.3327 5.39771 14.6017 1.66675 9.99935 1.66675M9.99935 1.66675C12.0837 3.94871 13.2683 6.91011 13.3327 10.0001C13.2683 13.0901 12.0837 16.0515 9.99935 18.3334M9.99935 1.66675C7.91495 3.94871 6.73039 6.91011 6.66602 10.0001C6.73039 13.0901 7.91495 16.0515 9.99935 18.3334"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
