import * as React from 'react'
import * as Dialog from 'primitives/dialog'
import * as NavigationMenu from 'primitives/navigation-menu'
import { m, useAnimationControls, LazyMotion, domAnimation } from 'framer-motion'
import { tw } from 'utils/classnames'

import * as MenuItems from '../menu-item'
import { useMenuItems, MenuItemType, MenuItem, MenuGroup } from '../../hooks/use-menu-items'
import { useLinkComponent, useTranslations } from '../../contexts/header-config-context'
import { LanguageSwitcher } from './language-switcher'
import { CurrencySwitcher } from './currency-switcher'
import { Menu01Icon } from '../icons/menu-01-icon'
import { ChevronLeftIcon } from '../icons/chevron-left-icon'
import { XCloseIcon } from '../icons/x-close-icon'
import { ChevronRightIcon } from '../icons/chevron-right-icon'
import { useIsIpad } from '../../hooks/use-is-ipad'
import { ArrowNarrowUpRight } from '../icons/arrow-narrow-up-right'

const overlayVariants = {
  expanded: { opacity: 1, transition: { duration: 0.15 } },
  collapsed: { opacity: 0, transition: { duration: 0.15 } },
}

const contentVariants = {
  expanded: {
    x: [-50, 0],
    opacity: [0, 1],
    transition: { ease: 'easeOut', duration: 0.2 },
  },
  collapsed: {
    x: [0, -50],
    opacity: [1, 0],
    transition: { ease: 'easeOut', duration: 0.2 },
  },
}

export function HamburgetMenu() {
  const [activeMenu, setActiveMenu] = React.useState<MenuItemType>('root')
  const scrollableRef = React.useRef<HTMLDivElement | null>(null)
  const [navigationHistory, setNavigationHistory] = React.useState<MenuItemType[]>([])
  const [open, setOpen] = React.useState(false)
  const activeMenuItems = useMenuItems(activeMenu)
  const overlayControls = useAnimationControls()
  const contentControls = useAnimationControls()
  const isIpad = useIsIpad()
  const t = useTranslations()
  const Link = useLinkComponent()

  React.useEffect(() => {
    // Reset state when menu closes
    if (!open) {
      setActiveMenu('root')
      setNavigationHistory([])
    }
  }, [open])

  React.useEffect(() => {
    const sequenceExpanded = async () => {
      await overlayControls.start('expanded')
      contentControls.start('expanded')
    }

    if (open) {
      sequenceExpanded()
    }
  }, [open, overlayControls, contentControls])

  React.useEffect(() => {
    if (activeMenu && scrollableRef.current) {
      scrollableRef.current.scrollTop = 0
    }
  }, [activeMenu, scrollableRef])

  const handleOverlayStateChange = async (isOpened: boolean) => {
    const sequenceCollapsed = async () => {
      await contentControls.start('collapsed')
      overlayControls.start('collapsed')
    }

    // handle animation before unmounting
    if (!isOpened) {
      await sequenceCollapsed()
    }

    setOpen(isOpened)
  }

  const handleActiveMenuChange = (menu: MenuItemType) => {
    setNavigationHistory(prev => [...prev, activeMenu])
    setActiveMenu(menu)
  }

  const renderMenu = (menu: MenuGroup[]) => {
    if (activeMenu === 'active-language') {
      return <LanguageSwitcher setIsMenuOpen={setOpen} />
    }

    if (activeMenu === 'active-currency') {
      return <CurrencySwitcher setIsMenuOpen={setOpen} />
    }

    return menu.map((group, index) => {
      return (
        <div
          key={group.groupName + index}
          className={tw('border-cf-dreamy-cloud-600 border-b p-4 last:border-none')}
        >
          {group.link ? (
            <NavigationMenu.Item
              className="text-16 border-cf-dreamy-cloud-700 mb-4 flex w-fit items-center gap-1 border-b border-dotted font-bold"
              onClick={() => {
                setOpen(false)
              }}
              asChild
            >
              <Link href={group.link}>
                <span>{t('navigation.all').replace(/\[0]/, group.groupName || '')} </span>
                <ArrowNarrowUpRight />
              </Link>
            </NavigationMenu.Item>
          ) : group.groupName ? (
            <li className="mb-4 text-[18px] font-bold leading-[22px]">{group.groupName}</li>
          ) : null}
          {group.items.map(item => (
            <HamburgerMenuItem
              key={item.id}
              {...item}
              setActiveMenu={handleActiveMenuChange}
              labelClasses={tw(
                activeMenu !== 'root' && 'text-16 leading-6',
                activeMenu === 'root' && index !== 0 && 'text-16 font-medium leading-6',
              )}
              setMenuOpen={setOpen}
            />
          ))}
        </div>
      )
    })
  }

  return (
    <LazyMotion features={domAnimation}>
      <Dialog.Root open={open} onOpenChange={handleOverlayStateChange} defaultOpen={false}>
        <Dialog.Trigger asChild>
          <MenuItems.Button className="h-[54px] flex-col gap-1 px-2">
            <div className="relative">
              <Menu01Icon />
              {/* <div className="absolute -right-1 -top-1 flex h-3.5 w-3.5 items-center justify-center rounded-full bg-[#E21743] text-[8px] text-[#FFF]">
              {cartItems}
            </div> */}
            </div>
            {t('top_nav.menu')}
          </MenuItems.Button>
        </Dialog.Trigger>
        <Dialog.Portal>
          <div data-cf-external>
            <m.div animate={overlayControls} variants={overlayVariants} key="overlay">
              <Dialog.Overlay
                forceMount
                className={tw(
                  'z-cf-menu-hamburger fixed inset-0 bg-[#272B2F] bg-opacity-80',
                  isIpad ? '' : 'min-[1175px]:cf-hidden',
                )}
              />
            </m.div>
            <Dialog.Content key="dialog" asChild forceMount>
              <m.div
                ref={scrollableRef}
                animate={contentControls}
                variants={contentVariants}
                className={tw(
                  'font-poppins',
                  'scroller-menu-y bg-cf-white z-cf-menu-hamburger fixed left-0 top-0 h-[100dvh] w-[calc(100dvw-56px)] text-sm',
                  isIpad ? '' : 'min-[1175px]:cf-hidden',
                )}
              >
                {activeMenu === 'root' ? (
                  <h3 className="p-4 pb-0 text-[18px] font-bold leading-[22px]">
                    {t('top_nav.menu')}
                  </h3>
                ) : (
                  <button
                    className="border-cf-dreamy-cloud-600 flex w-full items-center gap-4 border-b p-4"
                    onClick={() => {
                      setActiveMenu(navigationHistory[navigationHistory.length - 1])
                      setNavigationHistory(prev => prev.slice(0, -1))
                    }}
                  >
                    <ChevronLeftIcon className="h-5 w-5" />
                    <span className="text-14 grow text-left">{t('back')}</span>
                  </button>
                )}
                <NavigationMenu.Root
                  orientation="vertical"
                  role="navigation"
                  aria-label={t('top_nav.menu')}
                >
                  <NavigationMenu.List key={activeMenu}>
                    {renderMenu(activeMenuItems)}
                  </NavigationMenu.List>
                </NavigationMenu.Root>
              </m.div>
            </Dialog.Content>
            <Dialog.Close
              className={tw(
                'z-cf-menu-hamburger fixed right-0 top-0 m-4',
                isIpad ? '' : 'min-[1175px]:cf-hidden',
              )}
            >
              <div className="text-cf-white relative">
                <XCloseIcon className="h-6 w-6" />
              </div>
            </Dialog.Close>
          </div>
        </Dialog.Portal>
      </Dialog.Root>
    </LazyMotion>
  )
}

interface HamburgerMenuItemProps extends MenuItem {
  setActiveMenu: (menu: MenuItemType) => void
  labelClasses?: string
  setMenuOpen: (open: boolean) => void
}

function HamburgerMenuItem({
  icon,
  label,
  link,
  subMenuId,
  setActiveMenu,
  labelClasses = 'text-14 leading-5',
  onClick,
  setMenuOpen,
}: HamburgerMenuItemProps) {
  const Link = useLinkComponent()

  if (subMenuId) {
    return (
      <NavigationMenu.Item
        className="text-14 flex w-full cursor-pointer items-center justify-between gap-4 py-2 text-left leading-5"
        onClick={e => {
          e.preventDefault()
          onClick?.()
          setActiveMenu(subMenuId)
        }}
        asChild
      >
        <NavigationMenu.Trigger asChild>
          <button>
            {icon}
            <span className={tw('grow', labelClasses)}>{label}</span>
            <ChevronRightIcon className="h-5" />
          </button>
        </NavigationMenu.Trigger>
      </NavigationMenu.Item>
    )
  } else if (link) {
    return (
      <NavigationMenu.Item
        className="text-14 flex items-center justify-between gap-4 py-2"
        onClick={e => {
          onClick && e.preventDefault()
          onClick?.()
          setMenuOpen(false)
        }}
        asChild
      >
        <Link href={link}>
          {icon}
          <span className={tw('grow', labelClasses)}>{label}</span>
        </Link>
      </NavigationMenu.Item>
    )
  }

  return null
}
