import qs, { ParsedQs } from 'qs';
import type { UiState } from 'instantsearch.js';

import { AlgoliaLanguage } from 'components/elements/organisms/algoliaConnected/SearchResults/SearchResults.types';
import { contentAlgoliaIndex as algoliaIndex } from 'components/contexts/instantSearchProvider/InstantSearchProvider.utils';
import { SearchableFilters } from 'components/pages/stockphotos/components/SearchSidebar/SearchSidebar';
import { STOCKPHOTOS_AUTHOR_ID } from 'components/pages/stockphotos/pages/config';
import { buildAlgoliaColorFilter } from 'utilities/buildAlgoliaColorFilter';

export const translatableFilters = ['category', 'object', 'location', 'ethnicity', 'gender', 'age'] as const;

export const getSearchState = ({
  url,
  lang,
  categories,
}: {
  url: string;
  lang: AlgoliaLanguage;
  categories: Record<string, { id: string; name: string; slug: string }>;
}) => {
  const queryObject = qs.parse(url);

  return queryToSearchState(queryObject, lang, categories);
};

type QueryToSearchState = {
  uiState: UiState;
  other?: {
    expanded: ParsedQsValue;
    color?: string;
  };
};

export const queryToSearchState = (
  queryObject: ParsedQs,
  lang: AlgoliaLanguage,
  categories: Record<string, { id: string; name: string; slug: string }>,
): QueryToSearchState => {
  const { query = '', page, sortBy, category, format, object, withPeople, color, expanded } = queryObject;

  const colorHex = color ? decodeURIComponent(String(color)) : '';

  const colorFilters = buildAlgoliaColorFilter(colorHex);

  const state: UiState = {
    [algoliaIndex]: {
      query: decodeURIComponent(String(query)),
      page: !Number(page) ? 1 : Number(page),
      sortBy: sortBy ? decodeURIComponent(String(sortBy)) : '',
      configure: {
        hitsPerPage: 50,
        filters: `authorId:${STOCKPHOTOS_AUTHOR_ID}${Boolean(color) ? ` AND ${colorFilters}` : ''}`,
      },
      refinementList: {
        [`${SearchableFilters.CATEGORY}`]: decodeQueryArray(category).map(categoryName => {
          const categoryObject = Object.values(categories).find(({ name }) => name === categoryName);

          return categoryObject ? categoryObject.id : categoryName;
        }),
        [`${SearchableFilters.ORIENTATION}`]: decodeQueryArray(format),
        [`imageAnalysis.${lang}.${SearchableFilters.OBJECTS}`]: decodeQueryArray(object),
        'flags.hasPeople': decodeQueryArray(withPeople),
      },
    },
  };

  return {
    uiState: state,
    other: {
      expanded: decodeQueryArray(expanded),
      color: colorHex.length > 0 ? colorHex : undefined,
    },
  };
};

export const searchStateToQuery = (
  searchState: QueryToSearchState,
  lang: AlgoliaLanguage,
  categories: Record<string, { id: string; name: string; slug: string }>,
) => {
  const { query, page, refinementList, sortBy } = searchState.uiState[algoliaIndex];

  const category =
    refinementList &&
    refinementList[`${SearchableFilters.CATEGORY}`] &&
    refinementList[`${SearchableFilters.CATEGORY}`].length > 0
      ? refinementList[`${SearchableFilters.CATEGORY}`].map(id => categories[id].name)
      : [];
  const withPeople = refinementList ? refinementList['flags.hasPeople'] : [];
  const format = refinementList ? refinementList[`${SearchableFilters.ORIENTATION}`] : [];
  const object = refinementList ? refinementList[`imageAnalysis.${lang}.${SearchableFilters.OBJECTS}`] : [];

  const expanded = searchState.other?.expanded;
  const color = searchState.other?.color;

  const queryObject = {
    expanded: expanded ? decodeQueryArray(expanded) : undefined,
    query: query ? query : undefined,
    sortBy: sortBy ? sortBy : undefined,
    page: page && page !== 1 ? page : undefined,
    category: category ? category.map(encodeURIComponent) : undefined,
    withPeople: withPeople ? withPeople.map(encodeURIComponent) : undefined,
    format: format ? format.map(encodeURIComponent) : undefined,
    object: object ? object.map(encodeURIComponent) : undefined,
    color: color ? encodeURIComponent(color) : undefined,
  };

  const queryString = qs.stringify(queryObject, { arrayFormat: 'comma' });

  return queryString;
};

type ParsedQsValue = undefined | string | string[] | ParsedQs | ParsedQs[];

export const decodeQueryArray = (value: ParsedQsValue): string[] => {
  if (typeof value === 'string') {
    if (value.indexOf(',') > -1) {
      return value.split(',').map(decodeURIComponent);
    }

    return [decodeURIComponent(value)];
  }

  if (value && Array.isArray(value)) {
    const stringValues: string[] = [];

    value.forEach((item: ParsedQsValue) => {
      if (typeof item === 'string') {
        stringValues.push(item);
      }
    });

    return stringValues.map(decodeURIComponent);
  }

  return [];
};
