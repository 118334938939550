import * as React from 'react'

function detectIpad() {
  /* iOS pre 13 */
  const isOldIPad = /iPad/.test(navigator.userAgent)
  /* iPadOS 13+ browsers present a Mac user agent string as we need to detect touch support also */
  const isNewIpad = /MacIntel/.test(navigator.platform) && navigator.maxTouchPoints > 0

  return isOldIPad || isNewIpad
}

let isPad = false

export function useIsIpad() {
  const [isIpad, setIsIpad] = React.useState(isPad)

  React.useEffect(() => {
    isPad = detectIpad()
    setIsIpad(isPad)
  }, [])

  return isIpad
}
