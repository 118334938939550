import { ElementType } from 'react';
import { styled, Box, Grid, Typography } from '@mui/material';

import { Image } from 'components/elements/atoms/image/Image';

export const FooterRoot = styled('footer')(({ theme }) => ({
  background: '#393c57',
  color: theme.palette.white.main,
  padding: theme.spacing(4.5, 0, 4, 0),
  textAlign: 'center',
}));

export const GridColumns = styled(Grid)(({ theme }) => ({
  alignItems: 'center',
  paddingBottom: theme.spacing(2),
}));

export const GridLeftColumn = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
  borderRight: `2px solid ${theme.palette.white.main}`,
  [theme.breakpoints.down('md')]: {
    borderRight: 'none',
    borderBottom: `2px solid ${theme.palette.white.main}`,
  },
}));

export const GridRightColumn = styled(Grid)(({ theme }) => ({
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingBottom: theme.spacing(3),
}));

export const TypographyHeading = styled(Typography)<{ component?: ElementType }>(({ theme }) => ({
  paddingTop: theme.spacing(3),
  marginBottom: theme.spacing(1),
  fontSize: 16,
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: 1.4,
}));

export const TypographyText = styled(Typography)(({ theme }) => ({
  paddingBottom: theme.spacing(1.5),
  fontSize: 16,
}));

export const BoxPayments = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const ImageStyled = styled(Image)(({ theme }) => ({
  margin: theme.spacing(0.75),
}));

export const TypographyCopy = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(1),
}));
