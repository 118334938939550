import * as React from 'react'
import * as NavigationMenu from 'primitives/navigation-menu'

import { NavItem } from '../../../hooks/use-nav-items'
import { NavigationSubItem } from './submenu-item'
import { Container } from './nested-menu-container'
import {
  useActiveSecondaryItem,
  useMaxHeightMenuDropdown,
} from '../../../contexts/main-navigation-context'

interface Props extends React.PropsWithChildren<React.HTMLAttributes<HTMLDivElement>> {
  items: NavItem[]
}

export function TertiarySubmenu({ items }: Props) {
  const [secondary] = useActiveSecondaryItem()
  const maxHeight = useMaxHeightMenuDropdown()
  return (
    <Container asChild key="tertiary-items">
      <NavigationMenu.Content className={`bg-cf-dreamy-cloud-400 grow-1 order-3 w-full px-2 pt-3`}>
        <NavigationMenu.Root orientation="vertical" asChild>
          <div
            className="scroller-menu-x relative flex w-full flex-col overflow-x-auto"
            style={{ maxHeight: `(${maxHeight}px` }}
          >
            <h3 className="text-16 sticky w-full py-2 pl-4 pr-16 font-semibold">
              {secondary?.name}
            </h3>
            <NavigationMenu.List
              className="flex w-auto flex-col flex-wrap gap-[1px]"
              style={{ maxHeight: `calc(${maxHeight}px - 40px - 0.75rem)` }}
            >
              {items.map((item, index) => {
                return (
                  <NavigationSubItem
                    key={`${item.id}_${index}`}
                    item={item}
                    value={item.name}
                    className="w-fit"
                    level="tertiary"
                  >
                    {item.subMenuItems?.length ? (
                      <TertiarySubmenu items={item.subMenuItems} />
                    ) : null}
                  </NavigationSubItem>
                )
              })}
            </NavigationMenu.List>
          </div>
        </NavigationMenu.Root>
      </NavigationMenu.Content>
    </Container>
  )
}
