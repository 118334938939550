import { getOrMakeListener } from './listeners'

export function addDomEvent(
  target: EventTarget | Window,
  eventName: string,
  handler: EventListener,
  options?: AddEventListenerOptions,
) {
  return getOrMakeListener(target, eventName, handler, options)
}

export function loadImage(src?: string | void | null) {
  if (!src) {
    return Promise.reject('No src provided')
  }

  return new Promise<HTMLImageElement>((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossorigin', 'anonymous')
    img.crossOrigin = 'anonymous'
    img.onload = () => {
      resolve(img)
    }
    img.onerror = e => {
      reject(e)
    }
    img.src = src
  })
}

// loadImage does not load svg properly in firefox as width and height stay at 0.
export async function loadSVG(src?: string) {
  if (!src) {
    return Promise.reject('No src provided')
  }

  return fetch(src)
    .then(response => response.text())
    .then(svgText => {
      const parser = new DOMParser()
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml')
      const svgElement = svgDoc.querySelector('svg')
      if (!svgElement) {
        throw new Error('Invalid SVG')
      }

      // get width and height here or fallback to viewbox
      let width: string | number | null = svgElement.getAttribute('width')
      let height: string | number | null = svgElement.getAttribute('height')
      const viewBox = svgElement.getAttribute('viewBox')

      if ((!width || !height) && viewBox) {
        const viewBoxDimensions = viewBox.split(' ').map(Number)
        width = width || viewBoxDimensions?.[2]
        height = height || viewBoxDimensions?.[3]
      }

      const widthNumber = typeof width === 'string' ? parseFloat(width) : width
      const heightNumber = typeof height === 'string' ? parseFloat(height) : height

      const img = new Image()
      img.src = src
      return { img, dimensions: { width: widthNumber || 0, height: heightNumber || 0 } }
    })
    .catch(error => {
      throw error
    })
}

export function getCorsFixedUrl(url: string) {
  return url.includes('?') ? `${url}&__corsfix=1` : `${url}?__corsfix=1`
}

export function imageToBase64(img: HTMLImageElement) {
  const canvas = document.createElement('canvas')
  canvas.width = img.width
  canvas.height = img.height

  const ctx = canvas.getContext('2d')

  ctx?.drawImage(img, 0, 0)

  return canvas.toDataURL()
}

export function base64ToFile(dataurl: string, filename: string) {
  const arr = dataurl.split(',')
  const mime = arr[0].match(/:(.*?);/)![1]
  const bstr = atob(arr[1])
  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }

  return new File([u8arr], filename, { type: mime })
}

export async function imageUrlToBase64(url: string): Promise<string> {
  const data = await fetch(url)
  const blob = await data.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(blob)
    reader.onloadend = () => {
      const base64data = reader.result
      resolve(base64data as string)
    }
    reader.onerror = reject
  })
}

export const getHasEyeDropperSupport = () => 'EyeDropper' in window

export function setCookie({
  name,
  value,
  expiresIn,
  domain,
  path = '/',
}: {
  name: string
  value: string
  expiresIn?: number
  domain?: string
  path?: string
}) {
  let expires = ''
  if (expiresIn) {
    const date = new Date()
    date.setTime(date.getTime() + expiresIn)
    expires = '; expires=' + date.toUTCString()
  }
  let domainString = ''
  if (domain) {
    domainString = ';domain=' + domain
  }
  let pathString = ''
  if (path) {
    pathString = ';path=' + path
  }
  document.cookie = name + '=' + (value || '') + expires + domainString + pathString + ';'
}

export function getCookie(cname: string) {
  if (typeof document === 'undefined') {
    return ''
  }

  const name = cname + '='
  const decodedCookie = decodeURIComponent(document.cookie)
  const ca = decodedCookie.split(';')

  for (let i = 0; i < ca.length; i++) {
    let c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }

    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length)
    }
  }

  return ''
}

export function mergeFormData(...formStates: (FormData | null)[]) {
  const form = new FormData()

  for (const formState of formStates.reverse()) {
    if (!formState) {
      continue
    }

    for (const [key, value] of Array.from(formState.entries())) {
      if (form.has(key)) {
        continue
      }

      form.append(key, value)
    }
  }

  return form
}

export function sleep(ms: number) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

/**
 * The DOM likes values to be fixed to 3 decimal places
 */
export function toDomPrecision(v: number) {
  return +v.toFixed(4)
}

export function nextFrame(callback: FrameRequestCallback) {
  return requestAnimationFrame(() => requestAnimationFrame(callback))
}

export function isInEditElement() {
  return (
    document.activeElement?.tagName === 'INPUT' ||
    document.activeElement?.tagName === 'TEXTAREA' ||
    (document.activeElement as HTMLDivElement)?.contentEditable === 'true'
  )
}

/**
 * Sometimes an SVG can have no width and no height.
 * This function fixes that _if_ the SVG element has a viewBox. Otherwise
 * we can't really do anything else as it's maybe impossible but definitely
 * hard to know the size of the SVG, and the person who initially
 * wrote this function (me) doesn't know how to do that.
 *
 * So, if it doesn't have a viewBox, then it does nothing.
 *
 * I am not sure why TypeScript doesn't like `viewBox`,
 * although the specs say it's there on SVGElements.
 * see https://developer.mozilla.org/en-US/docs/Web/API/SVGMarkerElement/viewBox
 *
 * @param svg svg string
 * @returns fixed svg
 */
export function addWidthAndHeightToSvgFromViewbox(svg: string) {
  const parser = new DOMParser()
  const doc = parser.parseFromString(svg, 'image/svg+xml')
  const svgElement =
    doc.documentElement instanceof SVGElement ? doc.documentElement : findSvgElement(doc)

  const width = parseInt(svgElement.getAttribute('width') ?? '')
  const height = parseInt(svgElement.getAttribute('height') ?? '')

  if (width && height) {
    return svg
  }

  const svgElementViewBox = svgElement as unknown as SVGFitToViewBox
  const viewBox = svgElementViewBox?.viewBox?.baseVal
  if (viewBox) {
    svgElement.setAttribute('width', `${viewBox.width}`)
    svgElement.setAttribute('height', `${viewBox.height}`)
  }

  return svgElement.outerHTML
}

function findSvgElement(doc: Document) {
  const svgElement = doc.querySelector('svg')
  if (!svgElement) {
    throw new Error('Could not find SVG element')
  }
  return svgElement
}

export function requestIdleCallbackPolyfill(callback: IdleRequestCallback) {
  try {
    return requestIdleCallback(callback)
  } catch {}

  setTimeout(callback, 0)
}

export function getHtmlCharacterCount(input: string): number {
  const regex = /(<([^>]+)>)/gi
  const result = input.replace(regex, '')

  return result.length
}

export function scrollToElementWithDataAttribute({
  dataQuery,
  containerDataQuery,
  offset,
}: {
  dataQuery: string
  containerDataQuery?: string
  offset?: number
}) {
  const element = document.querySelector(dataQuery)
  const container = containerDataQuery ? document.querySelector(containerDataQuery) : window

  if (element) {
    const elementPosition = element.getBoundingClientRect().top
    const offsetPosition = elementPosition - (offset ?? 0)

    container?.scrollBy({
      top: offsetPosition,
      behavior: 'smooth',
    })
  }
}

export function isUrlEncoded(str: string) {
  try {
    const decodedStr = decodeURIComponent(str)
    return decodedStr !== str
  } catch (e) {
    return false
  }
}
