import { Language } from 'codegen/types'

export const languageMap = new Map<Language, string>([
  [Language.En, 'English'],
  [Language.Es, 'Español'],
  [Language.De, 'Deutsch'],
  [Language.Pt, 'Português (BR)'],
  [Language.Fr, 'Français'],
  [Language.It, 'Italiano'],
  [Language.Nl, 'Nederlands'],
  [Language.Pl, 'Polski'],
])

export const supportedLanguages = Array.from(languageMap.keys())

export const defaultLanguage = Language.En
