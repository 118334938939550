import { Grid, styled, Typography } from '@mui/material';

import { Link } from 'components/elements/atoms/link/Link';
import { Icon } from 'components/elements/atoms/icon/Icon';

export const HeaderRoot = styled('header')(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.appBar,
  display: 'flex',
  alignItems: 'center',
  height: theme.spacing(10.5),
  boxShadow: theme.shadows[3],
}));

export const GridColumnLeft = styled(Grid)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const GridColumnCenter = styled(Grid)(() => ({
  display: 'flex',
  justifyContent: 'center',
}));

export const LinkStyled = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

export const IconBack = styled(Icon)(({ theme }) => ({
  fontSize: theme.spacing(2.25),
}));

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  marginLeft: theme.spacing(1.25),
  [theme.breakpoints.down('sm')]: {
    display: 'none',
  },
}));

export const LinkLogo = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: 19,
  letterSpacing: theme.spacing(0.05),
  lineHeight: 1.9,
  whiteSpace: 'nowrap',
  textAlign: 'center',
  [theme.breakpoints.down('sm')]: {
    whiteSpace: 'normal',
    lineHeight: 1,
  },
}));
